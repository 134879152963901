
import React, { forwardRef } from "react";
import { Grid, Row, Col, Form, FormGroup } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import ReactGA from 'react-ga';

import "./DonationsArchives.css";

const { SearchBar } = Search;

const DonationsArchives = forwardRef((props, ref) => {
  
  function donationTypeFormatter (value, row, index){
    return row.donationType;
  }

  function donorTypeFormatter (value, row, index){
    return row.donorType;
  }

  const dateFormat = (cell, row) => {
    return(
        moment(cell).format("MM/DD/YYYY")
    )
  };

  const pagProps = paginationFactory({
    sizePerPage: 10
  });

  const sRowProps = {
    mode: 'radio',
    hideSelectColumn: true,
    clickToSelect: true,
    bgColor: 'lightgray'
  };

  const columns = [
    {
      dataField: 'donationId',
      text: 'Donation ID',
      type: 'number',
      sort: true
    },
    {
      dataField: 'donationType',
      text: 'Donation Type',
      formatter: donationTypeFormatter,
      sort: true
    }, {
      dataField: 'donorType',
      text: 'Donor Type',
      formatter: donorTypeFormatter,
      sort: true
    }, {
      dataField: 'name',
      text: 'Donor Name',
      sort: true
    }, {
      dataField: 'email',
      text: 'Donor Email',
      sort: true
    }, {
      dataField: 'phone',
      text: 'Donor Phone',
      sort: true
    }, {
      dataField: 'certificationDateUtc',
      text: 'Date',
      formatter: dateFormat,
      sort: true
    }
  ]
  ReactGA.pageview('Donation Archives');
  return (
    <ToolkitProvider
      ref={ref}
      id="donationsTable"
      keyField="donationId"
      data={props.allDonations}
      columns={columns}
      search
    >
      {
        props => (
          <div className="donationsArchives">
            <Grid>
              <Row className="show-grid rowOne">
                <Col md={10}>
                  <Form inline onSubmit={e => { e.preventDefault(); }}>
                    <FormGroup>
                      <SearchBar {...props.searchProps} />
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row className="show-grid rowTwo">
                <Col md={10}>
                  <BootstrapTable
                    {...props.baseProps}
                    selectRow={sRowProps}
                    pagination={pagProps}
                    bordered={false}
                    striped
                    hover
                    condensed
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        )
      }
    </ToolkitProvider>
  );
}
);
export default DonationsArchives;