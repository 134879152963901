import React, { Component } from "react";
import { Grid, Row, Col, ButtonToolbar, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import ReactGA from 'react-ga';

import "./Home.css";

export default class Home extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="Home">
        <div className="home">
          <Grid fluid={true}>
            <Row className="show-grid row--one">
              <Col md={2}></Col>
              <Col md={4}>
                <h2>Your Donations Could Save a Life.</h2>
                <p>
                  We are working to bridge the gap between the billions of dollars of unused and unexpired medications thrown away each year and the patients who need them.
              </p>
                <ButtonToolbar>
                  <Link to='donation'><Button bsStyle="success" bsSize="large">Donate Now</Button></Link>
                  <Link className='second' to='findMedication'><Button bsStyle="success" bsSize="large">Find Medications</Button></Link>
                  
                </ButtonToolbar>
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row className="show-grid row--two">
              <Col>
                <h3 className="text-center">
                  Your help has created a prescription drug program that improves
                <br />
                  access to needed medications for those who can't afford them.
              </h3>
              </Col>
            </Row>
            <Row className="show-grid row--three row-eq-height">
              <Grid fluid={true}>
                <Row className="learn-more">
                  <Col xs={4} className="whyDonate">
                    <div className="card">
                      <Image src="/assets/grandmother-heart.jpg" />
                      <div className="card-body">
                        <h3>Why Donate</h3>
                        <span>You can help others who can't afford their medication.</span>
                        <Link to='donate'><Button >Learn More</Button></Link>
                        {/* <Button href="donate">Learn More</Button> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} className="whatDonate">
                    <div className="card">
                      <Image src="/assets/medication-hands.jpg" />
                      <div className="card-body">
                        <h3>What to Donate</h3>
                        <span>Learn which medications are eligible for donation.</span>
                        <HashLink to='donate#whatMeds'><Button >Learn More</Button></HashLink>
                        {/* <Button href="donate#whatMeds">Learn More</Button> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} className="pharmacies">
                    <div className="card">
                      <Image src="/assets/interaction.jpg" />
                      <div className="card-body">
                        <h3>Pharmacies</h3>
                        <span>Where you can go to donate your unused medications.</span>
                        <Link to='findPharmacy'><Button >Learn More</Button></Link>
                        {/* <Button href="findPharmacy">Learn More</Button> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} className="environment">
                    <div className="card">
                      <Image src="/assets/girl-grass.jpg" />
                      <div className="card-body">
                        <h3>The Environment</h3>
                        <span>Donating unused medications helps our environment.</span>
                        <Link to='environment'><Button >Learn More</Button></Link>
                        {/* <Button href="environment">Learn More</Button> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </Row>
            <Row className="show-grid row--four row-eq-height">
              <Grid>
                <Row>
                  <Col md={6}>
                  </Col>
                  <Col md={4}>
                    <br />
                    <br />
                    <br />
                    <h2>Help the Charitable Pharmacy Group impact more families with a monetary donation.</h2>
                    <p>
                      Your financial support makes this program possible. With your donations we are able to provide the administrative support
                      that allows this program to operate and serve those in need.  Please consider making a financial donation to the Charitable Pharmacy.
                  </p>
                    <Button href="donateMoney" bsSize="large" bsStyle="success">Financial Donation</Button>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </Grid>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
