import React, { Component } from "react";
import { Alert, Grid, Row, Col, Form, FormGroup } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { invokeAPI } from "../../libs/apiLib";
import ReactGA from 'react-ga';

import "./FindPharmacy.css";

const { SearchBar } = Search;
export default class FindPharmacy extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isError: false,
      location: null,
      pharmQuery: "",
      pharmacies: []
    };
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.loadPharmacies();
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  getPharmacies() {
    return invokeAPI({
      path: `/pharmacies`,
      method: "GET",
      queryParams: { PharmacyNameContains: this.state.pharmQuery }
    });
  }

  async loadPharmacies(e) {
    // Get the data.
    try {
      const results = await this.getPharmacies();
      this.setState({
        pharmacies: results.results
      });
    } catch (e) {
      console.error("Failed to find Pharmacy", e);
    }
  }

  handleDismiss() {
    this.setState({ isError: false });
  }

  addressFormatter(value, row, index) {
    return row.address1 + ", " +  (row.address2 === undefined ? "" : row.address2) + " " + row.city + ", " + row.state + ", " + row.zip;
  }

  addressMapFormatter(value, row, index) {
    var href_Add =
      "https://maps.google.com/?q=" + row.address1 + " " + (row.address2 === undefined ? "" : row.address2) + " " + row.city + "," + row.state + "," + row.zip;

    return (
      <div>
        <a href={href_Add} target="_blank" rel="noopener noreferrer">
          Map
        </a>
      </div>
    );
  }

  phoneCallFormatter(value, row, index) {
    var href_tel = "tel:" + row.phone;

    return (
      <div>
        <a href={href_tel}>Call</a>
      </div>
    );
  }

  hoursFormatter(value, row, index) {
    return `8:00AM-4:30PM`;
  }

  render() {
    const pagProps = paginationFactory({
      sizePerPage: 10
    });

    const sRowProps = {
      mode: "radio",
      hideSelectColumn: true,
      clickToSelect: true,
      bgColor: "lightgray"
    };

    const columns = [
      {
        dataField: "pharmacyName",
        text: "Name",
        sort: true
      },
      {
        dataField: 'address',
        text: "Address",
        sort: false,
        formatter: this.addressFormatter
      },
      {
        dataField: 'map',
        text: '',
        sort: false,
        formatter: this.addressMapFormatter
      },     
      {
        dataField: 'hours',
        text: "Hours",
        sort: false,
        formatter: this.hoursFormatter
      },
      {
        dataField: "phone",
        text: "Phone",
        sort: false
      },
      {
        dataField: 'phoneNumber',
        text: '',
        sort: false,
        formatter: this.phoneCallFormatter
      }
    ];

    let alertBox;

    if (this.state.isError) {
      alertBox = (
        <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
          <h4>Search Error</h4>
          <p>There was an error performing the search.</p>
        </Alert>
      );
    } else {
      alertBox = "";
    }

    return (
      <div className="findPharmacy">
        <div className="findPharmacy">
          <Grid fluid={true}>
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                <div className="row-title">
                  <h2>Participating Pharmacies</h2>
                  <br />
                  <p>
                    If you aren't sure if there are any Charitable Pharmacy
                    affiliated pharmacies located in your area, we may be able
                    to help you. Search for the name or location of a pharmacy
                    in your area to see if it is participating in the Donate
                    Drugs program.
                  </p>
                  <br />
                  <br />
                </div>
                <Row className="show-grid">
                  <Col md={12}>
                    {alertBox}
                  </Col>
                  <Col md={12}></Col>
                </Row>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Row className="show-grid row--two">
              <Col md={2}></Col>
              <Col md={8}>
                <ToolkitProvider
                  id="pharmaciesTable"
                  keyField="pharmacyId"
                  data={this.state.pharmacies}
                  columns={columns}
                  search={ { searchFormatted: true } }
                >
                  {props => (
                    <div className="findPharmacy">
                      <Grid>
                        <Row className="show-grid rowOne">
                          <Col md={10}>
                            <Form inline>
                              <FormGroup>
                                <SearchBar {...props.searchProps} />
                              </FormGroup>
                            </Form>
                          </Col>
                        </Row>
                        <Row className="show-grid rowTwo">
                          <Col md={10}>
                            <BootstrapTable
                              {...props.baseProps}
                              selectRow={sRowProps}
                              pagination={pagProps}
                              bordered={false}
                              striped
                              hover
                              condensed
                            />
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
