import React, { Component } from "react";
import {
  Alert,
  Button,
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import { MedicationType, MedicationSource } from "../../globals";
import { DateTime } from "react-datetime-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { invokeAPI } from "../../libs/apiLib";
import ReactGA from "react-ga";

import "../DonationForm/DonationForm.css";
import "./DonationFormMedicationInfo.css";

export default class DonationFormMedicationInfo extends Component {
  constructor(props) {
    super(props);

    var theDate = new Date().toISOString();
    this.state = {
      isError: false,
      errorCd: "",
      errorMsg: "",
      newDrugNames: [],
      donationsData: [],
      currentDate: theDate,
      experationDate: theDate,
      experationFormattedDate: "",
      drugNames: [],
    };
    this.currentDatePicker = "";
    this.tempDonations = this.state.donationsData;

    this.defaultDonation = {
      DrugName: "",
      ExpirationDate: new Date().toISOString(),
      LotNumber: "",
      Source: "",
      Quantity: "",
      QuantityType: "",
      NdcNumber: "",
      StrengthDosage: "",
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateFocus = this.handleDateFocus.bind(this);
    this.updateDonationField = this.updateDonationField.bind(this);
    this.isValidated = this.isValidated.bind(this);

    return;
  }

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ isError: false });
    // Get the data.
    try {
      const results = await this.getDrugNames();
      this.setState({
        drugNames: results.result,
      });
    } catch (e) {
      console.error("Failed to get drug names:", e);
      this.setState({ isError: true });
    }

    if (!this.state.donationsData.length) {
      this.addDonationRow();
    }
    this._mounted = true;
    return;
  }

  componentWillUnmount() {
    // Fix new drug names
    var tmpD = this.state.donationsData;
    for(var i = 0; i < tmpD.length; i++) {
      if(this.state.newDrugNames[i]) {
        tmpD[i]["DrugName"] = this.state.newDrugNames[i];
      }
    }
    this.props.updateDS({ medications: [...tmpD] });
    this._mounted = false;
    return;
  }

  handleDateChange(experationVal, experationFormatVal) {
    if (this.state.donationsData[this.currentDatePicker]) {
      this.tempDonations[this.currentDatePicker].ExpirationDate = experationVal;

      this.setState({ donationsData: [...this.tempDonations] });
    }
    return;
  }
  handleDateFocus(event) {
    this.currentDatePicker = String(event.target.id).split("_")[1];
    return;
  }
  updateDonationField(key, idx, value) {
    this.tempDonations[idx][key] = value;
    this.setState({ donationsData: [...this.tempDonations] });
  }

  printDonationListHTML() {
    if (this.state.donationsData.length) {
      var meds = [];
      for (var i = 0; i < this.state.donationsData.length; i++) {
        meds.push(this.addHtmlForRow(i));
      }
      return <div>{meds}</div>;
    } else {
      return <div></div>;
    }
  }

  addHtmlForRow(idx) {
    return (
      <Row className="show-grid row--one" key={idx}>
        <Col md={12}>
          <div className="donationHeader">
            Your Medication Donation {idx + 1}{" "}
            <div
              className="donationHeader--close"
              onClick={() => this.deleteRow(idx)}
            ></div>
          </div>
          <Col md={9} className="col--nopadding pad-right">
            <ControlLabel>Drug Name</ControlLabel>
            <div className="donationDrugName">
              <Typeahead
                id="autoDrugName"
                labelKey="name"
                multiple={false}
                onChange={(selected) =>
                  this.updateDonationField("DrugName", idx, selected)
                }
                onInputChange={ (value) => this.newDrugName(value, idx) }
                options={this.state.drugNames}
                placeholder="Drug Name"
                selected={this.state.donationsData[idx].DrugName}
              />
            </div>
          </Col>
          <Col md={3} className="col--nopadding">
            <ControlLabel>Expiration</ControlLabel>
            <DateTime
              className="dtPicker"
              id={"datepicker_" + idx}
              value={this.state.donationsData[idx].ExpirationDate}
              onFocus={this.handleDateFocus}
              onChange={this.handleDateChange}
              pickerOptions={{ format: "L", allowInputToggle: false }}
            />
          </Col>
          <Col md={2} className="col--nopadding pad-right">
            <ControlLabel>Lot&nbsp;Number</ControlLabel>
            <FormControl
              type="text"
              value={this.state.donationsData[idx].LotNumber}
              onChange={(event) =>
                this.updateDonationField("LotNumber", idx, event.target.value)
              }
            />
          </Col>
          <Col md={2} className="col--nopadding pad-right">
            <FormGroup controlId="source">
              <ControlLabel>Source</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="Source"
                value={this.state.donationsData[idx].Source}
                onChange={(event) =>
                  this.updateDonationField("Source", idx, event.target.value)
                }
              >
                {MedicationSource.map((item, i) => {
                  return (
                    <option key={i} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
          </Col>
          <Col md={2} className="col--nopadding pad-right">
            <ControlLabel>Qty.&nbsp;Remaining</ControlLabel>
            <FormControl
              type="text"
              value={this.state.donationsData[idx].Quantity}
              onChange={(event) =>
                this.updateDonationField("Quantity", idx, event.target.value)
              }
            />
          </Col>
          <Col md={2} className="col--nopadding pad-right">
            <FormGroup controlId="quantityType">
              <ControlLabel>Quantity Type</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="Quantity Type"
                value={this.state.donationsData[idx].QuantityType}
                onChange={(event) =>
                  this.updateDonationField(
                    "QuantityType",
                    idx,
                    event.target.value
                  )
                }
              >
                {MedicationType.map((item, i) => {
                  return (
                    <option key={i} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
          </Col>
          <Col md={2} className="col--nopadding pad-right">
            <ControlLabel>NDC&nbsp;Number</ControlLabel>
            <FormControl
              type="text"
              value={this.state.donationsData[idx].NdcNumber}
              onChange={(event) =>
                this.updateDonationField("NdcNumber", idx, event.target.value)
              }
            />
          </Col>
          <Col md={2} className="col--nopadding">
            <ControlLabel>Dosage</ControlLabel>
            <FormControl
              type="text"
              value={this.state.donationsData[idx].StrengthDosage}
              onChange={(event) =>
                this.updateDonationField(
                  "StrengthDosage",
                  idx,
                  event.target.value
                )
              }
            />
          </Col>
        </Col>
      </Row>
    );
  }

  addDonationRow = () => {
    this.tempDonations.push({ ...this.defaultDonation });
    this.setState({ donationsData: [...this.tempDonations] });
    return;
  };

  getDrugNames() {
    return invokeAPI({
      path: `/medications/names`,
      method: "GET",
      queryParams: {},
    });
  }

  deleteRow = id => {
    let tmpDonations = this.state.donationsData;

    tmpDonations.splice(id, 1);

    if (this._mounted) {
      this.tempDonations = tmpDonations;
      this.setState({ donationsData: tmpDonations });
      if(tmpDonations.length == 0) {
        this.props.setNav(false);
      } else {
        this.props.setNav(true);
      }
    }

    return;
  };

  newDrugName = (value, idx) => {
    var newNames = this.state.newDrugNames;
    newNames[idx] = value;
    this.setState({ newDrugNames: newNames });
  }

  isValidated() {
    let isDataValid = true;
    let reqAttrs = [];

    this.state.donationsData.map((item, i) => {
      if (item["DrugName"].length === 0) {
        // check to see if it a new drug
        if (this.state.newDrugNames[i] !== undefined && this.state.newDrugNames[i].length === 0) {
          isDataValid = false;
          reqAttrs.push("Drug Name " + i);
        }
      }
      if (item["ExpirationDate"].length === 0) {
        isDataValid = false;
        reqAttrs.push("Expiration Date " + i);
      }
      if (item["LotNumber"].length === 0) {
        isDataValid = false;
        reqAttrs.push("Lot Number " + i);
      }
      if (item["Source"].length === 0) {
        isDataValid = false;
        reqAttrs.push("Source " + i);
      }
      if (item["Quantity"].length === 0) {
        isDataValid = false;
        reqAttrs.push("Quantity " + i);
      }
      if (item["QuantityType"].length === 0) {
        isDataValid = false;
        reqAttrs.push("Quantity Type " + i);
      }
      if (item["NdcNumber"].length === 0) {
        isDataValid = false;
        reqAttrs.push("NDC Number " + i);
      }
      if (item["StrengthDosage"].length === 0) {
        isDataValid = false;
        reqAttrs.push("Strength Dosage " + i);
      }
    });

    if (!isDataValid) {
      this.setState({
        isError: true,
        errorCd: "MIA-602",
        errorMsg: "Required Data Missing: " + reqAttrs.toString(),
      });
    } else {
      this.setState({ isError: false });
    }

    return isDataValid;
  }

  render() {
    const donationRowOutput = this.printDonationListHTML();

    let alertBox;

    if (this.state.isError) {
      alertBox = (
        <Alert bsStyle="danger">
          <h4>Medication Donation Error</h4>
          <p>There was an error creating a medication donation.</p>
          <p>
            {this.state.errorCd}:{this.state.errorMsg}
          </p>
        </Alert>
      );
    } else {
      alertBox = "";
    }

    return (
      <div
        className="step DonationFormMedicationInfo"
        ref="DonationFormMedicationInfo"
      >
        <Form>
          <FormGroup>
            <Grid>
              {donationRowOutput}
              <Col md={1}></Col>
              { this.state.donationsData.length < 10 &&
                <Row className="show-grid row--three">
                  <Col md={1}></Col>
                  <Col md={10}>
                    <Button bsStyle="primary" onClick={this.addDonationRow}>
                      + Add Another Donation
                    </Button>
                  </Col>
                  <Col md={1}></Col>
                </Row>
              }
            </Grid>
          </FormGroup>
        </Form>
        {alertBox}
      </div>
    );
  }
}
