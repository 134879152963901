import React, { Component } from "react";
import { Alert, Grid, Row, Col, Form, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import moment from 'moment';
import ReactGA from 'react-ga';

import "../DonationForm/DonationForm.css";
import "./DonationFormConfirmSubmit.css";

export default class DonationFormConfirmSubmit extends Component {
  constructor(props) {
    super(props);

    var theDate = new Date().toISOString();

    this.state = {
      isError: false,
      errorCd: '',
      errorMsg: '',
      currentDate: theDate,
      dp1Date: theDate,
      signature: props.getDS().signature,
      signatureValidation: null,
      certificationDate: theDate,
      donationType: props.getDS().donationType,
    };

    this.handleChangeDP1 = this.handleChangeDP1.bind(this);
    this.isValidated = this.isValidated.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentWillUnmount() {
    this.props.updateDS({
      signature: this.state.signature,
      certificationDate: this.state.certificationDate
    });
    this._mounted = false
  }

  isValidated() {
    let isDataValid = true;

    if (this.state.signature.length === 0) {
      isDataValid = false;
      this.setState({ signatureValidation: "error" });
    } else {
      this.setState({ signatureValidation: "success" });
    }

    if(!isDataValid) {
      this.setState({ isError: true, errorCd: "MIA-601", errorMsg: "Required Data Missing." });
    } else {
      this.setState({ isError: false });
    }

    return isDataValid;
  }

  handleChangeDP1(dp1Val, dp1FormatVal) {
    this.setState({
      dp1Date: dp1Val, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      certificationDate: dp1Val
    });
  }


  printFormHTML() {
    if (this.state.donationType !== '') {
      var fHtml = '' 
      switch (this.state.donationType) {
        case '1':
          fHtml = this.printForm_Donor();
        break;
        case '2':
          fHtml = this.printForm_Representative();
        break;
        case '3':
          fHtml = this.printForm_Facility();
        break;
        default:
          break;
      }
      return <div>{fHtml}</div>;
    }
    else {
      return <Col md={12}>
        <div className="donationError">
          <p> You must select a Donor Type on the Donor Information Step.</p>
        </div> </Col>;
    }
  }  

  printForm_Donor() {
    return <Col md={12}>
      <span className="donationHeader"><h3>Donor Certification</h3></span>
      <span>
        By signing below, I attest to the best of my knowledge that the drug(s) I am donating have been properly stored,
        in accordance with the manufacturer's recommendations, and never been opened, used, adultered, or misbranded.
      </span>
      <Col md={9} className="col--nopadding pad-right">
        <FormGroup validationState={this.state.signatureValidation}>
          <ControlLabel>Signature</ControlLabel>
          <FormControl type="text" value={this.state.signature} onChange={(event) => this.setState({ signature: event.target.value })} />
        </FormGroup>
      </Col>
      <Col md={3} className="col--nopadding">
        <ControlLabel>Date</ControlLabel><br />
        <ControlLabel>{moment(this.state.dp1Date).format("MM/DD/YYYY")}</ControlLabel>
      </Col>

      <span>
        By typing your name you certify this is you and only you. 
      </span>
    </Col>;
  }

  printForm_Representative() {
    return <Col md={12}>
      <span className="donationHeader"><h3>Person or Person's Representative Certification</h3></span>
      <span>
        By signing below, I attest to the best of my knowledge that the drug(s) being donated have never been opened, used, adulterated, or misbranded, and the drug(s) have been stored in a manner and location that adheres to the conditions established by the manufacturer.
      </span>
      <br />
      <Col md={9} className="col--nopadding pad-right">
        <FormGroup validationState={this.state.signatureValidation}>
          <ControlLabel>Signature</ControlLabel>
          <FormControl type="text" value={this.state.signature} onChange={(event) => this.setState({ signature: event.target.value })} />
        </FormGroup>
      </Col>
      <Col md={3} className="col--nopadding">
        <ControlLabel>Date</ControlLabel><br />
        <ControlLabel>{moment(this.state.dp1Date).format("MM/DD/YYYY")}</ControlLabel>
      </Col>
      <span>
        By typing your name you certify this is you and only you. 
      </span>
    </Col>;
  }

  printForm_Facility() {
    return <Col md={12}>
      <span className="donationHeader"><h3>Facility Certification</h3></span>
      <span>
        By signing below, I attest to the best of my knowledge that the drug(s) listed above have never been opened, used, adulterated, or misbranded, and the drug(s) have been stored in a manner and location that adheres to the conditions established by the manufacturer.
      </span>
      <br />
      <Col md={9} className="col--nopadding pad-right">
        <FormGroup validationState={this.state.signatureValidation}>
          <ControlLabel>Signature</ControlLabel>
          <FormControl type="text" value={this.state.signature} onChange={(event) => this.setState({ signature: event.target.value })} />
        </FormGroup>
      </Col>
      <Col md={3} className="col--nopadding">
        <ControlLabel>Date</ControlLabel>
        <ControlLabel>{moment(this.state.dp1Date).format("MM/DD/YYYY")}</ControlLabel>
      </Col>
      <span>
        By typing your name you certify this is you and only you. 
       </span>
    </Col>;
  }

  render() {
    const formOutput = this.printFormHTML();

    let alertBox;

    if (this.state.isError) {
      alertBox = (
        <Alert bsStyle="danger">
          <h4>Medication Donation Error</h4>
          <p>There was an error creating a medication donation.</p>
          <p>
            {this.state.errorCd}:{this.state.errorMsg}
          </p>
        </Alert>
      );
    } else {
      alertBox = "";
    }

    return (
      <div className="step DonationFormConfirmSubmit">
        <Form>
          <FormGroup>
            <Grid>
              <Row className="show-grid row--one">
                {formOutput}
              </Row>
            </Grid>
          </FormGroup>
        </Form>
        {alertBox}
      </div>
    )
  }
}
