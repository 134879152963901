export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  DEBUG: true,
  s3: {
    BUCKET: "charitablepharmacy-uploads"
  },
  apiGateway: {
    // URL: "http://localhost:5000",
    // URL: "http://api-d-publi-1mez7pdcsfe7b-583700732.us-west-2.elb.amazonaws.com",
    URL: "https://api.donatedrugs.net",
    REGION: "us-west-2"
  },
  cognito: {
    USER_POOL_ID: "us-west-2_aYAMAar8e",
    APP_CLIENT_ID: "1qgjhg594hm9aid3a5cis97aas",
    REGION: "us-west-2",
    IDENTITY_POOL_ID: "us-west-2:4848263b-96ae-4c3d-aa34-ed150acc4d41"
  }
};
