import React, {Component} from "react";
import {Tabs, Tab, Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, ButtonToolbar, Button} from "react-bootstrap";
import ReactGA from 'react-ga';

import "./MemberHome.css";

export default class MemberHome extends Component {
  constructor(props, context) {
    super(props, context);

    this.tabTitles = ['Preferences'];

    this.state = {
      key: 1,
      tabHeader: this.tabTitles[0],
    };

    this.handleSelect = this.handleSelect.bind(this);
}
    componentDidMount() {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    componentDidUpdate(prevProps) {

    }
    componentWillUnmount() {

    }

    handleSelect(key) {
      this.setState({key: key, tabHeader: this.tabTitles[key-1]});
    }

    renderMemberHome() {
      return (
        <div className="memberPreferences">
          <Grid>
            <Row className="show-grid rowHeader">
              <Col md={1}></Col>
              <Col md={10}>
                <h1>{this.state.tabHeader}</h1>
                <br />
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row className="show-grid rowNav">
              <Col md={1}></Col>
              <Col md={10}>
                <Tabs activeKey={this.state.key} onSelect={this.handleSelect} id="memberHomeMenu">
                  <Tab eventKey={1} title={this.tabTitles[0]}>
                    {this.renderMemberPreferences()}
                  </Tab>
                </Tabs>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Grid>
        </div>
      );
    }

    renderMemberPreferences() {
      return(
        <div className="memberPreferences">
          <br/>
          <Grid>
            {/*
            <Row className="show-grid rowOne">
              <Col md={10}>
                <br/>
                <h4>Update Email</h4>
                <br/>
                <Form inline>
                  <FormGroup controlId="currentEmail">
                    <ControlLabel>Current Email</ControlLabel><br/><br/>
                    <FormControl type="email" placeholder="Current Email"/>
                  </FormGroup>
                  <span className="formSpace"></span>
                  <FormGroup controlId="newEmail">
                    <ControlLabel>New Email</ControlLabel><br/><br/>
                    <FormControl type="email" placeholder="New Email"/>
                  </FormGroup>
                  <span className="formSpace"></span>
                  <FormGroup controlId="confirmEmail">
                    <ControlLabel>Confirm Email</ControlLabel><br/><br/>
                    <FormControl type="email" placeholder="Confirm Email"/>
                  </FormGroup>
                  <span className="formSpace"></span>
                  <FormGroup>
                    <ButtonToolbar>
                      <Button bsStyle="primary" type="submit">Change</Button>
                    </ButtonToolbar>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            */}
            <Row className="show-grid rowOne">
              <Col md={10}>
                <br/>
                <h4>Update Password</h4>
                <br/>
                <Form inline>
                  <FormGroup controlId="currentPassword">
                    <ControlLabel>Current Password</ControlLabel><br/><br/>
                    <FormControl type="password" placeholder="Current Password"/>
                  </FormGroup>
                  <span className="formSpace"></span>
                  <FormGroup controlId="newPassword">
                    <ControlLabel>New Password</ControlLabel><br/><br/>
                    <FormControl type="password" placeholder="New Password"/>
                  </FormGroup>
                  <span className="formSpace"></span>
                  <FormGroup controlId="confirmPassword">
                    <ControlLabel>Confirm Password</ControlLabel><br/><br/>
                    <FormControl type="password" placeholder="Confirm Password"/>
                  </FormGroup>
                  <span className="formSpace"></span>
                  <FormGroup>
                    <ButtonToolbar>
                      <Button bsStyle="primary" type="submit">Change</Button>
                    </ButtonToolbar>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }

    render() {
      return(
        <div className="MemberHome">
          {
            this.renderMemberHome()
          }
        </div>
      );
    }
}