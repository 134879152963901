import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

import "./PrivacyPolicy.css";

export default class PrivacyPolicy extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="Privacy">
        <div className="policy">
          <Grid>
            <Row className="show-grid">
              <Col md={2}></Col>
              <Col md={8}>
                <h1 className="policy--title">Privacy Policy</h1>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Row className="show-grid policy--content">
              <Col md={2}></Col>
              <Col md={8}>
                <div>
                  <p>
                    This privacy policy ("Privacy Policy") applies to your use of the CPG Services only and does not cover information collected by any third party sites to which the CPG Services links, or to any other products or services offered by CPG other than on the CPG Services. This Privacy Policy is part of, and is hereby incorporated into, the CPG Services Terms of Service. You can read the entire Terms of Service by clicking <Link to='termsconditions'>here</Link>. If you do not agree with the Terms of Service, including the Privacy Policy, in their entirety, please do not use any CPG Services.
                  </p>
                </div>
                <div className="policy--subTitle">
                  <p>
                    What information does CPG collect about me as I use the CPG Services?
                  </p>
                </div>
                <div>
                  <p>
                    CPG may collect personally identifying information from you such as your name, telephone number, postal address, e-mail address, location, and credit card information so that we or selected companies can provide you with the products and services you request. For example, we may ask you for your name, e-mail address, and certain other information in connection with certain features related to the donation of prescription drugs and positive identification of the donor. CPG may also collect your credit card information in connection with certain shipping options or other fees, donations and related payments, so that we can process your payment for the service you are requesting. During the payment process, such credit card information is transmitted either to CPG or to the particular supplier or service provider who provides the service you are purchasing. In both cases, credit card information is then transmitted to third-party credit card processors and card issuers that use your information to process your order.
                  </p>
                </div>
                <div>
                  <p>
                    CPG may also collect non-personally identifying information when you visit the CPG Services, such as your browser configuration, the type of operating system you are using and information about your Internet service provider.
                  </p>
                </div>
                <div className="policy--subTitle">
                  <p>
                    How does CPG use the information supplied by me?
                  </p>
                </div>
                <div>
                  <p>
                    We may use the information you supply to fulfill your requests for services, to respond to your inquiries, and to follow up with you regarding your donations or the services you have requested.
                  </p>
                </div>
                <div>
                  <p>
                    If you have indicated interest in receiving information from CPG or directly from a selected third-party, we may provide such third-party with information that may include but not be limited to your name, telephone number, postal address and email address. If you decide that you do not wish to receive further communications from such third-party, you will need to so notify CPG and such third-party.
                  </p>
                </div>
                <div>
                  <p>
                    We sometimes use non-personally identifying information we collect through our use of cookies to improve the design, delivery and content of our publications and the CPG Services and to enable us to personalize your user experience. We may also use the non-personally identifying information we collect in the aggregate to analyze how visitors use the CPG Services, as well as to provide you with offers and information about services which you have agreed to receive.
                  </p>
                </div>
                <div>
                  <p>
                    In addition, CPG may disclose information we maintain (A) if in good faith we believe that such disclosure is necessary to (1) comply with the law or legal process; (2) protect and defend our rights and property; (3) protect against misuse or unauthorized use of the CPG Services; or (4) protect the personal safety, property, or rights of our users or the public, or (B) in connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale.
                  </p>
                </div>
                <div>
                  <p>
                    If at any point after giving CPG your information, you do not wish to continue to receive additional information and offers from CPG about CPG, from CPG's affiliates, or from selected outside companies, you may opt out of receiving future emails by contacting CPG directly at <a href="mailto:info@donatedrugs.com">info@donatedrugs.com</a>.
                  </p>
                </div>
                <div className="policy--subTitle">
                  <p>
                    How does CPG protect my Personally Identifying Information and Personal Health Information?
                  </p>
                </div>
                <div>
                  <p>
                    Due to the nature of the services provided by CPG, we are required to collect certain Personally Identifying Information (“PII”)  and Personal Health Information (“PHI”) from our customers prior to engaging in online transactions such as donating medication, requesting medication, accepting donated medication, and communicating with our participant pharmacies.  If you elect to use the CPG Services either as a donor or a recipient, you will be asked to provide PII and PHI during the registration process.
                  </p>
                </div>
                <div>
                  <p>
                    CPG is a voluntary participant in a state sanctioned donation program. We are not required to provide the CPG Services to anyone and your participation is likewise voluntary. IF YOU DO NOT WISH TO PROVIDE YOUR PII OR PHI, YOU SHOULD NOT REGISTER FOR SERVICES AS YOUR REGISTRATION AND WAIVER OF RIGHTS WILL BE DEEMED CONSENT TO STORE AND USE YOUR PII AND PHI FOR THE PURPOSES STATED ON OUR WEBSITE AND IN OUR TERMS OF SERVICE.
                  </p>
                </div>
                <div>
                  <p>
                    If you do elect to register as a user of the CPG Services, know that we have taken steps to ensure the privacy and security of the PII and PHI which you provide in the registration process, including: (i) [  ]; (ii) [  ]; and (iii) [  ]. In addition, should we become aware of any breach of security or unauthorized disclosure of your PII or PHI, we will contact you immediately using the email and/or telephone number you have provided in the registration process.
                  </p>
                </div>
                <div className="policy--subTitle">
                  <p>
                    What are "cookies" and how does CPG use them?
                  </p>
                </div>
                <div>
                  <p>
                    "Cookies" are pieces of information that are placed by the CPG Services in a storage place on your computer or mobile device. Cookies do not contain information that is, in itself, personally identifying. Cookies and other tracking information are placed on your computer or mobile device in a limited way to help deliver advertisements, to calculate unique site visitors, and to track usage throughout our site. Other uses include placement of cookies to make the login process more convenient for our services that use logins.
                  </p>
                </div>
                <div>
                  <p>
                    In addition, some advertisers or featured services providers may place cookies on your computer or mobile device. These companies may use cookies and other tracking information to collect information about your visits to donatedrugs.com and other websites, so that they can measure the effectiveness of ads, to track page usage and paths followed during visits, and to provide advertisements about goods and services they think will be of interest to you based on your usage. These companies may provide this data to us.
                  </p>
                </div>
                <div>
                  <p>
                    We also contract with certain third parties to help manage and optimize the CPG Services business, including but not limited to the following companies with whom we share information in order to operate the CPG Services and deliver our service to you:
                  </p>
                </div>
                <div>
                  <p>
                    We use the services of a third party marketing company, to help us measure the effectiveness of our advertising and how visitors use the CPG Services. To do this, we use Web beacons and cookies provided by this third party marketing company at the CPG Services.
                  </p>
                </div>
                <div>
                  <p>
                    We also use a third-party web developer, Gravity Jack, to design and implement our web-based distribution network and host other relevant content. Our web developers will store and have access to all information provided by customers. Gravity Jack does not use any information provided by customers for any purpose other than the development and operation of donatedrugs.com.
                  </p>
                </div>
                <div className="policy--subTitle">
                  <p>
                    Children Under Age 13
                  </p>
                </div>
                <div>
                  <p>
                    No one under the age of 13 should provide any personally identifying information to CPG. In general, CPG does not knowingly collect personally identifying information from children under 13. If in limited circumstances we do knowingly collect personally identifying information from children under the age of 13, we will do so only with verified parental consent prior to collection. In the event that we learn that we have collected such information from a child under the age of 13 without verification of parental consent, we will delete this information from our database as quickly as possible. If you believe the CPG Services has collected any personally identifying information about anyone under 13, please contact us at <a href="mailto:info@donatedrugs.com">info@donatedrugs.com</a>.
                  </p>
                </div>
                <div className="policy--subTitle">
                  <p>
                    California Residents
                  </p>
                </div>
                <div>
                  <p>
                    Users of the CPG Services who are California residents and who have provided personally identifying information to us may request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an e-mail to <a href="mailto:info@donatedrugs.com">info@donatedrugs.com</a> with "Request for California Privacy Information" in the subject line and in the message.
                  </p>
                </div>
                <div>
                  <p></p>
                </div>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
