import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

import "./TermsConditions.css";

export default class TermsConditions extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="Terms">
        <div className="terms">
          <Grid>
            <Row className="show-grid">
              <Col md={2}></Col>
              <Col md={8}>
                <h1 className="terms--title">Terms of Service</h1>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Row className="show-grid terms--content">
              <Col md={2}></Col>
              <Col md={8}>
                <div className="terms--subhead">
                  <p >
                    These Terms of Service (the "Terms of Service") and <Link to='privacypolicy'>Privacy Policy</Link> were last updated on August 9, 2018.
                  </p>
                </div>
                <div>
                  <p>
                    Welcome to donatedrugs.com, which is published by the Charitable Pharmacy Group ("CPG"), a Washington non-profit organization. These Terms of Service apply to the CPG Services located at <a href="http://www.donatedrugs.com" target="_blank" rel="noopener noreferrer">www.donatedrugs.com</a>, our free prescription drug donation facilitation services and other digital services or mobile or other applications on which a link to these Terms of Service appears (collectively, the "CPG Services"), all of which CPG Services are provided by CPG at any time and from time to time in its sole discretion. CPG does not control, and is not responsible for, any third-party site to which the CPG Services link or that links to any CPG Services.
                  </p>
                </div>
                <div>
                  <p>
                    You agree that your use of the CPG Services is subject to the following Terms of Service. If you do not agree with the Terms of Service in their entirety, please do not use any CPG Services. These Terms of Service may be modified at any time and from time to time; the date of the most recent changes or revisions will appear on this page. Continued access to the CPG Services by you will constitute your acceptance of any changes or revisions to these Terms of Service. Portions of the CPG Services may be governed by different or additional Terms of Service. In the event of a conflict between any provision of these Terms of Service, on the one hand, and any other Terms of Service, on the other hand, the applicable provision of these Terms of Service shall prevail with respect to your use of the CPG Services.
                  </p>
                </div>
                <div>
                  <p>
                    The materials and tools contained on the CPG Services are provided by CPG as a service to you for your noncommercial, personal use on an "as is, as available" basis and may be used by you for informational purposes only. You acknowledge that you are using the CPG Services at your own risk. CPG assumes no responsibility for errors or omissions in these materials. CPG makes no commitment to update the information contained herein, and expressly disclaims any and all representations or warranties, express or implied, regarding the CPG Services, including without limitation the accuracy, completeness, or reliability of text, graphics, links, products and services, and other items accessed from or via the CPG Services, or that use of the CPG Services will be uninterrupted, error-free, or free of viruses or other harmful components. No advice or information given by CPG or any other party on the CPG Services shall create any warranty or liability. The content provided through CPG Services should be used for informational purposes only; the content is not intended to be a substitute for professional, medical or legal advice. Always seek the advice of a relevant professional with any questions about any health, medical, or other decision you are seeking to make.
                  </p>
                </div>
                <div>
                  <p>
                    CPG does not warrant or make any representations regarding the use or the results of the use of the materials on the CPG Services in terms of their correctness, accuracy, timeliness, reliability, or otherwise. Under no circumstances shall CPG or any of its parents, subsidiaries, affiliates, licensors, or their respective partners, officers, directors, employees, or agents be held liable for any damages, whether direct, incidental, indirect, special, or consequential, and including, without limitation, lost revenues or lost profits, arising from or in connection with your use, reliance on, or performance of the information on the CPG Services. Your sole remedy in the event of any deficiency, defect, failure, error or interruption in the CPG Services or any goods or services offered for sale through the CPG Services shall be to request that CPG correct the matter or, if CPG fails to do so, to discontinue use of the CPG Services. In no event shall CPG’s liability exceed an amount equal to all fees paid by you to CPG in connection with your use of the CPG Services.
                  </p>
                </div>
                <div>
                  <p>
                    CPG periodically schedules system downtime for maintenance and other purposes. Unplanned outages also may occur. CPG shall have no liability for the resulting unavailability of the CPG Services or for any loss of data or transactions caused by planned or unplanned system outages, or any outages of web host providers or the Internet infrastructure and network external to the CPG Services.
                  </p>
                </div>
                <div className="terms--subTitle">
                  <p>
                    Copyright
                  </p>
                </div>
                <div>
                  <p>
                    All materials published on or in the CPG Services are protected by copyright laws, and may not be reproduced, republished, distributed, transmitted, resold, displayed, broadcast, or otherwise exploited in any manner without the express written permission of either CPG or, in the case of content licensed by CPG from third parties, the entity that is credited as the copyright holder of such licensed content.
                  </p>
                </div>
                <div>
                  <p>
                    In the event you believe that any content posted on the CPG Services infringes your copyright or other intellectual-property rights, you may notify CPG by providing the information required by the Online Copyright Infringement Liability Limitation Action of the Digital Millennium Copyright Act, 17 U.S.C. 512, to CPG's Copyright Agent:
                  </p>
                </div>
                <div className="terms--subTitle">
                  <p>
                    Attention:
                  </p>
                </div>
                <div>
                  <p>
                    Rebecca Van Keulen, Executive Director
                    <br/>
                    PO Box 1784
                    <br/>
                    Spokane, WA 99210
                  </p>
                  <p>
                    Phone: <a href="tel:2089181031">208-918-1031</a>
                    <br/>
                    Email: <a href="mailto:becky@donatedrugs.com">becky@donatedrugs.com</a>
                  </p>
                </div>
                <div className="terms--subTitle">
                  <p>
                    User Generated Content
                  </p>
                </div>
                <div>
                  <p>
                    CPG, through its forms, forums or otherwise, may provide users an opportunity to transmit or post content, which may include text, videos, photographs, images, sound files and/or other content, to the CPG Services (collectively, "User Content") and may provide for the hosting, sharing and/or publishing of such User Content. CPG does not necessarily endorse, support, sanction, encourage, or agree with User Content and expressly disclaims any and all liability in connection with any User Content.
                  </p>
                </div>
                <div>
                  <p>
                    You shall not transmit or post any User Content that: (a) is unlawful, threatening, harassing, abusive, false, inaccurate, deceptive, misleading, offensive, distasteful, inappropriate, libelous, defamatory, obscene, vulgar, pornographic, profane, indecent, invasive of another’s privacy or harmful to minors in any way; (b) constitutes or encourages conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any local, state, national, or international law; (c) is copyrighted, protected by trade secret, patent, trademark or otherwise subject to third party proprietary rights, including privacy and publicity rights, unless you are the owner of such rights or have permission from their rightful owner to submit or post the material and to grant CPG all of the license rights granted herein; (d) refers derisively to the goods or services of others; (e) includes any disruptive elements that may damage or interfere with the CPG Services such as computer viruses, bots, worms, bombs, or Trojan horses; (f) includes advertisements or solicitations of business; (g) impersonates another person; (h) includes any person’s full name or personal or identifying information without that person’s authorization; (i) violates the law or otherwise violates these Terms of Service; or (j) provides a link to any of the above.
                  </p>
                </div>
                <div className="terms--subTitle">
                  <p>
                    Jurisdiction
                  </p>
                </div>
                <div>
                  <p>
                    You agree that any claim, action, or proceeding arising out of these Terms of Service or your use of the CPG Services shall be governed by and construed in accordance with the laws of the State of Washington, applicable to contracts to be wholly performed therein, and any action based on or alleging a breach of this Agreement must be brought in a state or federal court located in Spokane County, Washington. In addition, you agree to submit to the personal jurisdiction of such courts.
                  </p>
                </div>
                <div><p></p></div>
              </Col>
              <Col md={2}></Col>
            </Row>

          </Grid>
        </div>
      </div>
    );
  }
}
