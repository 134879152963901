import React, { Component } from "react";
import {Alert, FormGroup, FormControl, ControlLabel, Grid, Row, Col, Button, Image } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import "./Donation.css";
import { UserContext } from "../../globals";
import { invokeAPI } from "../../libs/apiLib";
import AuthService from "../../libs/authService";
import ReactGA from 'react-ga';

export default class Donation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
      email: "",
      password: ""
    };
    this.Auth = new AuthService();
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }


  login(email, password) {
    let data = new FormData();
    data.append('provider', 'credentials');
    data.append('UserName', email);
    data.append('Password', password);
    return invokeAPI({
      path: `/authenticate/`,
      method: "POST",
      body: data,
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleDismiss = event => {
    event.preventDefault();
    this.setState({ isError: false });
    
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      this.Auth.login(this.state.email, this.state.password)
        .then(res => {
          this.setState({ isLoading: false });
          this.props.userHasAuthenticated(true);
          this.props.setUserContext(UserContext.Member);
          this.props.history.push("/donationform");
        })
        .catch(err => {
          console.error(err);
          this.setState({ isLoading: false });
          this.setState({ isError: true });
        })
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
      this.setState({ isError: true });
    }
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    let alertBox;

    if(this.state.isError) {
      alertBox = <Alert bsStyle="danger" onDismiss={ this.handleDismiss }>
        <h4>Login Error</h4>
        <p>
          There was an error performing the login.
        </p>
      </Alert>;
    } else {
      alertBox = '';
    }

    return (
      <div className="Donation">
        <Grid>
          <Row className="show-grid rowOne">
            <Col md={1}></Col>
            <Col md={10}>
              <Col md={4}>
                <Row>
                  <Col md={1} sm={3}></Col>
                  <Col md={10} sm={6}>
                    <div className="text-center">
                      <h4>Returning Members</h4>
                    </div>
                    <br />
                    <br />
                    <form onSubmit={this.handleSubmit}>
                      
                        <FormGroup controlId="email">
                          <ControlLabel>Username</ControlLabel>
                          <FormControl autoFocus type="email" placeholder="Email Address" value={this.state.email} onChange={this.handleChange} />
                        </FormGroup>

                      
                        <FormGroup controlId="password">
                          <ControlLabel>Password</ControlLabel>
                          <FormControl type="password" placeholder="Password" value={this.state.password} onChange={this.handleChange} />
                        </FormGroup>

                      <FormGroup className="text-center">
                        <LoaderButton bsStyle="success" disabled={!this.validateForm()} type="submit" isLoading={this.state.isLoading} text="Sign In" loadingText="Logging in…" />
                      </FormGroup>
                      {alertBox}
                    </form>
                    <div className="text-center">
                      <a href="forgotPassword">Forgot Password?</a>
                    </div>
                  </Col>
                  <Col md={1} sm={3}></Col>
                  {/* <Col md={4}><div className="wrapper">
                      <div className="line"></div>
                      <div className="wordwrapper">
                        <div className="word">Or</div>
                      </div>
                    </div>
                  </Col> */}
                </Row>

              </Col>
              <Col md={4}>
                <Row>
                  <Col md={2}>
                    <div className="wrapper">
                      <div className="line"></div>
                      <div className="wordwrapper">
                        <div className="word">Or</div>
                      </div>
                    </div>
                  </Col>
                  <Col md={8} className="text-center">
                    <h4>Create an Account</h4>
                    <br />
                    <br />
                    <p>
                      If you plan on donating more than once, you may create a secure account to save your preferences
                      and speed up the donation process.
                  </p>
                    <br />
                    <br />
                    <br />
                    <Button bsStyle="success" href="/signup">Create an Account</Button>
                  </Col>
                  <Col md={2}>
                    <div className="wrapper">
                      <div className="line"></div>
                      <div className="wordwrapper">
                        <div className="word">Or</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col md={2}></Col>
                  <Col md={8} className="text-center">
                    <h4>Donate as a Guest</h4>
                    <br />
                    <br />
                    <p>
                      If you do not want to create an account, please donate as a guest.  We will not save any of your information.
                    </p>
                    <br />
                    <br />
                    <br />
                    <Button href="/donationForm" bsStyle="success">Donate Now</Button>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </Col>
            </Col>
            <Col md={1}></Col>

          </Row>
          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>
          <Row className="show-grid rowTwo text-center">
            <Col md={3}>
            </Col>
            <Col md={6}>
              <br />
              <h4>Download Form</h4>
              <br />
              <p>
                We prefer you donate online so we can track the medications donated and report the success of our program.
                However, you are not required to use our online services.  You can print the form provided by the Department
                of Health and return your medication to any participating pharmacy.
            </p>
              <br />
              <Image src="/assets/document-ico.svg" height="60" width="60"></Image>
              <br />
              <br />
              <br />
              <Button href="donationForm.pdf" bsStyle="success">Download Form</Button>
            </Col>
            <Col md={3}>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
