import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Alert,
} from "react-bootstrap";
import { DateTime } from "react-datetime-bootstrap";
import { baseUrl } from "../../globals";
import ReactGA from "react-ga";

import "./Reports.css";

const Reports = (props) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCd, setErrorCd] = useState("");
  const [apiClient, setApiClient] = useState();
  const [startDate, setStartDate] = useState("");
  const [startDateValidation, setStartDateValidation] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [endDateValidation, setEndDateValidation] = useState(null);

  /*
  const apiUrl = baseUrl;
  const jwt = localStorage.getItem("id_token");

  var ssClient = new JsonServiceClient(apiUrl);
  ssClient.bearerToken = jwt;

  setApiClient((apiClient) => ssClient);

  const exportDonations = async () => {
    try {
      let request = new Donation();

      let response = await ssClient.get(request);
        setDrugNames(response.result);
    } catch (e) {
      console.error("Failed to get medication names:", e);
      setIsError(true);
    }
  }
    const downloadCsv = (csvString) => {
    var blob = new Blob([csvString]);
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, "export.csv");
    } else {
      var a = window.document.createElement("a");

      a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
      a.download = "export.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
*/
  const handleExport = (event) => {
    event.preventDefault();
    // check for required data
    if (!checkRequired()) return;

    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let file =
      baseUrl +
      "/reports/donations-audit?DateFrom=" +
      startDate.slice(0, 10) +
      "&DateTo=" +
      endDate.slice(0, 10) +
      "&format=csv";
    const jwt = localStorage.getItem("id_token");

    let headers = new Headers();
    headers.append("Authorization", "Bearer " + jwt);

    fetch(file, { headers })
      .then((response) => response.text())
      .then((csv) => {
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, "exportedDonations.csv");
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "exportedDonations.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
  };

  const handleStartDateChange = (dateVal, priorDateVal) => {
    setStartDate(dateVal);
    setIsError(false);
  };

  const handleEndDateChange = (dateVal, priorDateVal) => {
    setEndDate(dateVal);
    setIsError(false);
  };

  const checkRequired = () => {
    let rtnVal = true;

    if (startDate.length === 0) {
      rtnVal = false;
      setStartDateValidation("error");
    } else {
      setStartDateValidation("success");
    }
    if (endDate.length === 0) {
      rtnVal = false;
      setEndDateValidation("error");
    } else {
      setEndDateValidation("success");
    }
    if (!rtnVal) {
      setIsError(true);
      setErrorCd("MIA-201");
      setErrorMsg("Required Data Missing.");
    }

    return rtnVal;
  };
  let alertBox;

  if (isError) {
    alertBox = (
      <Alert bsStyle="danger">
        <h4>Export Donation Report Error</h4>
        <p>There was an error exporting your donation report.</p>
        <p>
          {errorCd}:{errorMsg}
        </p>
      </Alert>
    );
  } else {
    alertBox = "";
  }
  ReactGA.pageview("Reports");
  return (
    <div className="reports">
      <Form inline>
        <Grid>
          <Row>
            <Col md={12}>
              <Grid>
                <Row>
                  <Col md={2}>
                    <p>Export Donations</p>
                  </Col>
                  <Col md={4}>
                    <FormGroup validationState={startDateValidation}>
                      <DateTime
                        className="dtPicker"
                        id="startDate"
                        value={startDate}
                        onChange={handleStartDateChange}
                        pickerOptions={{ format: "L", allowInputToggle: false }}
                        placeholder="Start (YYYY-MM-DD)"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup validationState={endDateValidation}>
                      <DateTime
                        className="dtPicker"
                        id="endDate"
                        value={endDate}
                        onChange={handleEndDateChange}
                        pickerOptions={{ format: "L", allowInputToggle: false }}
                        placeholder="End (YYYY-MM-DD)"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <Button onClick={handleExport}>Export</Button>
                  </Col>
                </Row>
              </Grid>
              <hr />
            </Col>
          </Row>
        </Grid>
      </Form>
      {alertBox}
    </div>
  );
};
export default Reports;
