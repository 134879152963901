import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import "./ConfirmForgot.css";
import { UserContext } from "../../globals";
// import config from "../../config";
import { invokeAPI } from "../../libs/apiLib";
import ReactGA from 'react-ga';

export default class ConfirmForgot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  login(email, password) {
    let data = new FormData();
    data.append('provider', 'credentials');
    data.append('UserName', email);
    data.append('Password', password);
    return invokeAPI({
      path: `/auth/`,
      method: "POST",
      body: data,
    });
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }


  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      // Tony Conti: This needs to be cleaned up. ... 
      const loginData = await this.login(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.props.saveSessionData({ key: 'session_id', data: loginData.sessionId });
      this.props.saveCookies({ key: 'ss-id', data: loginData.sessionId });
      this.props.setUserContext(UserContext.Member);
      this.props.history.push("/memberhome");
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="Login">
        <Grid>
          <Row className="show-grid rowOne">
            <Col md={4}>
            </Col>
            <Col md={4}>
              <div className="text-center">
                <h4>Forgot Password</h4>
              </div>
              <br />
              <br />
              <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="email">
                  <ControlLabel>Username</ControlLabel>
                  <FormControl autoFocus type="email" value={this.state.email} onChange={this.handleChange} />
                </FormGroup>
                <FormGroup controlId="password">
                  <ControlLabel>Password</ControlLabel>
                  <FormControl value={this.state.password} onChange={this.handleChange} type="password" />
                </FormGroup>
                <FormGroup className="text-center">
                  <LoaderButton bsStyle="success" disabled={!this.validateForm()} type="submit" isLoading={this.state.isLoading} text="Sign In" loadingText="Logging in…" />
                </FormGroup>
              </form>
            </Col>
            <Col md={4}>
            </Col>
          </Row>
        </Grid>
      </div >
    );
  }
}
