import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import "./App.css";
import Routes from "./Routes";
import { UserContext } from "./globals";
import cookie from "react-cookies";

import AuthService from "./libs/authService";
import config from "./config";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isOutsideWA: false,
      userContext: UserContext.Public
    };
    this.Auth = new AuthService();
  }

  async componentDidMount() {
    try {
      if (await this.Auth.loggedIn()) {
        this.userHasAuthenticated(true);
      }
      this.getUserLocation();
    } catch (e) {
      console.error(e);
    }
  }

  getUserLocation() {
    if (window.geoip2) {
      window.geoip2.city(data => {
        if (data.subdivisions.length > 0) {
          if (data.subdivisions[0].iso_code === "WA") {
            this.setState({ isOutsideWA: false });
          } else {
            this.setState({ isOutsideWA: true });
          }
        } else {
          this.setState({ isOutsideWA: true });
        }
      });
    }
  }

  getUserType() {
    this.userProfile = this.Auth.getProfile();
    this.setUserContext(this.userProfile.roles[0]);
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
    if (authenticated) {
      this.getUserType();
    } else {
      this.userProfile = {};
    }
  };

  setUserContext = theContext => {
    this.setState({ userContext: theContext });
  };

  saveSessionData = theData => {
    localStorage.setItem(theData.key, theData.data);
  };

  saveCookies = theCookie => {
    cookie.save(theCookie.key, theCookie.data, { path: config.apiGateway });
  };

  handleLogout = event => {
    this.Auth.logout();
    this.userHasAuthenticated(false);
    this.setUserContext(UserContext.Public);
    this.props.history.push("/");
  };

  handleLogin = event => {
    if (this.userContext === UserContext.Member) {
      this.props.history.push("/memberhome");
    } else if (this.userContext === UserContext.Pharmacy) {
      this.props.history.push("/pharmacyhome");
    }
  };

  handleSplitClick = event => {
    this.props.history.push("/donate");
  };

  handleMobileMenu = page => {
    this.props.history.push(page);
  };

  handleSplitSelect = event => {
    switch (event) {
      case "1": {
        if (this.state.userContext === UserContext.Member) {
          this.props.history.push("/donationform");
        } else {
          this.props.history.push("/donation");
        }
        break;
      }
      case "2": {
        this.props.history.push("/donationpdf");
        break;
      }
      case "3": {
        this.props.history.push("/donatemoney");
        break;
      }
      default:
        break;
    }
  };

  render() {
    window.scrollTo(0, 0);
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userProfile: this.userProfile,
      setUserContext: this.setUserContext,
      userContext: this.state.userContext,
      saveSessionData: this.saveSessionData,
      saveCookies: this.saveCookies,
      handleLogout: this.handleLogout,
      handleLogin: this.handleLogin //MIKA: handleLogout: this.handleLogin
    };

    const logoutText =
      this.state.userContext === UserContext.Member
        ? "Logout Member"
        : "Logout Pharmacy";

    return (
        <div className="Site">
          <div className="Site-content">
            <Header
              handleLogout={this.handleLogout}
              handleSplitClick={this.handleSplitClick}
              handleSplitSelect={this.handleSplitSelect}
              isAuthenticated={this.state.isAuthenticated}
              userContext={this.state.userContext}
              logoutText={logoutText}
              warningBar={this.state.isOutsideWA}
              handleMobileMenu={this.handleMobileMenu}
            />
            <div className="main">
              <Routes childProps={childProps} />
            </div>
            <Footer props={childProps} />
          </div>
        </div>
    );
  }
}

export default withRouter(App);
