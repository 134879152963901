import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Row, Col, Form, FormGroup, Button } from "react-bootstrap";
import ProcessRequestModal from "../../components/ProcessRequestModal/ProcessRequestModal";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import ReactGA from 'react-ga';

import "./MedicationRequests.css";

const { SearchBar } = Search;

const MedicationRequests = props => {
    let history = useHistory();

    const [showProcessRequestModal, setShowProcessRequestModal] = useState(false);
    const [selectedMedication, setSelectedMedication] = useState({});

    const openProcessRequestModal = (e, medication) => {
        e.preventDefault();
        setSelectedMedication(medication);
        setShowProcessRequestModal(true);
    }

    const processAccept = (isError) => {
        setShowProcessRequestModal(isError);
        if(!isError) {
            props.refreshRequests();
        }
    }

    const processReject = (isError) => {
        setShowProcessRequestModal(isError);
        if(!isError) {
            props.refreshRequests();
        }
    }

    const cancelProcessRequestModal = e => {
        setShowProcessRequestModal(false);
    }

    const tableRemoveMedicationButton = (cell, row, rowIndex, formatExtraData) =>
        <Button>Fill Rx</Button>

    const clickedMedicationManagementRow = (row, isSelect, rowIndex, e) => {
    }

    const dateFormat = (cell, row) => {
        return(
            moment(cell).format("MM/DD/YYYY")
        )
    };

    const goRequestForm = e => {
        e.preventDefault();
        history.push("/findMedication");
    };

    const pagProps = paginationFactory({
        sizePerPage: 10
    });

    const sRowProps = {
        mode: 'radio',
        hideSelectColumn: true,
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => clickedMedicationManagementRow(row, isSelect, rowIndex, e),
        bgColor: 'lightgray'
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Patient Name',
            sort: false
        }, {
            dataField: 'memberId',
            text: 'Patient ID',
            type: 'number',
            sort: false
        }, {
            dataField: 'medicationRequestId',
            text: 'Request ID',
            type: 'number',
            sort: true,
            events: {}
        }, {
            dataField: 'dateRequestedUtc',
            text: 'Date Requested',
            formatter: dateFormat,
            sort: true,
            events: {}
        }, {
            dataField: 'medicationInventoryDrugName',
            text: 'Drug Name',
            sort: true,
            events: {}
        }, {
            dataField: 'fillRx',
            text: 'Action',
            isDummyField: true,
            csvExport: false,
            formatter: tableRemoveMedicationButton,
            sort: false,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => { openProcessRequestModal(e, row); }
            }
        }
    ]
    ReactGA.pageview('Medication Requests');
    return (
        <ToolkitProvider
            id="medicationRequestTable"
            keyField="medicationRequestId"
            data={props.allMedicationRequests}
            columns={columns}
            search
        >
            {
                props => (
                    <div className="medicationRequests">
                        <Grid>
                            <Row className="show-grid rowOne">
                                <Col md={12}>
                                    <Form inline onSubmit={e => { e.preventDefault(); }}>
                                        <FormGroup>
                                            <SearchBar {...props.searchProps} />
                                        </FormGroup>
                                        <FormGroup className="pull-right">
                                            <Button className="btn-success" onClick={(e) => goRequestForm(e)}>+ Add Walk-In Request</Button>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row className="show-grid rowTwo">
                                <Col md={12}>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        bordered={false}
                                        selectRow={sRowProps}
                                        pagination={pagProps}
                                        striped
                                        hover
                                        condensed
                                    />
                                </Col>
                            </Row>
                        </Grid>
                        <ProcessRequestModal show={showProcessRequestModal} medication={selectedMedication} onHide={cancelProcessRequestModal} doAccept={processAccept} doReject={processReject} />
                    </div>
                )
            }
        </ToolkitProvider>
    );
}
export default MedicationRequests;