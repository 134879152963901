import React, { Component } from "react";
import { Grid, Row, Col, Button } from "react-bootstrap";
import ReactGA from 'react-ga';

import "./DonateMoney.css";
export default class DonateMoney extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="DonateMoney">
        <div className="donateMoney">
          <Grid>
            <Row className="show-grid rowOne">
              <Col>
                <h2>Donate Money to help those in need receive the medications they require.</h2>
              </Col>
            </Row>
            <Row>
              <Col md={2}></Col>
              <Col md={4}>
                <p>
                  We are working to bridge the gap between the billions of dollars of unused and unexpired medications thrown away each year and the patients who need them.
              </p>
              </Col>
              <Col md={4}>Your help will create a prescription drug program that improves
                access to needed medications for those who can't afford them, and
                you get a nice tax write off.
                </Col>
              <Col md={2}></Col>
            </Row>
            <Row>
              <Col>
              <h4>
                Click the PayPal button below to donate directly to the Charitable Pharmacy Group.  Thank you for your donation!
            </h4><br />
                <form method="POST" action="https://www.paypal.com/cgi-bin/webscr" target="_blank">
                  <input type="hidden" name="cmd" value="_donations"/>
                  <input type="hidden" name="business" value="MFKZ7R2MYL54E"/>
                  <input type="hidden" name="currency_code" value="USD"/>
                  <input type="hidden" name="item_name" value="Charitable Pharmacy Donation" />
                  <input type="hidden" name="item_number" value="Financial Donation" />
                  <Button type="submit" bsStyle="success" bsSize="large">Donate Now</Button>
                </form>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
