import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, Grid, Row, Col, Button } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import "./LoginPharmacy.css";
import { UserContext } from "../../globals";
//import config from "../../config";
import { invokeAPI } from "../../libs/apiLib";
//import { CognitoUserPool, AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import ReactGA from 'react-ga';

export default class LoginPharmacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  async login(email, password) {
    let data = new FormData();
    data.append('provider', 'credentials');
    data.append('UserName', email);
    data.append('Password', password);
    return invokeAPI({
      path: `/auth/`,
      method: "POST",
      body: data,
    });
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }


  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const loginData = await this.login(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.props.saveSessionData({ key: 'session_id', data: loginData.sessionId });
      this.props.setUserContext(UserContext.Pharmacy);
      this.props.history.push("/pharmacyhome");
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="LoginPharmacy">
        <Grid>
          <Row className="show-grid rowOne">
            <Col md={1}>
            </Col>
            <Col md={3}>
              <div className="text-center">
                <h4>Returning Pharmacists</h4>
              </div>
              <br />
              <br />
              <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="email">
                  <ControlLabel>Username</ControlLabel>
                  <FormControl autoFocus type="email" value={this.state.email} onChange={this.handleChange} />
                </FormGroup>
                <FormGroup controlId="password">
                  <ControlLabel>Password</ControlLabel>
                  <FormControl value={this.state.password} onChange={this.handleChange} type="password" />
                </FormGroup>
                <FormGroup className="text-center">
                  <LoaderButton bsStyle="success" disabled={!this.validateForm()} type="submit" isLoading={this.state.isLoading} text="Sign In" loadingText="Logging in…" />
                </FormGroup>
              </form>
              <div className="text-center">
                <a href="forgotPassword">Forgot Password?</a>
              </div>
            </Col>
            <Col md={1}>
              <div className="wrapper">
                <div className="line"></div>
                <div className="wordwrapper">
                  <div className="word">Or</div>
                </div>
              </div>
            </Col>
            <Col md={3} className="text-center">
              <h4>Create a Pharmacy / Pharmacist Account</h4>
              <br />
              <br />
              <p>
                To participate in the program as a pharmacy / pharmacist, you have to create an account so we can activate you into our program.
            </p>
              <br />
              <br />
              <br />
              <Button href="pharmacyNew" bsStyle="success">Create an Account</Button>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
