import React from "react";
import { Modal, Grid, Row, Col, Button } from "react-bootstrap";
import ReactGA from 'react-ga';

import "./FindAPharmacyModal.css";

export default ({
  className = "",
  ...props
}) =>
  <Modal
    className={`FindAPharmacyModal ${className}`}
    bsSize="large"
    aria-labelledby="contained-modal-title-lg"
    {...props}
  >
    { ReactGA.pageview('Find A Pharmacy Modal') }
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-lg">Find a Participating Pharmacy</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="FindPharmacyModal">
        <div className="findPharmacyModal">
          <Grid>
            <Row className="show-grid row--one">
              <Col md={1}></Col>
              <Col md={10}>
                <br />
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row className="show-grid row--two">
              <Col md={1}></Col>
              <Col md={10}>
                {props.pharmacyList}
              </Col>
              <Col md={1}></Col>
            </Row>
          </Grid>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide}>Close</Button>
    </Modal.Footer>
  </Modal>;
