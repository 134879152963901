import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import ReactGA from 'react-ga';

import "./DonationDetail.css";

export default class DonationDetail extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="DonationDetail">
        <div className="donationDetail">
          <Grid>
            <Row className="show-grid rowOne">
              <Col md={2}></Col>
              <Col md={4}>
                <h2>Donation Details</h2>
                <p>
                  This is the detail of a specific donation.
              </p>
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row className="show-grid rowTwo">
              <Col>
                <h3>
                  This donation is brought to you by a group of caring individuals working for the Charitable Pharmacy Group.
              </h3>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
