import React, { Component } from "react";
import { Tabs, Tab, Grid, Row, Col, Button } from "react-bootstrap";
import PharmacySummary from "../../components/PharmacySummary/PharmacySummary";
import MedicationMagement from "../../components/MedicationManagement/MedicationManagement";
import DonationsReceived from "../../components/DonationsReceived/DonationsReceived";
import MedicationRequests from "../../components/MedicationRequests/MedicationRequests";
import DonationsArchives from "../../components/DonationsArchives/DonationsArchives";
import Reports from "../../components/Reports/Reports";
import PharmacyPreferences from "../../components/PharmacyPreferences/PharmacyPreferences";
import { baseUrl } from "../../globals";
import { JsonServiceClient } from "@servicestack/client";
import { ListDonations, ListDonatedMedications, ListMedicationRequests, ListMedications } from "../../shared/dtos"
import ReactGA from 'react-ga';

import "./PharmacyHome.css";

export default class PharmacyHome extends Component {
  constructor(props, context) {
    super(props, context);

    this.tabTitles = [
      'Summary',
      'Medication Management',
      'Donations Received',
      'Medication Requests',
      'Donation Archives',
      'Reports',
      'Settings',
      'Preferences'];

    this.state = {
      key: 1,
      tabHeader: this.tabTitles[0],
      showProcessMedicationModal: false,
      availableMedications: [],
      medicationRequests: [],
      allMedications: [],
      previousDonations: [],
      donatedMedications: [],
      allMedicationRequests: [],
      allDonations: []
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.allMedications = this.allMedications.bind(this);
    this.donatedMedications = this.donatedMedications.bind(this);
    this.allMedicationRequests = this.allMedicationRequests.bind(this);
  }

  componentDidMount() {
    try {
      ReactGA.pageview(window.location.pathname + window.location.search);
      // new up a service stack client
      const apiUrl = baseUrl
      const jwt = localStorage.getItem('id_token');

      var ssClient = new JsonServiceClient(apiUrl);
      ssClient.bearerToken = jwt;

      this.availableMedications(ssClient);
      this.medicationRequests(ssClient);
      this.allMedications();
      this.donatedMedications();
      this.allMedicationRequests();
      this.allDonations(ssClient);
    }
    catch (e) {
      console.error(e);
    }
  }
  componentDidUpdate(prevProps) {

  }
  componentWillUnmount() {

  }

  handleSelect(key) {
    this.setState({ key: key, tabHeader: this.tabTitles[key - 1] });
  }
  donationsRecievedButton(cell, row) {
    return (
      <Button bsStyle="primary">Process</Button>
    );
  }
  removeMedicationButton() {
    return (
      <Button bsStyle="primary">Fill Rx</Button>
    );
  }
  removeMedication() {
    // Either splice or slice - whatever works? But learn how to single out a single ID. ...Maybe donation ID?

  }

  async availableMedications(ssClient) {
    try {
      let request = new ListMedications();
      request.MedicationInventoryStatusIn = ["Available,OnHold"];

      let response = await ssClient.get(request);
      this.setState({ availableMedications: response.results });
    }
    catch (err) {
      console.error("error getting current donations:" + err.message);
    }
  }

  async medicationRequests(ssClient) {
    try {
      let request = new ListMedicationRequests();
      request.MedicationRequestStatusIn = ["Requested"];

      let response = await ssClient.get(request)
      this.setState({ medicationRequests: response.results });
    }
    catch (err) {
      console.error("error getting medication requests:" + err.message);
    }
  }

  async allMedications() {
    try {
      // new up a service stack client
      const apiUrl = baseUrl
      const jwt = localStorage.getItem('id_token');

      var ssClient = new JsonServiceClient(apiUrl);
      ssClient.bearerToken = jwt;

      let request = new ListMedications();

      let response = await ssClient.get(request)
      this.setState({ allMedications: response.results });
    }
    catch (err) {
      console.error("error getting all medications:" + err.message);
    }
  }

  async donatedMedications() {
    try {
      // new up a service stack client
      const apiUrl = baseUrl
      const jwt = localStorage.getItem('id_token');

      var ssClient = new JsonServiceClient(apiUrl);
      ssClient.bearerToken = jwt;

      let request = new ListDonatedMedications();

      let response = await ssClient.get(request)
      this.setState({ donatedMedications: response.results });
    }
    catch (err) {
      console.error("error getting all donated medications:" + err.message);
    }
  }

  async allMedicationRequests() {
    try {
      // new up a service stack client
      const apiUrl = baseUrl
      const jwt = localStorage.getItem('id_token');

      var ssClient = new JsonServiceClient(apiUrl);
      ssClient.bearerToken = jwt;

      let request = new ListMedicationRequests();

      let response = await ssClient.get(request)
      this.setState({ allMedicationRequests: response.results });
    }
    catch (err) {
      console.error("error getting all medication requests:" + err.message);
    }
  }

  async allDonations(ssClient) {
    try {
      let request = new ListDonations();

      let response = await ssClient.get(request)
      this.setState({ allDonations: response.results });
    }
    catch (err) {
      console.error("error getting all donations requests:" + err.message);
    }
  }

  render() {
    return (
      <div className="PharmacyHome">
        <div className="pharmacySummary">
          <Grid>
            <Row className="show-grid rowHeader">
              <Col md={1}></Col>
              <Col md={10}>
                <h1>{this.state.tabHeader}</h1>
                <br />
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row className="show-grid rowNav">
              <Col md={1}></Col>
              <Col md={10}>
                <Tabs activeKey={this.state.key} onSelect={this.handleSelect} id="pharmacyHomeMenu">
                  <Tab eventKey={1} title={this.tabTitles[0]}>
                    <PharmacySummary
                      availableMedications={this.state.availableMedications}
                      medicationRequests={this.state.medicationRequests}
                      allMedications={this.state.allMedications}
                      previousDonations={this.state.previousDonations}
                    />
                  </Tab>
                  <Tab eventKey={2} title={this.tabTitles[1]}>
                    <MedicationMagement
                      allMedications={this.state.allMedications}
                      refreshMedications={this.allMedications}
                    />
                  </Tab>
                  <Tab eventKey={3} title={this.tabTitles[2]}>
                    <DonationsReceived
                      donatedMedications={this.state.donatedMedications}
                      refreshDonations={this.donatedMedications}
                    />
                  </Tab>
                  <Tab eventKey={4} title={this.tabTitles[3]}>
                    <MedicationRequests
                      allMedicationRequests={this.state.allMedicationRequests}
                      refreshRequests={this.allMedicationRequests}
                    />
                  </Tab>
                  <Tab eventKey={5} title={this.tabTitles[4]}>
                    <DonationsArchives
                      allDonations={this.state.allDonations}
                    />
                  </Tab>
                  <Tab eventKey={6} title={this.tabTitles[5]}>
                    <Reports />
                  </Tab>
                  { /*
                  <Tab eventKey={7} title={this.tabTitles[6]}>
                    <PharmacySettings />
                  </Tab>
                  */ }
                  <Tab eventKey={8} title={this.tabTitles[7]}>
                    <PharmacyPreferences />
                  </Tab>
                </Tabs>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Grid>
        </div>
      </div>
    )
  }
}