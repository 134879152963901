import config from "../config";


import axios from 'axios';

export async function authUser() {

  if (
    localStorage.getItem('session_id')
  ) {
    await getCurrentUser();
    return true;
  }
  return false;
}

function getCurrentUser() {
  return getAuth();
}

/*
function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}
*/


export function signOutUser() {
  const currentUser = getCurrentUser();

  if (currentUser !== undefined) {
    currentUser.signOut();
  }
}

export function getAuth() {
  var authConfig = {
    headers: { 'Authorization': "bearer " + localStorage.getItem('bearerToken') }
  };

  axios.get(config.apiGateway.URL + `/auth`, {
    authConfig
  })
    .then(() => {
      return true;
    }).catch(function (error) {
      console.error('Error on Authentication');
      return false;
    });
}

export async function invokeAPI_NEW({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  body,
  data = {}
}) {
  // let signedRequest = null;

  var authConfig = {
    headers: { 'Authorization': "Bearer " + localStorage.getItem('bearerToken') }
  };

  let fullPath = config.apiGateway.URL + path;

  body = { ...body };
  if (body === undefined) {
    body = undefined;
  } else {
    body = JSON.stringify(body);
  }
  switch (method) {
    case "GET":
      axios.get(fullPath, {
        authConfig
      })
        .then(res => {
          return res;
        }).catch(function (error) {
          console.error('Error on Authentication');
          return false;
        });
      break;
    case "POST":
      return post(fullPath, headers, queryParams, body, data, authConfig)
    case "PUT":
      break;
    case "DELETE":
      break;
    default:
      break;
  }
}

/*
async function get({
  path,
  headers = {},
  queryParams = {},
  body,
  data = {},
  credentials
}) {

  axios.get(path, body, {
    credentials
  })
    .then(res => {
      return res;
    }).catch(function (error) {
      console.error('Error on Authentication');
      return false;
    });
}
*/

async function post({
  path,
  headers = {},
  queryParams = {},
  body,
  data = {},
  credentials
}) {

  axios.post(path, body, {
    credentials
  })
    .then(res => {
      return res;
    }).catch(function (error) {
      console.error('Error on Authentication');
      return false;
    });
}

export async function invokeAPI({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  body,
  data = {},
  credentials
}) {
  let signedRequest = null;
  signedRequest = buildDebugRequest({
    method,
    path,
    headers,
    queryParams,
    body,
    data
  });
  headers = signedRequest.headers;
    const results = await fetch(signedRequest.url, {
    method,
    headers,
    body,
    data,
    credentials
  });

  if (results.status !== 200) {
    throw new Error(results.status + ":" + results.statusText + ":" + results.url);
  }
  return results.json();
}

function buildDebugRequest(request) {
  let awsSigV4Client = {};
  const verb = request.method.toUpperCase();
  const path = config.apiGateway.URL + request.path;
  const queryParams = { ...request.queryParams };
  const headers = { ...request.headers };

  awsSigV4Client.defaultAcceptType = "application/json";
  awsSigV4Client.defaultContentType = "application/json";
  headers["Content-Type"] = awsSigV4Client.defaultContentType;
  headers["Accept"] = awsSigV4Client.defaultAcceptType;

  let body = { ...request.body };
  if (request.body === undefined || verb === "GET") {
    body = undefined;
  } else {
    body = JSON.stringify(body);
  }

  let data = { ...request.data };
  if (request.data === undefined) {
    data = {};
  } else {
    data = JSON.stringify(data);
  }

  let url = path;
  let queryString = buildCanonicalQueryString(queryParams);
  if (queryString !== "") {
    url += "?" + queryString;
  }
  return {
    headers: headers,
    url: url,
    body: body,
    data: data
  };
};

function buildCanonicalQueryString(queryParams) {
  if((queryParams===undefined) || (Object.keys(queryParams).length < 1)) {
    return "";
  }

  let sortedQueryParams = [];
  for (let property in queryParams) {
    if (queryParams.hasOwnProperty(property)) {
      sortedQueryParams.push(property);
    }
  }
  sortedQueryParams.sort();

  let canonicalQueryString = "";
  for (let i = 0; i < sortedQueryParams.length; i++) {
    canonicalQueryString +=
      sortedQueryParams[i] +
      "=" +
      encodeURIComponent(queryParams[sortedQueryParams[i]]) +
      "&";
  }
  return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
}


