import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import ReactGA from 'react-ga';

import "./AboutUs.css";

export default class AboutUs extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="AboutUs">
        <div className="aboutUs">
          <Grid>
            <Row className="show-grid rowTwo">
              <Col md={2}></Col>
              <Col md={8}>
                <h1 className="page-title">About Us</h1>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Row className="show-grid rowOne">
              <Col md={2}></Col>
              <Col md={8}>
              <p>
                Every year over 5 Billion Dollars in unopened and unexpired medications are thrown in the trash in the United States. Meanwhile, there are hundreds of people who cannot afford their medications. In April of 2016 we were able to pass House Bill 2458 into law. This legislation allows for patients to donate unused medications back to participating pharmacies for redistribution.
              </p>
              <p>
              The Charitable Pharmacy Board of Directors is a group of pharmacists, physicians, attorneys and business directors working together to create a program to help bridge the gap between patients who have unused prescription medications and patients who are unable to afford them.
              </p>
              </Col>
              <Col md={2}></Col>
            </Row>

          </Grid>
        </div>
      </div>
    );
  }
}
