import React, { useState, useEffect } from "react";
import {
  Alert,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  ButtonToolbar,
  Button,
  Modal,
  Col,
  Row,
} from "react-bootstrap";
import { DateTime } from "react-datetime-bootstrap";
import PhoneInput from "react-phone-number-input";
import { MedicationType, baseUrl, USStates, MedicationSource } from "../../globals";
import { JsonServiceClient } from "@servicestack/client";
import {
  ProcessMedicationRequest,
  RequestStatus,
  MedicationInventoryStatus,
  GetMedicationRequest,
  PrescriptionFillType,
} from "../../shared/dtos";
import ReactGA from 'react-ga';

import "./ProcessRequestModal.css";

const ProcessRequestModal = (props) => {
  const [apiClient, setApiClient] = useState({});
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCd, setErrorCd] = useState("");
  const [acceptError, setAcceptError] = useState(false);
  const [rejectError, setRejectError] = useState(false);
  const [medicationRequestId, setMedicationRequestId] = useState(-1);

  const [pharmacistId, setPharmacistId] = useState(-1);
  const [memberId, setMemberId] = useState(-1);
  const [statusDateUtc, setStatusDateUtc] = useState("");
  const [statusDateUtcValidation, setStatusDateUtcValidation] = useState(null);
  const [name, setName] = useState("");
  const [nameValidation, setNameValidation] = useState(null);
  const [memberEmail, setMemberEmail] = useState("");
  const [memberEmailValidation, setMemberEmailValidation] = useState(null);
  const [phone, setPhone] = useState("");
  const [phoneValidation, setPhoneValidation] = useState(null);
  const [mailToName, setMailToName] = useState("");
  const [mailToNameValidation, setMailToNameValidation] = useState(null);
  const [mailToAddress1, setMailToAddress1] = useState("");
  const [mailToAddress1Validation, setMailToAddress1Validation] = useState(null);
  const [mailToAddress2, setMailToAddress2] = useState("");
  const [mailToCity, setMailToCity] = useState("");
  const [mailToCityValidation, setMailToCityValidation] = useState(null);
  const [mailToState, setMailToState] = useState("");
  const [mailToStateValidation, setMailToStateValidation] = useState(null);
  const [mailToZip, setMailToZip] = useState("");
  const [mailToZipValidation, setMailToZipValidation] = useState(null);
  const [prescriptionFillType, setPrescriptionFillType] = useState("");
  const [prescriptionFillTypeValidation, setPrescriptionFillTypeValidation] = useState(null);
  const [prescriptionLocationType, setPrescriptionLocationType] = useState("");
  const [prescriptionLocationTypeValidation, setPrescriptionLocationTypeValidation] = useState(null);
  const [drugName, setDrugName] = useState("");
  const [drugNameValidation, setDrugNameValidation] = useState(null);
  const [expirationDate, setExpirationDate] = useState("");
  const [expirationDateValidation, setExpirationDateValidation] = useState(null);
  const [source, setSource] = useState("");
  const [sourceValidation, setSourceValidation] = useState(null);
  const [lotNumber, setLotNumber] = useState("");
  const [lotNumberValidation, setLotNumberValidation] = useState(null);
  const [ndcNumber, setNdcNumber] = useState("");
  const [ndcNumberValidation, setNdcNumberValidation] = useState(null);
  const [quantity, setQuantity] = useState(-1);
  const [quantityValidation, setQuantityValidation] = useState(null);
  const [quantityType, setQuantityType] = useState("");
  const [quantityTypeValidation, setQuantityTypeValidation] = useState(null);
  const [strengthDosage, setStrengthDosage] = useState("");
  const [strengthDosageValidation, setStrengthDosageValidation] = useState(null);
  const [medicationInventoryId, setMedicationInventoryId] = useState(-1);
  const [pharmacyId, setPharmacyId] = useState(-1);
  const [pharmacyIdValidation, setPharmacyIdValidation] = useState(null);
  const [medicationInventoryStatus, setMedicationInventoryStatus] = useState("");

  useEffect(() => {
    const apiUrl = baseUrl;
    const jwt = localStorage.getItem("id_token");

    var ssClient = new JsonServiceClient(apiUrl);
    ssClient.bearerToken = jwt;

    setApiClient(ssClient);
  }, []);

  useEffect(() => {
    const getMedReq = async () => {
      if (props.medication.medicationRequestId !== undefined) {
        // get the medication request details
        try {
          let request = new GetMedicationRequest();
          request.medicationRequestId = props.medication.medicationRequestId;

          var resp = await apiClient.get(request);
          let req = resp.result;
          let med = resp.result.medication;

          setMedicationRequestId(req.medicationRequestId);
          setPharmacistId(req.pharmacistId);
          setMemberId(req.memberId);
          setStatusDateUtc(req.statusDateUtc);
          setName(req.name);
          setMemberEmail(req.email);
          setPhone(req.phone);
          setMailToName(req.mailToName);
          setMailToAddress1(req.mailToAddress1);
          setMailToAddress2(req.mailToAddress2);
          setMailToCity(req.mailToCity);
          setMailToState(req.mailToState);
          setMailToZip(req.mailToZip);
          setPrescriptionFillType(req.prescriptionFillType);
          setPrescriptionLocationType(req.prescriptionLocationType);
          setDrugName(med.drugName);
          setExpirationDate(med.expirationDate);
          setSource(med.source);
          setLotNumber(med.lotNumber);
          setNdcNumber(med.ndcNumber);
          setQuantity(med.quantity);
          setQuantityType(med.quantityType);
          setStrengthDosage(med.strengthDosage);
          setMedicationInventoryId(med.medicationInventoryId);
          setPharmacyId(med.pharmacyId);
          setMedicationInventoryStatus(med.medicationInventoryStatus);
          setIsError(false);
        } catch (err) {
          setIsError(true);
          console.error("error creating medication:" + JSON.stringify(err));
        }
      }
    };
    getMedReq();
  }, [props.medication.medicationRequestId, apiClient]);

  useEffect(() => {
    props.doAccept(isError);
    props.doReject(isError);
  }, [isError]);

  const checkRequired = () => {
    let rtnVal = true;

    if (statusDateUtc === undefined || statusDateUtc.length === 0) {
      rtnVal = false;
      setStatusDateUtcValidation("error");
    } else {
      setStatusDateUtcValidation("success");
    }
    if (name === undefined || name.length === 0) {
      rtnVal = false;
      setNameValidation("error");
    } else {
      setNameValidation("success");
    }
    if (memberEmail === undefined || memberEmail.length === 0) {
      rtnVal = false;
      setMemberEmailValidation("error");
    } else {
      setMemberEmailValidation("success");
    }
    if (phone === undefined || phone.length === 0) {
      rtnVal = false;
      setPhoneValidation("error");
    } else {
      setPhoneValidation("success");
    }
    if (prescriptionFillType === PrescriptionFillType.Mailed) {
      if (mailToName === undefined || mailToName.length === 0) {
        rtnVal = false;
        setMailToNameValidation("error");
      } else {
        setMailToNameValidation("success");
      }
      if (mailToAddress1 === undefined || mailToAddress1.length === 0) {
        rtnVal = false;
        setMailToAddress1Validation("error");
      } else {
        setMailToAddress1Validation("success");
      }
      if (mailToCity === undefined || mailToCity.length === 0) {
        rtnVal = false;
        setMailToCityValidation("error");
      } else {
        setMailToCityValidation("success");
      }
      if (mailToState === undefined || mailToState.length === 0) {
        rtnVal = false;
        setMailToStateValidation("error");
      } else {
        setMailToStateValidation("success");
      }
      if (mailToZip === undefined || mailToZip.length === 0) {
        rtnVal = false;
        setMailToZipValidation("error");
      } else {
        setMailToZipValidation("success");
      }
    }
    if (
      prescriptionFillType === undefined ||
      prescriptionFillType.length === 0
    ) {
      rtnVal = false;
      setPrescriptionFillTypeValidation("error");
    } else {
      setPrescriptionFillTypeValidation("success");
    }
    if (
      prescriptionLocationType === undefined ||
      prescriptionLocationType.length === 0
    ) {
      rtnVal = false;
      setPrescriptionLocationTypeValidation("error");
    } else {
      setPrescriptionLocationTypeValidation("success");
    }
    if (drugName === undefined || drugName.length === 0) {
      rtnVal = false;
      setDrugNameValidation("error");
    } else {
      setDrugNameValidation("success");
    }
    if (pharmacyId === undefined || pharmacyId.length === 0) {
      rtnVal = false;
      setPharmacyIdValidation("error");
    } else {
      setPharmacyIdValidation("success");
    }
    if (expirationDate === undefined || expirationDate.length === 0) {
      rtnVal = false;
      setExpirationDateValidation("error");
    } else {
      setExpirationDateValidation("success");
    }
    if (strengthDosage === undefined || strengthDosage.length === 0) {
      rtnVal = false;
      setStrengthDosageValidation("error");
    } else {
      setStrengthDosageValidation("success");
    }
    if (lotNumber === undefined || lotNumber.length === 0) {
      rtnVal = false;
      setLotNumberValidation("error");
    } else {
      setLotNumberValidation("success");
    }
    if (ndcNumber === undefined || ndcNumber.length === 0) {
      rtnVal = false;
      setNdcNumberValidation("error");
    } else {
      setNdcNumberValidation("success");
    }
    if (quantity === undefined || quantity.length === 0) {
      rtnVal = false;
      setQuantityValidation("error");
    } else {
      setQuantityValidation("success");
    }
    if (quantityType === undefined || quantityType.length === 0) {
      rtnVal = false;
      setQuantityTypeValidation("error");
    } else {
      setQuantityTypeValidation("success");
    }
    if (source === undefined || source.length === 0) {
      rtnVal = false;
      setSourceValidation("error");
    } else {
      setSourceValidation("success");
    }
    if (
      medicationInventoryStatus === undefined ||
      medicationInventoryStatus.length === 0
    ) {
      rtnVal = false;
    }
    if (!rtnVal) {
      setRejectError(true);
      setAcceptError(true);
      setErrorCd("MIA-201");
      setErrorMsg("Required Data Missing.");
    }

    return rtnVal;
  };

  const handleStatusDateChange = (dateVal, priorDateVal) => {
    setStatusDateUtc(dateVal);
  };

  const handleChange = (e) => {
    var theId = e.target.id;
    var theVal = e.target.value;

    switch (theId) {
      case "name": {
        setName((name) => theVal);
        break;
      }
      case "memberEmail": {
        setMemberEmail((memberEmail) => theVal);
        break;
      }
      case "phone": {
        setPhone((phone) => theVal);
        break;
      }
      case "mailToName": {
        setMailToName((mailToName) => theVal);
        break;
      }
      case "mailToAddress1": {
        setMailToAddress1((mailToAddress1) => theVal);
        break;
      }
      case "mailToAddress2": {
        setMailToAddress2((mailToAddress2) => theVal);
        break;
      }
      case "mailToCity": {
        setMailToCity((mailToCity) => theVal);
        break;
      }
      case "mailToState": {
        setMailToState((mailToState) => theVal);
        break;
      }
      case "mailToZip": {
        setMailToZip((mailToZip) => theVal);
        break;
      }
      case "prescriptionFillType": {
        setPrescriptionFillType((prescriptionFillType) => theVal);
        break;
      }
      case "prescriptionLocationType": {
        setPrescriptionLocationType((prescriptionLocationType) => theVal);
        break;
      }
      case "medicationInventoryStatus": {
        setMedicationInventoryStatus((medicationInventoryStatus) => theVal);
        break;
      }
      case "drugName": {
        setDrugName((drugName) => theVal);
        break;
      }
      case "expirationDate": {
        setExpirationDate((expirationDate) => theVal);
        break;
      }
      case "source": {
        setSource((source) => theVal);
        break;
      }
      case "lotNumber": {
        setLotNumber((lotNumber) => theVal);
        break;
      }
      case "ndcNumber": {
        setNdcNumber((ndcNumber) => theVal);
        break;
      }
      case "quantity": {
        setQuantity((quantity) => theVal);
        break;
      }
      case "quantityType": {
        setQuantityType((quantityType) => theVal);
        break;
      }
      case "strengthDosage": {
        setStrengthDosage((strengthDosage) => theVal);
        break;
      }
      default: {
        console.error("Unknown Field:", theId);
      }
    }
  };

  const acceptRequest = async (e) => {
    // check for required data
    if (!checkRequired()) return;

    try {
      let reqMedReq = new ProcessMedicationRequest();
      reqMedReq.medicationRequestId = medicationRequestId;
      reqMedReq.statusDateUtc = statusDateUtc;
      reqMedReq.name = name;
      reqMedReq.email = memberEmail;
      reqMedReq.phone = phone;
      reqMedReq.mailToName = mailToName;
      reqMedReq.mailToAddress1 = mailToAddress1;
      reqMedReq.mailToAddress2 = mailToAddress2;
      reqMedReq.mailToCity = mailToCity;
      reqMedReq.mailToState = mailToState;
      reqMedReq.mailToZip = mailToZip;
      reqMedReq.prescriptionFillType = prescriptionFillType;
      reqMedReq.prescriptionLocationType = prescriptionLocationType;
      reqMedReq.medication.medicationInventoryStatus =
        MedicationInventoryStatus.Dispensed;
      reqMedReq.medication.drugName = drugName;
      reqMedReq.medication.expirationDate = expirationDate;
      reqMedReq.medication.source = source;
      reqMedReq.medication.lotNumber = lotNumber;
      reqMedReq.medication.ndcNumber = ndcNumber;
      reqMedReq.medication.quantity = quantity;
      reqMedReq.medication.quantityType = quantityType;
      reqMedReq.medication.strengthDosage = strengthDosage;
      reqMedReq.medicationRequestStatus = RequestStatus.Dispensed;

      await apiClient.put(reqMedReq);
      setAcceptError(false);
      setIsError(false);
      props.doAccept(false);
    } catch (err) {
      setAcceptError(true);
      setIsError(true);
      setErrorCd("MIA-400");
      setErrorMsg(JSON.stringify(err));
      console.error("error accepting request:" + JSON.stringify(err));
    }
  };

  const rejectRequest = async (e) => {
    // check for required data
    if (!checkRequired()) return;

    try {
      let reqMedReq = new ProcessMedicationRequest();
      reqMedReq.medicationRequestId = medicationRequestId;
      reqMedReq.statusDateUtc = statusDateUtc;
      reqMedReq.name = name;
      reqMedReq.email = memberEmail;
      reqMedReq.phone = phone;
      reqMedReq.mailToName = mailToName;
      reqMedReq.mailToAddress1 = mailToAddress1;
      reqMedReq.mailToAddress2 = mailToAddress2;
      reqMedReq.mailToCity = mailToCity;
      reqMedReq.mailToState = mailToState;
      reqMedReq.mailToZip = mailToZip;
      reqMedReq.prescriptionFillType = prescriptionFillType;
      reqMedReq.prescriptionLocationType = prescriptionLocationType;
      reqMedReq.medication.medicationInventoryStatus =
        MedicationInventoryStatus.Available;
      reqMedReq.medication.drugName = drugName;
      reqMedReq.medication.expirationDate = expirationDate;
      reqMedReq.medication.source = source;
      reqMedReq.medication.lotNumber = lotNumber;
      reqMedReq.medication.ndcNumber = ndcNumber;
      reqMedReq.medication.quantity = quantity;
      reqMedReq.medication.quantityType = quantityType;
      reqMedReq.medication.strengthDosage = strengthDosage;
      reqMedReq.medicationRequestStatus = RequestStatus.Denied;

      await apiClient.put(reqMedReq);
      setRejectError(false);
      setIsError(false);
      props.doAccept(false);
    } catch (err) {
      setRejectError(true);
      setIsError(true);
      setErrorCd("MIA-401");
      setErrorMsg(JSON.stringify(err));
      console.error("error rejecting request:" + JSON.stringify(err));
    }
  };

  const closeFillRx = () => {
    setRejectError(false);
    setAcceptError(false);
    props.onHide();
  };

  let alertBox;

  if (rejectError || acceptError) {
    alertBox = (
      <Alert bsStyle="danger">
        <h4>Process Medication Request Error</h4>
        <p>There was an error processing the new medication request.</p>
        <p>
          {errorCd}:{errorMsg}
        </p>
      </Alert>
    );
  } else {
    alertBox = "";
  }
  ReactGA.pageview('Process Request Modal');
  return (
    <div className="processRequestModal">
      <Modal
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
        {...props}
        onHide={closeFillRx}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Process Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={4}>
                <ControlLabel>
                  Medication Request Id: {medicationRequestId}
                </ControlLabel>
              </Col>
              <Col md={4}>
                <ControlLabel>Pharmacist Id: {pharmacistId}</ControlLabel>
              </Col>
              <Col md={4}>
                <ControlLabel>Member Id: {memberId}</ControlLabel>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <FormGroup validationState={statusDateUtcValidation}>
                  <ControlLabel>Request Status Date</ControlLabel>
                  <DateTime
                    className="dtPicker"
                    id="statusDateUtc"
                    value={statusDateUtc}
                    onChange={handleStatusDateChange}
                    pickerOptions={{ format: "L", allowInputToggle: false }}
                    placeholder="MM/DD/YYYY"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup validationState={nameValidation}>
                  <ControlLabel>Name</ControlLabel>
                  <FormControl
                    id="name"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup validationState={memberEmailValidation}>
                  <ControlLabel>Email</ControlLabel>
                  <FormControl
                    id="memberEmail"
                    type="text"
                    placeholder="Member Email"
                    value={memberEmail}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup controlId="phone" validationState={phoneValidation}>
                  <ControlLabel>Phone</ControlLabel>
                  <PhoneInput
                    defaultCountry="US"
                    value={phone}
                    onChange={setPhone}
                    placeholder="Member Phone"
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <FormGroup validationState={mailToNameValidation}>
                  <ControlLabel>Mail Name</ControlLabel>
                  <FormControl
                    id="mailToName"
                    type="text"
                    placeholder="Mail Name"
                    value={mailToName}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup validationState={mailToAddress1Validation}>
                  <ControlLabel>Mail Address 1</ControlLabel>
                  <FormControl
                    id="mailToAddress1"
                    type="text"
                    placeholder="Mail To Address 1"
                    value={mailToAddress1}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Mail Address 2</ControlLabel>
                  <FormControl
                    id="mailToAddress2"
                    type="text"
                    placeholder="Mail To Address 2"
                    value={mailToAddress2}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup validationState={mailToCityValidation}>
                  <ControlLabel>Mail City</ControlLabel>
                  <FormControl
                    id="mailToCity"
                    type="text"
                    placeholder="Mail To City"
                    value={mailToCity}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup controlId="mailToState" validationState={mailToStateValidation}>
                  <ControlLabel>Mail State</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="Mail To State"
                    value={mailToState}
                    onChange={handleChange}
                  >
                    { USStates.map((item, i) => {return <option key={i} value={item.value}>{item.name}</option>}) }
                  </FormControl>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup validationState={mailToZipValidation}>
                  <ControlLabel>Mail Zip</ControlLabel>
                  <FormControl
                    id="mailToZip"
                    type="text"
                    placeholder="Mail To Zip"
                    value={mailToZip}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <FormGroup controlId="prescriptionFillType" validationState={prescriptionFillTypeValidation}>
                  <ControlLabel>Prescription Fill Type</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="Prescription Fill Type"
                    value={prescriptionFillType}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    <option value="None">None</option>
                    <option value="StorePickup">Store Pickup</option>
                    <option value="Mailed">Mailed</option>
                  </FormControl>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup controlId="prescriptionLocationType" validationState={prescriptionLocationTypeValidation}>
                  <ControlLabel>Prescription Location Type</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="Prescription Location Type"
                    value={prescriptionLocationType}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    <option value="None">None</option>
                    <option value="AtPharmacy">At Pharmacy</option>
                    <option value="PhysicianWillSend">
                      Physician Will Send
                    </option>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={4}>
                <ControlLabel>
                  Medication Inventory Id: {medicationInventoryId}
                </ControlLabel>
              </Col>
              <Col md={4}>
                <FormGroup validationState={pharmacyIdValidation}>
                  <ControlLabel>Pharmacy ID</ControlLabel>
                  <FormControl
                    id="pharmacyId"
                    type="number"
                    placeholder="Pharmacy ID"
                    value={pharmacyId}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <ControlLabel>Pharmacist Id: {pharmacistId}</ControlLabel>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <FormGroup validationState={drugNameValidation}>
                  <ControlLabel>Drug Name</ControlLabel>
                  <FormControl
                    id="drugName"
                    type="text"
                    placeholder="Drug Name"
                    value={drugName}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup validationState={expirationDateValidation}>
                  <ControlLabel>Expiration Date</ControlLabel>
                  <DateTime
                    className="dtPicker"
                    id="expirationDate"
                    value={expirationDate}
                    onChange={handleStatusDateChange}
                    pickerOptions={{ format: "L", allowInputToggle: false }}
                    placeholder="MM/DD/YYYY"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup controlId="source" validationState={sourceValidation}>
                  <ControlLabel>Source</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="Source"
                    value={source}
                    onChange={handleChange}
                  >
                    { MedicationSource.map((item, i) => {return <option key={i} value={item.value}>{item.name}</option>}) }
                  </FormControl>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup validationState={lotNumberValidation}>
                  <ControlLabel>Lot Number</ControlLabel>
                  <FormControl
                    in="lotNumber"
                    type="text"
                    placeholder="Lot Number"
                    value={lotNumber}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup validationState={ndcNumberValidation}>
                  <ControlLabel>Ndc Number</ControlLabel>
                  <FormControl
                    id="ndcNumber"
                    type="text"
                    placeholder="Ndc Number"
                    value={ndcNumber}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup validationState={quantityValidation}>
                  <ControlLabel>Quantity</ControlLabel>
                  <FormControl
                    id="quantity"
                    type="text"
                    placeholder="Quantity"
                    value={quantity}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup controlId="quantityType" validationState={quantityTypeValidation}>
                  <ControlLabel>Quantity Type</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="Quantity Type"
                    value={quantityType}
                    onChange={handleChange}
                  >
                    { MedicationType.map((item, i) => {return <option key={i} value={item.value}>{item.name}</option>}) }
                  </FormControl>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup validationState={strengthDosageValidation}>
                  <ControlLabel>Strength Dosage</ControlLabel>
                  <FormControl
                    id="strengthDosage"
                    type="text"
                    placeholder="Strength Dosage"
                    value={strengthDosage}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="success" onClick={rejectRequest}>
              Reject Requested Medication
            </Button>
            <Button
              bsStyle="success"
              className="pull-right"
              onClick={acceptRequest}
            >
              Accept Requested Medication
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
        {alertBox}
      </Modal>
    </div>
  );
};

export default ProcessRequestModal;
