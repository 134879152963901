import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import ReactGA from 'react-ga';

import "./ContactUs.css";

export default class ContactUs extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="ContactUs">
        <div className="contactUs">
          <Grid>
            <Row className="show-grid rowOne">
              <Col md={2}></Col>
              <Col md={8}>
                <h1 className="page-title">Contact Us</h1>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Row className="show-grid rowTwo">
              <Col md={2}></Col>
              <Col md={8}>
                <p>
                  We are starting a great thing right here in the Inland Northwest.
                </p>
                <p>
                  We are located in Spokane, Washington.
                </p>
                <div>
                  <p>
                    Rebecca Van Keulen, Executive Director
                    <br />
                    PO Box 1784
                    <br />
                    Spokane WA, 99210
                  </p>
                  <p>
                    Phone: <a href="tel:5097619787">(509)761-9787</a>
                    <br />
                    Email: <a href="mailto:becky@donatedrugs.com">becky@donatedrugs.com</a>
                  </p>
                </div>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
