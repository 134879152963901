import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "./index.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-phone-number-input/style.css';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-166551712-1');
//Kelly's Test Account ReactGA.initialize('UA-166921715-1');

ReactDOM.render(
   <Router >
    <App />
  </Router>,
  document.getElementById("root")
);
registerServiceWorker();

export default App;