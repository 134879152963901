import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid } from "react-bootstrap";
import { UserContext } from "../../globals";
import "./Footer.css";

export class Footer extends Component {
  printFooterLinks() {
    if (this.props.props.userContext === UserContext.Member) {
      return <ul>
        <li><Link to='termsconditions'>Terms &amp; Conditions</Link></li>
        <li><Link to='privacypolicy'>Privacy Policy</Link></li>
        {/* <li><Link to='loginPharmacy'>Pharmacy Login</Link></li> */}
        <li><Link to='memberHome'>Member Preferences</Link></li>
      </ul>;
    } else if (this.props.props.userContext === UserContext.Pharmacy) {
      return <ul>
        <li><Link to='termsconditions'>Terms &amp; Conditions</Link></li>
        <li><Link to='privacypolicy'>Privacy Policy</Link></li>
        <li><Link to='/pharmacyhome'>Pharmacy Home</Link></li>
      </ul>;
    }
    else {
      return <ul>
        <li><Link to='termsconditions'>Terms &amp; Conditions</Link></li>
        <li><Link to='privacypolicy'>Privacy Policy</Link></li>
        <li><Link to='/'>Home Page</Link></li>
      </ul>;
    }
  }
  render() {
    const footerLinks = this.printFooterLinks();
    return (
      <footer className="footer">
        <Grid >
          <div className="footer--links-contain" >
            <div className="footer--links">
              <span>Copyright &copy; 2020 Charitable Pharmacy Group.  All rights reserved.</span>
              {footerLinks}
            </div>
            <div>
              <a href="https://www.facebook.com/charitablepharmacygroup/" target="_blank" rel="noopener noreferrer">
                <img src="/assets/facebook-ico.svg" alt="Facebook" height="32" width="32" />
              </a>
              {/*<a href="#twitter-url" style={{ marginLeft: "24px" }}>
                <img src="/assets/twitter-ico.svg" alt="Twitter" height="32" width="32" />
              </a> */}
            </div>
          </div>
        </Grid>
      </footer>
    );
  }
}
