import React, { Component } from "react";
import { JsonServiceClient } from "@servicestack/client";
import { ListDonatedMedications, Authenticate, ListMedicationRequests, ListMedications, CreateDonation, DonationType, DonationDetail, CreateMedicationInventory, MedicationInventoryStatus } from "../../shared/dtos"

export default class Sandbox extends Component {
    constructor(props) {
        super(props);

        //const baseUrl = "https://xapi.donatedrugs.net";
        const baseUrl = "http://localhost:5000";
        //this.PharmClient = new JsonServiceClient(baseUrl);
        this.DonorClient = new JsonServiceClient(baseUrl);


        const apiUrl = baseUrl
        const jwt = localStorage.getItem('id_token');
  
        this.PharmClient = new JsonServiceClient(apiUrl);
        this.PharmClient.BearerToken = jwt;

        this.state = {
            donorAuthResponse: '',
            pharmAuthResponse: '',
            currentDonationsReceived: [],
            currentDonationsRequested: [],
            currentMedicationInventory: []
        }

        //this.createDonation = this.createDonation.bind(this);
        this.handleCreateDonation = this.handleCreateDonation.bind(this);

        //this.createMedication = this.createMedication.bind(this);
        this.handleCreateMedication = this.handleCreateMedication.bind(this);
    }

    async componentDidMount() {
        await this.LoginDonor();
        await this.LoginPharm();
        await this.getListCurrentDonationsReceived();
        await this.getListCurrentDonationsRequested();
        await this.getListCurrentMedicationInventory();
    }

    async LoginPharm() {
        //authenticate
        let request = new Authenticate();
        request.provider = "credentials";
        request.userName = "mike@libertylakecloud.com";
        request.password = "Foobar123!";
        request.rememberMe = true;

        //authenticate:  Servicestack client will cache creds
        var authResponse = await this.PharmClient.post(request);
        this.setState({ pharmAuthResponse: authResponse });
    }

    async LoginDonor() {
        //authenticate
        let request = new Authenticate();
        request.provider = "credentials";
        request.userName = "mbrown@dbaccelerate.com";
        request.password = "Foobar123!";
        request.rememberMe = true;

        //authenticate:  Servicestack client will cache creds
        var authResponse = await this.DonorClient.post(request);
        this.setState({ donorAuthResponse: authResponse });
    }

    async getListCurrentDonationsReceived() {
        try {
            let response = await this.PharmClient.get(new ListDonatedMedications())
            this.setState({ currentDonationsReceived: response.results });
        }
        catch (err) {
            console.error("error getting list of current donations:" + err.message);
        }
    }

    async getListCurrentDonationsRequested() {
        try {
            let response = await this.PharmClient.get(new ListMedicationRequests())
            this.setState({ currentDonationsRequested: response.results });
        }
        catch (err) {
            console.error("error getting list of medication requests:" + err.message);
        }
    }

    async getListCurrentMedicationInventory() {
        try {
            let response = await this.PharmClient.get(new ListMedications())
            this.setState({ currentMedicationInventory: response.results });
        }
        catch (err) {
            console.error("error getting list of medication inventory" + err.message);
        }
    }

    async createDonation() {
        var request = new CreateDonation();
        request.donationType = DonationType.Donor;
        request.name = "Han Solo";
        request.email = "han@solo.space";
        request.address = "Mos Eisley Cantina";
        request.city = "Mos Eisley";
        request.zip = "THX";
        request.certificationDateUtc = "2020-06-01"
        request.signature = "Han";
        request.hasConsented = true;
        request.medications = [];

        var medication = new DonationDetail();
        medication.drugName = "Taxol";
        medication.expirationDate = "2020-06-01";
        medication.lotNumber = "123";
        medication.Source = "Space";
        medication.quantity = 10;
        medication.quantityType = "injections";
        medication.ndcNumber = "12344555";
        medication.strengthDosage = "Blaster";

        request.medications.push(medication);

        try {
            await this.Client.post(request);
        }
        catch (err) {
            console.error("error creating donation:" + err.message);
        }

        try {
            await this.Client.post(request);
        }
        catch (err) {
            console.error("error creating donation:" + err.message);
        }

    }

    async handleCreateDonation(event) {
        await this.createDonation();
        await this.getListCurrentDonationsReceived();
    }


    async createMedication() {
        var request = new CreateMedicationInventory();
        request.pharmacyId = 3;
        request.drugName = "Adriomicin";
        request.expirationDate = "2020-07-01";
        request.lotNumber = "xyz";
        request.source = "store";
        request.quantity = 20;
        request.quantityType = "injections";
        request.ndcNumber = "5682342";
        request.strengthDosage = "Heavy";
        request.medicationInventoryStatus = MedicationInventoryStatus.Available

        try {
            await this.PharmClient.post(request);
        }
        catch (err) {
            console.err("error creating medicaion Pharm:" + err.errorCode + err.message );
        }

        try {
            await this.DonorClient.post(request);
        }
        catch (err) {
            console.error("error creating medicaion Donor:" + err.errorCode + err.message );
        }

    }

    async handleCreateMedication(event) {
        await this.createMedication();
        await this.getListCurrentDonationsReceived();
    }

    render() {

        return (
            <div className="form-group">
                <h1>Donation</h1>
                <p>Create a donation:   <button type="button" onClick={this.handleCreateDonation}>Create Donation</button></p>
                <h1>Pharmacists Page</h1>
                <h2>Summary Page</h2>
                <ul>
                    <li><p>Current Donations Received Count:{this.state.currentDonationsReceived.length}</p></li>
                    <li><p>Current Donations Requested Count:{this.state.currentDonationsRequested.length}</p></li>
                    <li><p>Current Medication Inventory Count:{this.state.currentMedicationInventory.length}</p></li>
                </ul>
                <h2>Medication Management</h2>
                <ul>
                    <li>Search medication</li>
                    <li>Add Medication:  <button type="button" onClick={this.handleCreateMedication}>Create Medication</button></li>
                </ul>
                <h2>Donations Received</h2>
                <ul>
                    <li>Search Donations</li>
                    <li>Receive / Process donation</li>
                </ul>
                <h2>Medication Requests</h2>
                <ul>
                    <li>Search Requests</li>
                    <li>Process request</li>
                </ul>
                <h2>Donation Archives</h2>
                <ul>
                    <li>Search Donations</li>
                </ul>
            </div>
        );
    }
}