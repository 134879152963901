import React, { Component } from "react";
import {
  Alert,
  Grid,
  Row,
  Col,
  Button,
  Form,
  FormGroup
} from "react-bootstrap";
import MedicationRequestModal from "../../components/MedicationRequestModal/MedicationRequestModal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { invokeAPI } from "../../libs/apiLib";
import AuthService from "../../libs/authService";
import { MedicationInventoryStatus } from "../../shared/dtos";
import ReactGA from 'react-ga';

import "./FindMedication.css";

const { SearchBar } = Search;
export default class FindMedication extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isError: false,
      medQuery: "",
      medicationLoaded: false,
      showMedicationRequestModal: false,
      selectedMedication: {},
      requestData: {
        prescriptionLocation: "",
        fillOption: "",
        name: "",
        email: "",
        phone: "",
        memberId: ""
      },
      medication: []
    };

    if (this.props.isAuthenticated) {
      this.state.requestData.email = this.props.userProfile.email;
      this.state.requestData.memberId = localStorage.getItem("userId");
    }
    this.Auth = new AuthService();
    this.openMedicationRequestModal = this.openMedicationRequestModal.bind(
      this
    );
    this.medicationModalContinue = this.medicationModalContinue.bind(this);
    this.closeMedicationRequestModal = this.closeMedicationRequestModal.bind(
      this
    );
    this.sendRequest = this.sendRequest.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ isError: false });
    // Get the data.
    try {
      const results = await this.getMedication();
      this.setState({
        medication: results.results,
        medicationLoaded: true
      });
    } catch (e) {
      console.error("Failed to find Medication:", e);
      this.setState({ isError: true });
    }
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  openMedicationRequestModal(e, medication) {
    e.preventDefault();
    this.setState({ selectedMedication: medication }, () =>
      this.setState({ showMedicationRequestModal: true })
    );
  }
  closeMedicationRequestModal(bSubmit) {
    this.setState({ showMedicationRequestModal: false });
  }

  sendRequest() {
    this.handleRequest();
  }
  medicationModalContinue(event) {
    this.setState({ showMedicationRequestModal: false });
  }

  async handleRequest() {
    try {
      let data = new FormData();
      data.append(
        "medicationInventoryId",
        this.state.selectedMedication.medicationInventoryId
      );
      data.append(
        "PrescriptionLocationTypeId",
        this.state.requestData.prescriptionLocation
      );
      data.append("PrescriptionFillTypeId", this.state.requestData.fillOption);
      data.append("DateRequestedUtc", new Date().toISOString());

      // TONY CONTI - We don't always have this.
      data.append("memberId", this.state.requestData.memberId);
      this.Auth.requestMedication(data)
        .then(res => {
        })
        .catch(err => {
        });
    } catch (e) {
      console.error("Failed to find Medication", e);
    }
  }

  getMedication() {
    // MIKA: Here you go.
    return invokeAPI({
      path: `/medications/inventory`,
      method: "GET",
      queryParams: {
        DrugNameContains: this.state.medQuery,
        MedicationInventoryStatus: MedicationInventoryStatus.Available
      }
    });
  }

  requestButton = (cell, row, rowIndex, formatExtraData) =>
    <Button>Request</Button>

  handleSubmit = async event => {
    event.preventDefault();
  };

  handleDismiss() {
    this.setState({ isError: false });
  }

  render() {
    var rowClass = this.state.medicationLoaded
      ? "show-grid row--one with-results"
      : "show-grid row--one";

    const pagProps = paginationFactory({
      sizePerPage: 10
    });

    const sRowProps = {
      mode: "radio",
      hideSelectColumn: true,
      clickToSelect: true,
      bgColor: "lightgray"
    };

    const columns = [
      {
        dataField: "drugName",
        text: "Medication",
        sort: true
      },
      {
        dataField: "quantityType",
        text: "Type",
        sort: true
      },
      {
        dataField: "strengthDosage",
        text: "Dosage",
        sort: true
      },
      {
        dataField: "pharmacyName",
        text: "Pharmacy",
        sort: true
      },
      {
        dataField: 'request',
        text: 'Request',
        isDummyField: true,
        csvExport: false,
        formatter: this.requestButton,
        sort: false,
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { this.openMedicationRequestModal(e, row); }
        }
      }
    ];

    let alertBox;

    if (this.state.isError) {
      alertBox = (
        <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
          <h4>Search Error</h4>
          <p>There was an error performing the search.</p>
        </Alert>
      );
    } else {
      alertBox = "";
    }

    return (
      <div className="FindMedication">
        <div className="findMedication">
          <Grid fluid={true}>
            <Row className={rowClass}>
              <Col md={2}></Col>
              <Col md={8}>
                <div className="row-title">
                  <h2>Find Medication</h2>
                  <br />
                  <p>
                    If you are not able to afford your medication copay or cost
                    of the entire medication, we may be able to help you. Search
                    for the medication your physician has prescribed to see if
                    we have it in stock. If your medication does not come up,
                    please contact your physician to see if there is a brand
                    name or generic name that you may also search for. If we
                    have the medication, follow the steps to get the medication
                    from our program.
                  </p>
                </div>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Row className="show-grid row--two">
              <Col md={2}></Col>
              <Col md={8}>
                <ToolkitProvider
                  id="medicationTable"
                  keyField="medicationInventoryId"
                  data={this.state.medication}
                  columns={columns}
                  search={{ searchFormatted: true }}
                >
                  {props => (
                    <div className="findMedication">
                      <Grid>
                        <Row className="show-grid rowOne">
                          <Col md={10}>
                            <Form inline>
                              <FormGroup>
                                <SearchBar {...props.searchProps} />
                              </FormGroup>
                            </Form>
                          </Col>
                        </Row>
                        <Row className="show-grid rowTwo">
                          <Col md={10}>
                            <BootstrapTable
                              {...props.baseProps}
                              selectRow={sRowProps}
                              pagination={pagProps}
                              bordered={false}
                              striped
                              hover
                              condensed
                            />
                            {!this.state.medicationLoaded &&
                                <Row className="show-grid ">
                                  <Col md={6}>
                                    <span>
                                      Your medication is not currently in our inventory. Ask your
                                      doctor if there is a generic or different type of medication you
                                      could also search for.
                                    </span>
                                  </Col>
                                </Row>
                            }
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Grid>
        </div>
        {alertBox}
        <MedicationRequestModal
          medication={this.state.selectedMedication}
          sendRequest={this.sendRequest}
          requestData={this.state.requestData}
          show={this.state.showMedicationRequestModal}
          onHide={this.closeMedicationRequestModal}
        />
      </div>
    );
  }
}
