import React, { Component } from "react";
import { Col, Row, MenuItem, Image, Nav, NavItem, Navbar, SplitButton } from "react-bootstrap";
import RouteNavItem from "../RouteNavItem";
import { UserContext } from "../../globals";
import { slide as Menu } from 'react-burger-menu';

import "./Header.css";

class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showWarningBar: this.props.warningBar,
      isMenuOpen: false
    };
    this.handleMobileMenu = this.handleMobileMenu.bind(this);
  }
  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.warningBar !== this.props.warningBar) {
      this.setState({ showWarningBar: this.props.warningBar });
    }
  }

  componentWillUnmount() {
  }

  closeWarning = event => {
    this.setState({ showWarningBar: false });
  }

  headerClick = async event => {
    event.preventDefault();
  }

  handleStateChange(state) {
    this.setState({ isMenuOpen: state.isOpen })
  }
  handleMobileMenu = page => {
    this.setState({ isMenuOpen: false });
    if (page === 'logout') {
      this.props.handleLogout();
    } else {
      this.props.handleMobileMenu(page);
    }
  }

  render() {
    return (
      <div className="App-header">
        {this.state.showWarningBar === true ?
          <div className="warning-bar">
            <Row className="">
              <Col md={2}></Col>
              <Col md={8}>
                <Image src="/assets/Seal_of_Washington.png" />
                <p>Please note that at this time, the services provided on this website are available only to Washington residents in
    accordance with RCW 69.70. All non-residents must comply with their state and applicable federal laws regarding
    participation.
          </p>
              </Col>
              <Col md={2}></Col>
            </Row>
            <div className="close" onClick={this.closeWarning}><p>X</p></div>
          </div> : ""
        }

        <Navbar collapseOnSelect>
          <Navbar.Header>
          <Navbar.Brand>
            <RouteNavItem href={this.props.userContext === UserContext.Pharmacy ? '/pharmacyhome' : '/'}>
              <Image src="/assets/cpg-logo.svg" />
            </RouteNavItem>
          </Navbar.Brand>
          </Navbar.Header>
          <Navbar.Collapse className="mainHeader">
            <Nav bsStyle="pills" pullLeft>
              <RouteNavItem key={1} href="/aboutUs" >
                About
              </RouteNavItem>
              <RouteNavItem key={2} href="/contactUs">
                Contact Us
              </RouteNavItem>
            </Nav>
            <Nav className="right" pullRight bsStyle="pills">
              <NavItem componentClass='span'>
                <SplitButton
                  className="navbar-link"
                  title="Donate"
                  key={3}
                  id="donateSplitButton"
                  onClick={this.props.handleSplitClick}
                  onSelect={this.props.handleSplitSelect}

                >
                  <MenuItem eventKey="1">Online Donation Form</MenuItem>
                  <MenuItem eventKey="2">Download Donation Form</MenuItem>
                  <MenuItem eventKey="3">Financial Donations</MenuItem>
                </SplitButton>
              </NavItem>
              <RouteNavItem key={4} href="/findMedication">
                Find RX
              </RouteNavItem>
              <RouteNavItem key={5} href="/findPharmacy">
                Pharmacies
              </RouteNavItem>
              {this.props.isAuthenticated
                ? <NavItem onClick={this.props.handleLogout} className="btn">{this.props.logoutText}</NavItem>
                : [
                  <RouteNavItem key={6} href="/login" className="btn btn-success">
                    Sign In
                    </RouteNavItem>
                ]}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Menu right isOpen={this.state.isMenuOpen}
          onStateChange={(state) => this.handleStateChange(state)}>

          <NavItem onClick={() => this.handleMobileMenu('/')} >Home</NavItem>
          <NavItem onClick={() => this.handleMobileMenu('/aboutUs')} >About</NavItem>
          <NavItem onClick={() => this.handleMobileMenu('/donate')} >Donate</NavItem>
          <NavItem onClick={() => this.handleMobileMenu(this.props.userContext === UserContext.Member ? '/donationForm' : '/donation')} >Online Donation Form</NavItem>
          <NavItem onClick={() => this.handleMobileMenu('/donationPdf')} >Download Donation Form</NavItem>
          <NavItem onClick={() => this.handleMobileMenu('/donateMoney')} >Financial Donations</NavItem>
          <NavItem onClick={() => this.handleMobileMenu('/findMedication')} >Find RX</NavItem>
          <NavItem onClick={() => this.handleMobileMenu('/findPharmacy')} >Pharmacies</NavItem>

          {this.props.isAuthenticated
            ? <NavItem key={8} onClick={() => this.handleMobileMenu('logout')} >{this.props.logoutText}</NavItem>
            : <NavItem key={8} onClick={() => this.handleMobileMenu('/login')} >Login</NavItem>
          }
        </Menu>
      </div>
    );
  }
}

export default Header