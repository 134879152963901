import React, { useState, forwardRef } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Row, Col, Form, FormGroup, Button } from "react-bootstrap";
import ProcessMedicationModal from "../../components/ProcessMedicationModal/ProcessMedicationModal";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import ReactGA from 'react-ga';

import "./DonationsReceived.css";

const { SearchBar } = Search;

const DonationsReceived = forwardRef((props, ref) => {
    let history = useHistory();

    const [showProcessMedicationModal, setShowProcessMedicationModal] = useState(false);
    const [selectedMedication, setSelectedMedication] = useState({});

    const openProcessMedicationModal = (e, medication) => {
        e.preventDefault();
        setSelectedMedication(medication);
        setShowProcessMedicationModal(true);
    }

    const closeProcessMedicationModal = (isError) => {
        setShowProcessMedicationModal(isError);
        if(!isError) {
            props.refreshDonations();
        }
    }

    const hideProcessMedicationModal = (e) => {
        setShowProcessMedicationModal(false);
    }

    const donationsReceivedButton = (cell, row, rowIndex, formatExtraData) =>
        <Button>Process</Button>

    const clickedDonationReceivedRow = (row, isSelect, rowIndex, e) => {
    };

    const dateFormat = (cell, row) => {
        return(
            moment(cell).format("MM/DD/YYYY")
        )
    };

    const goDonationForm = e => {
        e.preventDefault();
        history.push("/donationform");
    };

    const pagProps = paginationFactory({
        sizePerPage: 10
    });

    const sRowProps = {
        mode: 'radio',
        hideSelectColumn: true,
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => { clickedDonationReceivedRow(row, isSelect, rowIndex, e); },
        bgColor: 'lightgray'
    };

    const columns = [
        {
            dataField: 'donatedMedicationId',
            text: 'Donated Medication ID',
            hidden: true
        },
        {
            dataField: 'donationId',
            text: 'Donation ID',
            sort: true,
            events: {}
        }, {
            dataField: 'donationName',
            text: 'Donor',
            sort: true,
            events: {}
        }, {
            dataField: 'drugName',
            text: 'Drug',
            sort: true,
            events: {}
        }, {
            dataField: 'lotNumber',
            text: 'Lot',
            events: {}
        }, {
            dataField: 'expirationDate',
            text: 'Expires',
            sort: true,
            formatter: dateFormat,
            events: {}
        }, {
            dataField: 'quantity',
            text: 'Qty.',
            events: {}
        }, {
            dataField: 'quantityType',
            text: 'Type',
            events: {}
        }, {
            dataField: 'strengthDosage',
            text: 'Dosage',
        }, {
            dataField: 'donatedMedicationStatus',
            text: 'Status',
            sort: true,
            events: {}
        }, {
            dataField: 'pharmacistId',
            text: 'Pharmacist',
            sort: true,
            events: {}
        }, {
            dataField: 'process',
            text: 'Action',
            isDummyField: true,
            csvExport: false,
            formatter: donationsReceivedButton,
            sort: false,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => { openProcessMedicationModal(e, row); }
            }
        }
    ]
    ReactGA.pageview('Donations Received');
    return (
        <ToolkitProvider
            id="donationsTable"
            ref={ref}
            keyField="donatedMedicationId"
            data={props.donatedMedications}
            columns={columns}
            search
        >
            {
                props => (
                    <div className="donationsReceived">
                        <Grid>
                            <Row className="show-grid rowOne">
                                <Col md={12}>
                                    <Form inline onSubmit={e => { e.preventDefault(); }}>
                                        <FormGroup>
                                            <SearchBar {...props.searchProps} />
                                        </FormGroup>
                                        <FormGroup className="pull-right">
                                            <Button className="btn-success" onClick={(e) => goDonationForm(e)}>+ Add Walk-In Donation</Button>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row className="show-grid rowTwo">
                                <Col md={12}>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        bordered={false}
                                        selectRow={sRowProps}
                                        pagination={pagProps}
                                        striped
                                        hover
                                        condensed
                                    />
                                </Col>
                            </Row>
                        </Grid>
                        <ProcessMedicationModal show={showProcessMedicationModal} onHide={hideProcessMedicationModal} doAccept={closeProcessMedicationModal} doReject={closeProcessMedicationModal} selectedMed={selectedMedication} />
                    </div>
                )
            }
        </ToolkitProvider>
    );
}
);
export default DonationsReceived;
