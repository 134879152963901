import { CognitoUserPool } from "amazon-cognito-identity-js";
import config from "../config";
import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import sigV4Client from "./sigV4Client";

export async function authUser() {
  if (
    AWS.config.credentials &&
    Date.now() < AWS.config.credentials.expireTime - 60000
  ) {
    return true;
  }

  const currentUser = getCurrentUser();

  if (currentUser === null) {
    return false;
  }

  const userToken = await getUserToken(currentUser);

  await getAwsCredentials(userToken);

  return true;
}
function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

function getCurrentUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: config.cognito.USER_POOL_ID,
    ClientId: config.cognito.APP_CLIENT_ID
  });
  return userPool.getCurrentUser();
}

export function signOutUser() {
  const currentUser = getCurrentUser();

  if (currentUser !== null) {
    currentUser.signOut();
  }

  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
  }
}

function getAwsCredentials(userToken) {
  const authenticator = `cognito-idp.${config.cognito
    .REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`;

  AWS.config.update({ region: config.cognito.REGION });

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
    Logins: {
      [authenticator]: userToken
    }
  });

  return AWS.config.credentials.getPromise();
}

export async function invokeApig({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  body
}) {
  if (!await authUser()) {
    throw new Error("User is not logged in");
  }

  const signedRequest = sigV4Client
    .newClient({
      accessKey: AWS.config.credentials.accessKeyId,
      secretKey: AWS.config.credentials.secretAccessKey,
      sessionToken: AWS.config.credentials.sessionToken,
      region: config.apiGateway.REGION,
      endpoint: config.apiGateway.URL
    })
    .signRequest({
      method,
      path,
      headers,
      queryParams,
      body
    });

  body = body ? JSON.stringify(body) : body;
  headers = signedRequest.headers;

  const results = await fetch(signedRequest.url, {
    method,
    headers,
    body
  });

  if (results.status !== 200) {
    throw new Error(await results.text());
  }

  return results.json();
}

export async function s3Upload(file) {
  if (!await authUser()) {
    throw new Error("User is not logged in");
  }

  const s3 = new S3({
    params: {
      Bucket: config.s3.BUCKET
    }
  });
  const filename = `${AWS.config.credentials
    .identityId}-${Date.now()}-${file.name}`;

  return s3
    .upload({
      Key: filename,
      Body: file,
      ContentType: file.type,
      ACL: "public-read"
    })
    .promise();
}

// DEBUG Teating with Json Server
/*
function buildDebugRequest(request) {
  let awsSigV4Client = {};
  const verb = request.method.toUpperCase();
  const path = config.apiGateway.URL + request.path;
  const queryParams = { ...request.queryParams };
  const headers = { ...request.headers };
  awsSigV4Client.defaultAcceptType = "application/json";
  awsSigV4Client.defaultContentType = "application/json";

  headers["Content-Type"] = awsSigV4Client.defaultContentType;
  headers["Accept"] = awsSigV4Client.defaultAcceptType;

  let body = { ...request.body };

  if (request.body === undefined || verb === "GET") {
  } else {
    body = JSON.stringify(body);
  }

  let data = { ...request.data };
  if (request.data === undefined) {
    data = {};
  } else {
    data = JSON.stringify(data);
  }

  let url = path;
  let queryString = buildCanonicalQueryString(queryParams);
  if (queryString !== "") {
    url += "?" + queryString;
  }

  headers["Content-Type"] = awsSigV4Client.defaultContentType;
  return {
    headers: headers,
    url: url,
    body: body,
    data: data
  };
};

function buildCanonicalQueryString(queryParams) {
  if (Object.keys(queryParams).length < 1) {
    return "";
  }

  let sortedQueryParams = [];
  for (let property in queryParams) {
    if (queryParams.hasOwnProperty(property)) {
      sortedQueryParams.push(property);
    }
  }
  sortedQueryParams.sort();

  let canonicalQueryString = "";
  for (let i = 0; i < sortedQueryParams.length; i++) {
    canonicalQueryString +=
      sortedQueryParams[i] +
      "=" +
      encodeURIComponent(queryParams[sortedQueryParams[i]]) +
      "&";
  }
  return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
}
*/