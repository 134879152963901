import React, { useState } from "react";
import {
  Alert,
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar
} from "react-bootstrap";
import ReactGA from 'react-ga';

import "./PharmacyPreferences.css";

const PharmacyPreferences = props => {
  const [isError, setIsError] = useState(false);

  const handleDismiss = e => {
    setIsError(false);
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    try {
      // Do the Password Update
      setIsError(false);
    } catch (e) {
      setIsError(true);
      console.error(e);
    }
  };

  let alertBox;

  if (isError) {
    alertBox = (
      <Alert bsStyle="danger" onDismiss={handleDismiss}>
        <h4>Change Password Error</h4>
        <p>There was an error changing your password.</p>
      </Alert>
    );
  } else {
    alertBox = "";
  }
  ReactGA.pageview('Pharmacist - Preferences');
  return (
    <div className="pharmacyPreferences">
      <br />
      <h4>Update Password</h4>
      <br />
      <Grid>
        {/*
          <Row className="show-grid rowOne">
            <Col md={10}>
              <Form inline>
                <FormGroup controlId="currentEmail">
                  <ControlLabel>Current Email</ControlLabel><br /><br />
                  <FormControl type="email" placeholder="Current Email" />
                </FormGroup>
                <span className="formSpace"></span>
                <FormGroup>
                  <ControlLabel>New Email</ControlLabel><br /><br />
                  <FormControl type="email" placeholder="New Email" />
                </FormGroup>
                <span className="formSpace"></span>
                <FormGroup>
                  <ControlLabel>Confirm Email</ControlLabel><br /><br />
                  <FormControl type="email" placeholder="Confirm Email" />
                </FormGroup>
                <span className="formSpace"></span>
                <FormGroup>
                  <ButtonToolbar>
                    <Button bsStyle="primary" type="submit">Change</Button>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </Col>
          </Row>
                */}
        <Row className="show-grid rowOne">
          <Col md={10}>
            <br />
            <Form inline onSubmit={handleSubmit}>
              <FormGroup controlId="currentPassword">
                <ControlLabel>Current Password</ControlLabel>
                <br />
                <br />
                <FormControl type="password" placeholder="Current Password" />
              </FormGroup>
              <span className="formSpace"></span>
              <FormGroup controlId="newPassword">
                <ControlLabel>New Password</ControlLabel>
                <br />
                <br />
                <FormControl type="password" placeholder="New Password" />
              </FormGroup>
              <span className="formSpace"></span>
              <FormGroup controlId="confirmPassword">
                <ControlLabel>Confirm Password</ControlLabel>
                <br />
                <br />
                <FormControl type="password" placeholder="Confirm Password" />
              </FormGroup>
              <span className="formSpace"></span>
              <FormGroup>
                <ButtonToolbar>
                  <Button bsStyle="primary" type="submit">
                    Change
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Grid>
      {alertBox}
    </div>
  );
};
export default PharmacyPreferences;
