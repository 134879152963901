import React, { Component } from "react";

import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { Button, Grid, Row, Col, Form, FormGroup, FormControl } from "react-bootstrap";
import ReactGA from 'react-ga';

import "../DonationForm/DonationForm.css";
import "./DonationFormThankYou.css";

export default class DonationFormThankYou extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signature:'',
      isLoading:false,
      selectedPharmId: props.getDS().pharmacyId,
      pharmacyAddress: props.getDS().pharmacyAddress,
      pharmacyState: props.getDS().pharmacyState,
      pharmacyCity: props.getDS().pharmacyCity,
      pharmacyZip: props.getDS().pharmacyZip,
      pharmacyPhone: props.getDS().pharmacyPhone,
      pharmacyName: props.getDS().pharmacyName,
      hasConsented: props.getDS().hasConsented
    };
    this.userConsents = this.userConsents.bind(this);
    this.doPrint = this.doPrint.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentWillUnmount() {
    this.props.updateDS({
      hasConsented: this.state.hasConsented});
    this._mounted = false
  }

  validateForm() {
    return this.state.signature.length > 0;
  }

  userConsents(event) {
    if (event) {
      this.setState({ hasConsented: true }, () => this.sendToServer());
    }
  }

  doPrint(event) {
    if(event) {
      window.print();
    }
  }

  sendToServer() {
    this.props.updateDS({
      hasConsented: this.state.hasConsented
    });
    this.props.submitAll()
  }

  renderPage() {
    if (this.state.hasConsented === false) {
      return this.renderConsent();
    }
    else {
      return this.renderThanks();
    }
  }

  renderConsent() {
    return <Form>
      <FormGroup>
        <Grid>
          <Col md={12}>
            <div className="">
                <div>
                Due to the nature of the services provided through this website, we are required to collect certain Personally Identifying Information (“PII”)  and Personal Health Information (“PHI”) from you, our customer, prior to engaging in online transactions such as donating medication, requesting medication, accepting donated medication, and communicating with our participating pharmacies.  If you elect to use the services offered on this website either as a donor or a recipient, you will be asked to provide PII and PHI during the registration process.
                We are a voluntary participant in a state sanctioned donation program. We are not required to provide the services offered on this website to anyone and your participation is likewise voluntary. IF YOU DO NOT WISH TO PROVIDE YOUR PII OR PHI, YOU SHOULD NOT REGISTER FOR SERVICES AS YOUR REGISTRATION WILL BE DEEMED CONSENT TO STORE AND USE YOUR PII AND PHI FOR THE PURPOSES STATED ON THIS WEBSITE AND IN OUR TERMS OF SERVICE.
                By entering your full name below and clicking the “CONTINUE”  button, you are consenting to storage and use of the information you provide in the registration process which includes PII and PHI for the purposes stated on this website and in our Terms of Service.
                I, <FormControl type="text" value={this.state.signature} onChange={(event) => this.setState({ signature: event.target.value })} /> hereby acknowledge that I have read and understand the Terms of Service and Privacy Policy and I am knowingly providing my Personally Identifying Information and Personal Health Information to be used for the purposes stated on this website.
          </div>
            </div>
          </Col>
          <Row className="show-grid go-button">
            <Col md={1}></Col>
            <Col md={10}>
              <LoaderButton bsStyle="success" disabled={!this.validateForm()} onClick={(event) => this.userConsents(event)}
               isLoading={this.state.isLoading} text="Continue" loadingText="Logging in…" />
            </Col>
            <Col md={1}></Col>
          </Row>
        </Grid>
      </FormGroup>
    </Form>
  }

  renderThanks() {
    return <div>
        <h1>Thank You!</h1>
        <p>
          Your donation means the world to patients who need this medication and are not able to afford it otherwise.<br />
          Thank you for your time and effort to help others in your community.<br />
          You will receive an email with a confirmation of your donation and where you are to return the medication.<br/>
          <br />
          <strong>
            This is verification you have completed your submission to donate medication.<br />
            Please print this page and complete your donation by delivering your medication to the pharmacy you selected.<br/>
          </strong>
          </p>
        <br />
        <h3>Pharmacy Information</h3>
        <br />
        <span>
          {this.state.pharmacyName}<br />
          {this.state.pharmacyAddress}<br />
          {this.state.pharmacyCity}, {this.state.pharmacyState} , {this.state.pharmacyZip}
          <br />
          <br />
          Hours: 8:30AM-6:00PM
          <br />
          <br />
          Phone Number: {this.state.pharmacyPhone}
          <br />  
          <br />
        </span>
      </div>;
  }

  render() {
    const outputHTML = this.renderPage();
    return (
      <div className="step DonationFormThankYou">
        {outputHTML}
        <div>
          <Button bsStyle="success" onClick={(event) => this.doPrint(event)}>Print</Button>
        </div>
      </div>
    )
  }
}
