import React, { Component } from "react";
import {
  Alert,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  ButtonToolbar,
  Modal
} from "react-bootstrap";
import { DateTime } from "react-datetime-bootstrap";
import { MedicationType, baseUrl, MedicationSource } from "../../globals";
import { JsonServiceClient } from "@servicestack/client";
import {
  ProcessDonatedMedication,
  DonatedMedicationStatus,
  UpdateMedicationInventory,
  MedicationInventoryStatus
} from "../../shared/dtos";
import ReactGA from 'react-ga';

import "./ProcessMedicationModal.css";

export default class ProcessMedicationModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
      isError: false,
      errorCd: '',
      errorMsg: '',
      apiClient: {},
      donatedMedicationRequest: new ProcessDonatedMedication(),
      DonatedMedicationId: -1,
      DonationId: -1,
      PharmacyId: -1,
      PharmacyIdValidation: null,
      DrugName: "",
      DrugNameValidation: null,
      ExpirationDate: "",
      ExpirationDateValidation: null,
      LotNumber: "",
      LotNumberValidation: null,
      Source: "",
      SourceValidation: null,
      Quantity: -1,
      QuantityValidation: null,
      QuantityType: "",
      QuantityTypeValidation: null,
      NdcNumber: "",
      NdcNumberValidation: null,
      StrengthDosage: "",
      StrengthDosageValidation: null,
      DonationStatusDateUtc: "",
      DonationStatusDateUtcValidation: null,
      PharmacistId: -1
    };
  }

  componentDidMount() {
    const apiUrl = baseUrl;
    const jwt = localStorage.getItem("id_token");

    var ssClient = new JsonServiceClient(apiUrl);
    ssClient.bearerToken = jwt;
    this.setState((state, props) => ({
      apiClient: ssClient
    }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMed.donationId !== this.props.selectedMed.donationId) {
      this.setState((state, props) => ({
        DonationId: props.selectedMed.donationId
      }));
    }
    if (prevProps.selectedMed.donatedMedicationId !== this.props.selectedMed.donatedMedicationId) {
      this.setState((state, props) => ({
        DonatedMedicationId: props.selectedMed.donatedMedicationId
      }));
    }
    if (prevProps.selectedMed.pharmacyId !== this.props.selectedMed.pharmacyId) {
      this.setState((state, props) => ({
        PharmacyId: props.selectedMed.pharmacyId
      }));
    }
    if (prevProps.selectedMed.pharmacistId !== this.props.selectedMed.pharmacistId) {
      this.setState((state, props) => ({
        PharmacistId: props.selectedMed.pharmacistId
      }));
    }
    if (prevProps.selectedMed.drugName !== this.props.selectedMed.drugName) {
      this.setState((state, props) => ({
        DrugName: props.selectedMed.drugName
      }));
    }
    if (
      prevProps.selectedMed.expirationDate !==
      this.props.selectedMed.expirationDate
    ) {
      this.setState((state, props) => ({
        ExpirationDate: props.selectedMed.expirationDate
      }));
    }
    if (
      prevProps.selectedMed.lotNumber !== this.props.selectedMed.lotNumber
    ) {
      this.setState((state, props) => ({
        LotNumber: props.selectedMed.lotNumber
      }));
    }
    if (prevProps.selectedMed.source !== this.props.selectedMed.source) {
      this.setState((state, props) => ({
        Source: props.selectedMed.source
      }));
    }
    if (prevProps.selectedMed.quantity !== this.props.selectedMed.quantity) {
      this.setState((state, props) => ({
        Quantity: props.selectedMed.quantity
      }));
    }
    if (
      prevProps.selectedMed.quantityType !== this.props.selectedMed.quantityType
    ) {
      this.setState((state, props) => ({
        QuantityType: props.selectedMed.quantityType
      }));
    }
    if (
      prevProps.selectedMed.ndcNumber !== this.props.selectedMed.ndcNumber
    ) {
      this.setState((state, props) => ({
        NdcNumber: props.selectedMed.ndcNumber
      }));
    }
    if (
      prevProps.selectedMed.strengthDosage !==
      this.props.selectedMed.strengthDosage
    ) {
      this.setState((state, props) => ({
        StrengthDosage: props.selectedMed.strengthDosage
      }));
    }
    if (
      prevProps.selectedMed.donationStatusDateUtc !==
      this.props.selectedMed.donationStatusDateUtc
    ) {
      this.setState((state, props) => ({
        DonationStatusDateUtc: props.selectedMed.donationStatusDateUtc
      }));
    }
  }

  componentWillDismount() {}

  acceptMedication = async e => {
    // check for required data
    if (!this.checkRequired()) return;
    // create client and add new med.
    try {
      let dntMedReq = this.state.donatedMedicationRequest;
      dntMedReq.donatedMedicationId = this.state.DonatedMedicationId;
      dntMedReq.pharmacyId = this.state.PharmacyId;
      dntMedReq.drugName = this.state.DrugName;
      dntMedReq.donatedMedicationStatus = DonatedMedicationStatus.Accepted;
      dntMedReq.donationStatusDateUtc = this.state.DonationStatusDateUtc;
      dntMedReq.source = this.state.Source;
      dntMedReq.quantity = this.state.Quantity;
      dntMedReq.quantityType = this.state.QuantityType;
      dntMedReq.strengthDosage = this.state.StrengthDosage;
      dntMedReq.expirationDate = this.state.ExpirationDate;
      dntMedReq.ndcNumber = this.state.NdcNumber;
      dntMedReq.lotNumber = this.state.LotNumber;

      let updMedReq = new UpdateMedicationInventory();
      updMedReq.medicationInventoryId = this.state.DonatedMedicationId;
      updMedReq.medicationInventoryStatus = MedicationInventoryStatus.Available;

      await this.state.apiClient.post(dntMedReq);
      await this.state.apiClient.put(updMedReq);

      this.setState({ isError: false }, this.props.doAccept(false));
    } catch (err) {
      this.setState({ isError: true }, this.props.doAccept(true));
      console.error("error accepting medication donation:" + JSON.stringify(err));
    }
  };

  rejectMedication = async e => {
    // check for required data
    if (!this.checkRequired()) return;
    // create client and add new med.
    try {
      let dntMedReq = this.state.donatedMedicationRequest;
      dntMedReq.donatedMedicationId = this.state.DonatedMedicationId;
      dntMedReq.pharmacyId = this.state.PharmacyId;
      dntMedReq.drugName = this.state.DrugName;
      dntMedReq.donatedMedicationStatus = DonatedMedicationStatus.Rejected;
      dntMedReq.donationStatusDateUtc = this.state.DonationStatusDateUtc;
      dntMedReq.source = this.state.Source;
      dntMedReq.quantity = this.state.Quantity;
      dntMedReq.quantityType = this.state.QuantityType;
      dntMedReq.strengthDosage = this.state.StrengthDosage;
      dntMedReq.expirationDate = this.state.ExpirationDate;
      dntMedReq.ndcNumber = this.state.NdcNumber;
      dntMedReq.lotNumber = this.state.LotNumber;

      await this.state.apiClient.post(dntMedReq);
      this.setState({ isError: false }, this.props.doReject(false));
    } catch (err) {
      this.setState({ isError: true }, this.props.doReject(true));
      console.error("error rejecting medication donation:" + JSON.stringify(err));
    }
  };

  handleChange = e => {
    var theId = e.target.id;
    var theVal = e.target.value;
    
    switch (theId) {
      case "pharmacyId": {
        this.setState((state, props) => ({
          PharmacyId: theVal
        }));
        break;
      }
      case "drugName": {
        this.setState((state, props) => ({
          DrugName: theVal
        }));
        break;
      }
      case "expirationDate": {
        this.setState((state, props) => ({
          ExpirationDate: theVal
        }));
        break;
      }
      case "strengthDosage": {
        this.setState((state, props) => ({
          StrengthDosage: theVal
        }));
        break;
      }
      case "lotNumber": {
        this.setState((state, props) => ({
          LotNumber: theVal
        }));
        break;
      }
      case "ndcNumber": {
        this.setState((state, props) => ({
          NdcNumber: theVal
        }));
        break;
      }
      case "quantity": {
        this.setState((state, props) => ({
          Quantity: theVal
        }));
        break;
      }
      case "quantityType": {
        this.setState((state, props) => ({
          QuantityType: theVal
        }));
        break;
      }
      case "source": {
        this.setState((state, props) => ({
          Source: theVal
        }));
        break;
      }
      case "donationStatusDateUtc": {
        this.setState((state, props) => ({
          DonationStatusDateUtc: theVal
        }));
        break;
      }
      default:
        break;
    }
  };

  checkRequired = () => {
    let rtnVal = true;

    if (this.state.DrugName.length === 0) {
      rtnVal = false;
      this.setState({ DrugNameValidation: "error" });
    } else {
      this.setState({ DrugNameValidation: "success" });
    }
    if (this.state.PharmacyId.length === 0) {
      rtnVal = false;
      this.setState({ PharmacyIdValidation: "error" });
    } else {
      this.setState({ PharmacyIdValidation: "success" });
    }
    if (this.state.ExpirationDate.length === 0) {
      rtnVal = false;
      this.setState({ ExpirationDateValidation: "error" });
    } else {
      this.setState({ ExpirationDateValidation: "success" });
    }
    if (this.state.StrengthDosage.length === 0) {
      rtnVal = false;
      this.setState({ StrengthDosageValidation: "error" });
    } else {
      this.setState({ StrengthDosageValidation: "success" });
    }
    if (this.state.LotNumber.length === 0) {
      rtnVal = false;
      this.setState({ LotNumberValidation: "error" });
    } else {
      this.setState({ LotNumberValidation: "success" });
    }
    if (this.state.NdcNumber.length === 0) {
      rtnVal = false;
      this.setState({ NdcNumberValidation: "error" });
    } else {
      this.setState({ NdcNumberValidation: "success" });
    }
    if (this.state.Quantity.length === 0) {
      rtnVal = false;
      this.setState({ QuantityValidation: "error" });
    } else {
      this.setState({ QuantityValidation: "success" });
    }
    if (this.state.QuantityType.length === 0) {
      rtnVal = false;
      this.setState({ QuantityTypeValidation: "error" });
    } else {
      this.setState({ QuantityTypeValidation: "success" });
    }
    if (this.state.Source.length === 0) {
      rtnVal = false;
      this.setState({ SourceValidation: "error" });
    } else {
      this.setState({ SourceValidation: "success" });
    }
    if (this.state.DonationStatusDateUtc.length === 0) {
      rtnVal = false;
      this.setState({ DonationStatusDateUtcValidation: "error" });
    } else {
      this.setState({ DonationStatusDateUtcValidation: "success" });
    }
    if (!rtnVal) {
      this.setState({ isError: true, errorCd: 'MIA-301', errorMsg: "Required Data Missing." });
    }

    return rtnVal;
  };

  handleExpDateChange = (dateVal, priorDateVal) => {
    this.setState({ ExpirationDate: dateVal });
  };

  handleStatusDateChange = (dateVal, priorDateVal) => {
    this.setState({ DonationStatusDateUtc: dateVal });
  };

  renderModal() {
    return (
      <Modal.Body>
        <Form>
          <Row>
            <Col md={3}>
              <ControlLabel>Donation Id: {this.state.DonationId}</ControlLabel>
            </Col>
            <Col md={3}>
              <ControlLabel>
                Donated Medication Id: {this.state.DonatedMedicationId}
              </ControlLabel>
            </Col>
            <Col md={3}>
              <FormGroup validationState={this.state.PharmacyIdValidation}>
                <ControlLabel>Pharmacy</ControlLabel>
                <FormControl
                  id="pharmacyId"
                  type="number"
                  placeholder="Pharmacy ID"
                  value={this.state.PharmacyId}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <ControlLabel>
                Pharmacist Id: {this.state.PharmacistId}
              </ControlLabel>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.state.DrugNameValidation}>
                <ControlLabel>Drug Name</ControlLabel>
                <FormControl
                  id="drugName"
                  type="text"
                  placeholder="Medication Name"
                  value={this.state.DrugName}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={this.state.DonationStatusDateUtcValidation}>
                <ControlLabel>Donation Date</ControlLabel>
                <DateTime
                  className="dtPicker"
                  id="donationStatusDateUtc"
                  value={this.state.DonationStatusDateUtc}
                  onChange={this.handleStatusDateChange}
                  pickerOptions={{ format: "L", allowInputToggle: false }}
                  placeholder="MM/DD/YYYY"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup controlId="source" validationState={this.state.SourceValidation}>
                <ControlLabel>Source</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="Source"
                  value={this.state.Source}
                  onChange={this.handleChange}
                >
                  { MedicationSource.map((item, i) => {return <option key={i} value={item.value}>{item.name}</option>}) }
                </FormControl>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup validationState={this.state.QuantityValidation}>
                <ControlLabel>Quantity</ControlLabel>
                <FormControl
                  id="quantity"
                  type="text"
                  placeholder="30"
                  value={this.state.Quantity}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup controlId="quantityType" validationState={this.state.QuantityTypeValidation}>
                <ControlLabel>Quantity Type</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="Quantity Type"
                  value={this.state.QuantityType}
                  onChange={this.handleChange}
                >
                  { MedicationType.map((item, i) => {return <option key={i} value={item.value}>{item.name}</option>}) }
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.state.StrengthDosageValidation}>
                <ControlLabel>Dosage</ControlLabel>
                <FormControl
                  id="strengthDosage"
                  type="text"
                  placeholder="100mg"
                  value={this.state.StrengthDosage}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={this.state.ExpirationDateValidation}>
                <ControlLabel>Expiration Date</ControlLabel>
                <DateTime
                  className="dtPicker"
                  id="expirationDate"
                  value={this.state.ExpirationDate}
                  onChange={this.handleExpDateChange}
                  pickerOptions={{ format: "L", allowInputToggle: false }}
                  placeholder="MM/DD/YYYY"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup validationState={this.state.NdcNumberValidation}>
                <ControlLabel>Ndc Number</ControlLabel>
                <FormControl
                  id="ndcNumber"
                  type="text"
                  placeholder="Ndc Number"
                  value={this.state.NdcNumber}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={this.state.LotNumberValidation}>
                <ControlLabel>Lot Number</ControlLabel>
                <FormControl
                  id="lotNumber"
                  type="text"
                  placeholder="Lot Number"
                  value={this.state.LotNumber}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    );
  }

  renderFooter() {
    return (
      <Modal.Footer>
        <ButtonToolbar>
          <Button
            onClick={e => {
              this.props.doReject(e);
              this.rejectMedication(e);
            }}
            className="btn-success"
          >
            Reject Donated Medication
          </Button>
          <Button
            onClick={e => {
              this.props.doAccept(e);
              this.acceptMedication(e);
            }}
            className="btn-success pull-right"
          >
            Accept Donated Medication
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    );
  }

  closeProcess = () => {
    this.setState({ isError: false }, this.props.onHide());
  };

  render() {
    const ModalBodyHTML = this.renderModal();
    const ModalFooterHTML = this.renderFooter();

    let alertBox;

    if (this.state.isError) {
      alertBox = (
        <Alert bsStyle="danger">
          <h4>Donation Processing Error</h4>
          <p>There was an error processing the donation.</p>
          <p>
          {this.state.errorCd}:{this.state.errorMsg}
        </p>
        </Alert>
      );
    } else {
      alertBox = "";
    }
    ReactGA.pageview('Donation - Process Medication');
    return (
      <Modal
        className="ProcessMedicationModal"
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
        {...this.props}
        onHide={this.closeProcess}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Process Medication
          </Modal.Title>
        </Modal.Header>
        {ModalBodyHTML}
        {ModalFooterHTML}
        {alertBox}
      </Modal>
    );
  }
}
