import React, { Component } from "react";
import { Alert, FormGroup, FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import "./Login.css";
import { UserContext } from "../../globals";
import AuthService from "../../libs/authService";
import ReactGA from 'react-ga';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
      email: "",
      password: ""
    };
    this.Auth = new AuthService();
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps) {
  }

  componentWillUnmount() {
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleDismiss = event => {
    event.preventDefault();
    this.setState({ isError: false });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isError: false });
    this.setState({ isLoading: true });

    try {
      this.Auth.login(this.state.email, this.state.password)
        .then(res => {
          this.props.userHasAuthenticated(true);
          if (this.props.userContext === UserContext.Member) {
            this.props.history.push("/donationform");
          }
          else if (this.props.userContext === UserContext.Pharmacy) {
            this.props.history.push("/pharmacyhome");
          }
          else {
            this.props.history.push("/");
          }
        })
        .catch(err => { // rejected login
          this.setState({ isLoading: false });
          this.setState({ isError: true });
          console.error("Login Rejected:", err);
        })
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
      this.setState({ isError: true });
    }
  }

  render() {
    let alertBox;

    if(this.state.isError) {
      alertBox = <Alert bsStyle="danger" onDismiss={ this.handleDismiss }>
        <h4>Login Error</h4>
        <p>
          There was an error performing the login.
        </p>
      </Alert>;
    } else {
      alertBox = '';
    }

    return (
      <div className="Login">
        <Grid>
          <Row className="show-grid rowOne">
            <Col md={4}>
            </Col>
            <Col md={4}>
              <div className="text-center">
                <h4>Returning Members</h4>
              </div>
              <br />
              <br />
              <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="email">
                  <ControlLabel>Username</ControlLabel>
                  <FormControl autoFocus type="email" value={this.state.email} onChange={this.handleChange} />
                </FormGroup>
                <FormGroup controlId="password">
                  <ControlLabel>Password</ControlLabel>
                  <FormControl value={this.state.password} onChange={this.handleChange} type="password" />
                </FormGroup>
                <FormGroup className="text-center">
                  <LoaderButton bsStyle="success" disabled={!this.validateForm()} type="submit" isLoading={this.state.isLoading} text="Sign In" loadingText=" Logging in…" />
                </FormGroup>
              </form>
              <div className="text-center">
                <a href="forgotPassword">Forgot Password?</a>
              </div>
              {alertBox}
            </Col>
            <Col md={4}>
            </Col>
            { /*
            <Col md={1}>
              <div className="wrapper">
                <div className="line"></div>
                <div className="wordwrapper">
                  <div className="word">Or</div>
                </div>
              </div>
            </Col>
            <Col md={3} className="text-center">
              <h4>Create an Account</h4>
              <br />
              <br />
              <p>
                If you plan on donating more than once, you may create a secure account to save your preferences
                and speed up the donation process.
              </p>
              <br />
              <br />
              <br />
              <Button href="signup" bsStyle="success">Create an Account</Button>
            </Col>
            */ }
          </Row>
        </Grid>
      </div>
    );
  }
}
