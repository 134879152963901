import React, { useState, forwardRef } from "react";
import { Grid, Row, Col, Form, FormGroup, Button } from "react-bootstrap";
import MedicationInventoryAdd from "../MedicationInventoryAdd/MedicationInventoryAdd";
import MedicationInventoryEdit from "../MedicationInventoryEdit/MedicationInventoryEdit";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import ReactGA from 'react-ga';

import "./MedicationManagement.css";

const { SearchBar } = Search;

const MedicationManagement = forwardRef((props, ref) => {
    const [showMedInvAdd, setShowMedInvAdd] = useState(false);
    const [showMedInvEdit, setShowMedInvEdit] = useState(false);
    const [selectedMedication, setSelectedMedication] = useState({});

    const openMedInvAdd = (e) => {
        e.preventDefault();
        setShowMedInvAdd(true);
    }

    const addMedInvAdd = (isError) => {
        setShowMedInvAdd(isError);
        if(!isError) {
            props.refreshMedications();
        }
    }

    const cancelMedInvAdd = (e) => {
        setShowMedInvAdd(false);
    }

    const openMedInvEdit = (e, medication) => {
        e.preventDefault();
        setSelectedMedication(selectedMedication => medication);
        setShowMedInvEdit(true);
    }

    const addMedInvEdit = isError => {
        setShowMedInvEdit(isError);
        if(!isError) {
            props.refreshMedications();
        }
    }

    const cancelMedInvEdit = e => {
        setShowMedInvEdit(false);
    }

    const tableEditMedicationButton = (cell, row, rowIndex, formatExtraData) =>
        <Button>Edit</Button>

    const clickedMedicationManagementRow = (row, isSelect, rowIndex, e) => {
    }

    const dateFormat = (cell, row) => {
        return(
            moment(cell).format("MM/DD/YYYY")
        )
    };

    const pagProps = paginationFactory({
        sizePerPage: 10
      });

    const sRowProps = {
        mode: 'radio',
        hideSelectColumn: true,
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => { clickedMedicationManagementRow(row, isSelect, rowIndex, e); },
        bgColor: 'lightgray'
    };

    const columns = [
        {
            dataField: 'medicationInventoryId',
            text: 'Med Id',
            sort: true
        }, {
            dataField: 'drugName',
            text: 'Drug Name',
            sort: true
        }, {
            dataField: 'quantityType',
            text: 'Drug Type',
            sort: true
        }, {
            dataField: 'strengthDosage',
            text: 'Dosage',
        }, {
            dataField: 'expirationDate',
            text: 'Expiration Date',
            formatter: dateFormat,
            sort: true
        }, {
            dataField: 'medicationInventoryStatus',
            text: 'Status',
            sort: true
        }, {
            dataField: 'EditMed',
            text: 'Action',
            isDummyField: true,
            csvExport: false,
            formatter: tableEditMedicationButton,
            sort: false,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => { openMedInvEdit(e, row); }
            }
        }
        /*, {
            dataField: 'RemoveMed',
            text: 'Remove Medication',
            isDummyField: true,
            csvExport: false,
            formatter: tableRemoveMedicationButton,
            sort: false,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => { showRemoveMedicationModal(e, row); }
            }
        }
        */
    ]
    ReactGA.pageview('Medication Management');
    return (
        <ToolkitProvider
            id="allMeds"
            ref={ref}
            keyField="medicationInventoryId"
            data={props.allMedications}
            columns={columns}
            search
        >
            {
                props => (
                    <div className="medicationManagement">
                        <Grid>
                            <Row className="show-grid rowOne">
                                <Col md={12}>
                                    <Form inline onSubmit={e => { e.preventDefault(); }}>
                                        <FormGroup>
                                            <SearchBar {...props.searchProps} />
                                        </FormGroup>
                                        <FormGroup className="pull-right">
                                            <Button ref={ref} className="btn-success" onClick={(e) => openMedInvAdd(e)}>+ Add Medication</Button>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row className="show-grid rowTwo">
                                <Col md={12}>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        bordered={false}
                                        selectRow={sRowProps}
                                        pagination={pagProps}
                                        striped
                                        hover
                                        condensed
                                    />
                                </Col>
                            </Row>
                        </Grid>
                        <MedicationInventoryAdd show={showMedInvAdd} doCancel={cancelMedInvAdd} onHide={cancelMedInvAdd} doAdd={addMedInvAdd} />
                        <MedicationInventoryEdit show={showMedInvEdit} doCancel={cancelMedInvEdit} onHide={cancelMedInvEdit} doEdit={addMedInvEdit} medication={selectedMedication} />
                    </div>
                )
            }
        </ToolkitProvider>
    );
});

export default MedicationManagement;