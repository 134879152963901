import React, { Component } from "react";
import { Grid, Row, Col , Button, Form, FormGroup, FormControl, ControlLabel, ButtonToolbar } from "react-bootstrap";
import { MedicationType } from "../../globals";
import ReactGA from 'react-ga';

import "./FindMedicationResults.css";

export default class FindMedicationResults extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="FindMedicationResults">
        <div className="findMedicationResults">
          <Grid>
            <Row className="show-grid rowOne">
              <Col md={1}></Col>
              <Col md={10}>
                <Form inline>
                  <FormGroup controlId="medName">
                    <ControlLabel>Medication Name</ControlLabel>{<br />}
                    <FormControl type="text" placeholder="Zolof" />
                  </FormGroup>
                  <span className="formSpace"> </span>
                  <FormGroup controlId="medType">
                    <ControlLabel>Medication Type</ControlLabel>{<br />}
                    <FormControl componentClass="select">
                    { MedicationType.map((item, i) => {return <option key={i} value={item.value}>{item.name}</option>}) }
                    </FormControl>
                  </FormGroup>
                  <span className="formSpace"> </span>
                  <FormGroup>
                    <ButtonToolbar>
                      <Button bsStyle="primary" type="submit">Search</Button>
                      <Button bsStyle="currentLocation">Search Near Me</Button>
                    </ButtonToolbar>
                  </FormGroup>
                  <br />
                  <br />
                  <FormGroup controlId="city">
                    <ControlLabel>City</ControlLabel>{<br />}
                    <FormControl type="text" placeholder="Liberty Lake" />
                  </FormGroup>
                  <span className="formSpace"> </span>
                  <FormGroup controlId="zipCode">
                    <ControlLabel>Zip Code</ControlLabel>{<br />}
                    <FormControl type="text" placeholder="99019" />
                  </FormGroup>
                </Form>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row className="rowTwo">
              <Col md={1}></Col>
              <Col md={10}>
                <h4>No Medications Found...</h4>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
