import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import StepZilla from "react-stepzilla";
import DonationFormDonorInfo from "../DonationFormDonorInfo/DonationFormDonorInfo";
import DonationFormMedicationInfo from "../DonationFormMedicationInfo/DonationFormMedicationInfo";
import DonationFormConfimSubmit from "../DonationFormConfirmSubmit/DonationFormConfirmSubmit";
import DonationFormThankYou from "../DonationFormThankYou/DonationFormThankYou";
import { invokeAPI } from "../../libs/apiLib";
import ReactGA from "react-ga";

import "./DonationForm.css";

export default class DonationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {showNav: false};

    this.currentStep = 0;
    this.donationFormDS = {
      email: "",
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      // Server Data
      medications: [],
      pharmacyId: "",
      pharmacyName: "",
      pharmacyAddress: "",
      pharmacyState: "",
      pharmacyCity: "",
      pharmacyZip: "",
      pharmacyPhone: "",
      selectedPharmName: "",
      memberId: "",
      donationType: "",
      certificationDate: "",
      signature: "",
      hasConsented: false,
    };
    if (this.props.isAuthenticated) {
      this.donationFormDS.email = this.props.userProfile.email;
    }
    this._validateOnDemand = true; // flag enables onBlur validation as user fills forms
    this.validationCheck = this.validationCheck.bind(this);
    this.isValidated = this.isValidated.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.handleDonorFormStepChange(0);
  }

  componentWillUnmount() {}

  submitAll = async (event) => {
    // Send it all to the server.
    try {
      await this.postDonation();
    } catch (e) {
      console.error(e);
    }
  };

  postDonation() {
    this.donationFormDS.medications.map((med, i) => {
      var DName = med.DrugName;

      // Fix the Drug Name if an Array
      if (Array.isArray(DName)) {
        let DNameItem = med.DrugName[0];
        med.DrugName = DNameItem;
      }
    });

    var newDonation = this.donationFormDS;

    return invokeAPI({
      path: `/donations/`,
      method: "POST",
      body: JSON.stringify(newDonation),
    });
  }

  jsonToFormData(data) {
    const formData = new FormData();

    this.buildFormData(formData, data);

    return formData;
  }

  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  }

  getDS() {
    return this.donationFormDS;
  }

  setNav(val) {
    this.setState({ showNav: val });
  }

  doCaptcha() {
    if (window.sessionStorage.getItem("Captcha") !== "null") {
      this.setState({ showNav: true });
    } else {
      this.setState({ showNav: false });
    }
    window.sessionStorage.removeItem("Captcha");
  }

  updateDS(update) {
    this.donationFormDS = {
      ...this.donationFormDS,
      ...update,
    };
  }

  handleDonorFormStepChange(step) {
    window.sessionStorage.setItem("step", step);
    this.currentStep = step;
  }

  isValidated() {
    const userInput = this._grabUserInput(); //Grabs user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator
    let isDataValid = false;

    //if full validation passes then save to store and pass as valid
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === true;
      })
    ) {
      if (
        this.props.getDS().email !== userInput.email ||
        this.props.getDS().name !== userInput.name ||
        this.props.getDS().address !== userInput.address ||
        this.props.getDS().city !== userInput.city ||
        this.props.getDS().state !== userInput.state ||
        this.props.getDS().zip !== userInput.zip ||
        this.props.getDS().phone !== userInput.phone
      ) {
        //only update store of something changed
        this.props.updateDS({
          ...userInput,
        });
      }

      isDataValid = true;
    } else {
      //if anything fails to update the UI validation state but NOT the UI data state
      this.setState(
        Object.assign(
          userInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    }
    return isDataValid;
  }

  validationCheck() {
    if (!this._validateOnDemand) return;

    const userInput = this._grabUserInput(); //grab user entered vals
    const validateNewInput = this._validateData(userInput); //Run the new input against the validator

    this.setState(
      Object.assign(
        userInput,
        validateNewInput,
        this._validationErrors(validateNewInput)
      )
    );
  }

  _validateData(data) {
    return {
      emailVal: data.email !== 0, //required: anything beisdes N/A
      nameVal: data.name !== 0,
      addressVal: data.address !== 0,
      cityVal: data.city !== 0,
      stateVal: data.state !== 0,
      zipVal: data.zip !== 0,
      phoneVal: data.phone !== 0,
      pharmacyId: data.pharmacyId !== 0,
    };
  }

  _validationErrors(val) {
    const errMsgs = {
      emailValMsg: val.emailVal ? "" : "Please enter your email.",
      nameValMsg: val.nameVal ? "" : "Please enter your name.",
      addressValMsg: val.addressVal ? "" : "Please enter your address.",
      cityValMsg: val.cityVal ? "" : "Input your city of residence.",
      stateValMsg: val.stateVal ? "" : "Input your state of residence.",
      zipValMsg: val.zipVal ? "" : "Input your zipcode.",
      phoneValMsg: val.phoneVal ? "" : "Please enter your phone number.",
      pharmacyValMsg: val.pharmacyId ? "" : "Please pick a pharmacy location.",
    };
    return errMsgs;
  }

  _grabUserInput() {
    return {
      email: this.refs.email.value,
      name: this.refs.name.value,
      address: this.refs.address.value,
      city: this.refs.city.value,
      state: this.refs.state.value,
      zip: this.refs.zip.value,
      phone: this.refs.phone.value,
      pharmacyId: this.refs.pharmacyId.value,
    };
  }

  render() {
    const steps = [
      {
        name: "Donor Information",
        component: (
          <DonationFormDonorInfo
            getDS={() => this.getDS()}
            updateDS={(d) => {
              this.updateDS(d);
            }}
            doCaptcha={() => this.doCaptcha()}
            showNav={this.state.showNav}
          />
        ),
      },
      {
        name: "Medication Information",
        component: (
          <DonationFormMedicationInfo
            getDS={() => this.getDS()}
            updateDS={(d) => {
              this.updateDS(d);
            }}
            setNav={(val) => this.setNav(val)}
          />
        ),
      },
      {
        name: "Confirm & Submit Donation",
        component: (
          <DonationFormConfimSubmit
            getDS={() => this.getDS()}
            updateDS={(d) => {
              this.updateDS(d);
            }}
          />
        ),
      },
      {
        name: "Thank You!",
        component: (
          <DonationFormThankYou
            getDS={() => this.getDS()}
            updateDS={(d) => {
              this.updateDS(d);
            }}
            submitAll={this.submitAll}
          />
        ),
      },
    ];

    return (
      <div className="DonationForm">
        <Grid fluid={true}>
          <Row className="show-grid row--one">
            <Col md={2}></Col>
            <Col md={8}>
              <h1 className="page-title">Medication Donation Form</h1>
              <br />
            </Col>
            <Col md={2}></Col>
          </Row>
          <Row className="show-grid row--two">
            <Col md={2}></Col>
            <Col md={8}>
              <div className="step-progress">
                <StepZilla
                  steps={steps}
                  showNavigation={this.state.showNav}
                  showSteps={true}
                  stepsNavigation={false}
                  prevBtnOnLastStep={false}
                  dontValidate={false}
                  preventEnterSubmission={true}
                  // startAtStep={window.sessionStorage.getItem('step') ? parseFloat(window.sessionStorage.getItem('step')) : 0}
                  startAtStep={0}
                  nextButtonText={"Next"}
                  // Is this the point in time in which we add the call to validate?
                  backButtonText={"Back"}
                  nextButtonCls={"btn btn-next btn-success pull-right"}
                  backButtonCls={"btn btn-prev btn-success pull-left"}
                  nextTextOnFinalActionStep={"Submit"}
                  onStepChange={(step) => this.handleDonorFormStepChange(step)}
                />
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

//TODO: Insert for if not all fields are chosen? "Please make sure you have accurately filled all fields."
