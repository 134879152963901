import React from "react";
import { Link } from "react-router-dom";

// <Route
//   path={props.href}
//   exact
//   children={({ match, history }) =>
//     <NavItem
//       onClick={e => history.push(e.currentTarget.getAttribute("href"))}
//       {...props}
//       active={match ? true : false}
//     >
//       {props.children}
//     </NavItem>}
// />;
// <li>
//   <Link
//     to={props.href}
//     exact="true"
//     >
//         {props.children}
//     </Link>
// </li>

const RouteNavItem = (props) => (
  <li role="presentation"className="">
  <Link
    to={props.href}
    exact="true"
    >
        {props.children}
    </Link>
</li>
)
export default RouteNavItem