import React, { Component } from "react";
import { Grid, Row, Col, Button, Image, ButtonToolbar, ToggleButtonGroup, ToggleButton, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserContext } from "../../globals";
import ReactGA from 'react-ga';

import "./Donate.css";

export default class Donate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      donationType: 1,
      donationAmount: ''
    };

  }

  validateForm() {
    return this.state.donationAmount !== '';
  }

  // Toggle Group is not triggering onChange  - Work Around. 
  goForClick1 = (selected) => {
    this.setState({ donationType: 1 });
  }
  goForClick2 = (selected) => {
    this.setState({ donationType: 2 });
  }

  renderDontationForm() {
    if (this.state.donationType === 1) {
      return <form className="donation-form" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
        <div className="form-group">
          <FormControl type="number" placeholder="Donation Amount" value={this.state.donationAmount} onChange={(event) => this.setState({ donationAmount: event.target.value })} />
          <input type="hidden" name="business" value="MFKZ7R2MYL54E" />
          <input type="hidden" name="cmd" value="_donations" />
          <input type="hidden" name="amount" value={this.state.donationAmount} />
          <input type="hidden" name="currency_code" value="USD" />
          <input type="hidden" name="item_name" value="Charitable Pharmacy Donation" />
          <input type="hidden" name="item_number" value="Financial Donation" />
          <Button type="submit" bsStyle="success" bsSize="large" disabled={!this.validateForm()}>Financial Donation</Button>
        </div>
      </form>;

    }
    else {
      return <form className="donation-form" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
        <div className="form-group">
          <FormControl type="number" placeholder="Donation Amount" value={this.state.donationAmount} onChange={(event) => this.setState({ donationAmount: event.target.value })} />
          <input type="hidden" name="business" value="MFKZ7R2MYL54E" />
          <input type="hidden" name="cmd" value="_xclick-subscriptions" />
          <input type="hidden" name="item_name" value="Charitable Pharmacy Monthly Donation" />
          <input type="hidden" name="item_number" value="Recurring Donation" />
          <input type="hidden" name="currency_code" value="USD" />
          <input type="hidden" name="a3" value={this.state.donationAmount} />
          <input type="hidden" name="p3" value="1" />
          <input type="hidden" name="t3" value="M" />
          <input type="hidden" name="src" value="1" />
          <Button type="submit" bsStyle="success" bsSize="large" disabled={!this.validateForm()}>Monthly Donation</Button>
        </div>
      </form>;
    }// END IF
  }


  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const formHtml = this.renderDontationForm();
    const donateLink = (this.props.userContext === UserContext.Member) ? 'DonationForm' : 'donation';
    return (
      <div className="Donate">
        <div className="donate">
          <Grid fluid={true}>
            <Row className="show-grid row--one">
              <Grid>
                <Row className="show-grid row--one--one">
                  <Col md={2}></Col>
                  <Col md={4}>
                    <h2>Donation is Easy and Worth so Much.</h2>
                    <p>
                      Every day there are people choosing between feeding their families and purchasing their life-<wbr />sustaining medications.
                      Your donation will allow these people to do both. Your donation could be saving a life.
                    </p>
                    <Link to={donateLink}><Button bsStyle="success" bsSize="large">Donate Now</Button></Link>
                  </Col>
                  <Col md={6}></Col>
                </Row>
                <Row className="show-grid row--one--two">

                  <Col md={4} sm={12} className="donation-col">
                    <div>
                      <div className="d-inline-block">
                        <span className="circle-image-wrapper">
                          <Image src="/assets/medication-ico.svg" height="50" width="50" />
                        </span>
                      </div>
                      <p>Learn about what medications can be donated to our program.</p>
                    </div>
                    <Button href="#whatMeds" bsStyle="success" bsSize="lg" >Eligible Medication</Button>
                  </Col>
                  <Col md={4} sm={12} className="donation-col">
                    <div>
                      <div className="d-inline-block">
                        <span className="circle-image-wrapper">
                          <Image src="/assets/document-ico.svg" height="50" width="50" />
                        </span>
                      </div>
                      <p>Learn how to donate your unused medications and complete available donation forms.</p>
                    </div>
                    <Link to='donatehow'><Button bsStyle="success" bsSize="large">How To Donate</Button></Link>
                    {/* <Button href="donatehow" bsStyle="success" bsSize="lg" >How To Donate</Button> */}
                  </Col>
                  <Col md={4} sm={12} className="donation-col">
                    <div>
                      <div className="d-inline-block">
                        <span className="circle-image-wrapper">
                          <Image src="/assets/building-ico.svg" height="50" width="50" />
                        </span>
                      </div>
                      <p>Find a participating pharmacy that will accept your medication donation.</p>
                    </div>
                    <Link to='findPharmacy'><Button bsStyle="success" bsSize="large">Where To Donate</Button></Link>
                  </Col>
                </Row>
              </Grid>
            </Row>
            <Row className="show-grid row--two">
              <Grid>
                <Row className="show-grid row--two--one">
                  <Col md={1}>
                  </Col>
                  <Col md={4}>
                    <ButtonToolbar>
                      <ToggleButtonGroup justified type="radio" name="options"
                        defaultValue={this.state.donationType}
                      >
                        <ToggleButton value={1} onClick={this.goForClick1} >Single Donation</ToggleButton>
                        <ToggleButton value={2} onClick={this.goForClick2}>Monthly</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                    {formHtml}
                    {/* <Form horizontal className="donation-form">
                      <FormGroup controlId="formDonation">
                        <Col >
                          <FormControl type="donation" placeholder="Donation" />
                        </Col>
                        <Col >
                          <Link to='DonateMoney'><Button bsStyle="success" bsSize="large">Financial Donation</Button></Link> */}
                    {/* <Button bsStyle="success" bsSize="lg" type="submit">Financial Donation</Button> */}
                    {/* </Col>
                      </FormGroup>
                    </Form> */}
                    {/* <FormControl type="donation" placeholder="Donation" /> */}

                  </Col>
                  <Col md={6}>
                  </Col>
                </Row>
                <Row className="show-grid row--two--two">
                  <Col md={1}>
                  </Col>
                  <Col md={4}>
                    <h2 className="page-title">Help grow the charitable pharmacy by making a monetary donation.</h2>
                    <p>
                      Your financial support makes this program possible. With your donations we are able to provide the administrative support
                      that allows this program to operate and serve those in need.  Please consider making a financial donation to the Charitable Pharmacy.
                  </p>
                  </Col>
                  <Col md={7}></Col>
                </Row>
              </Grid>
            </Row>
            <Row className="show-grid row--three">
              <Grid>
                <Row className="show-grid row--three--one" id="whatMeds">
                  <Col md={2}></Col>
                  <Col md={8}>
                    <h3 className="page-title">Medication Eligible for Donation.</h3>
                    <p>
                      Please read through the state requirements to find out what medications are eligible to donate.
                      Many medications are able to be donated - however, there are specific requirements that must be met in order to donate.
                  </p>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <Row className="show-grid row--three--two">
                  <Col md={2}></Col>
                  <Col md={4}>
                    <ol>
                      <li>Narcotic medications are NOT accepted by this program. (e.g. Fetynol, Codine, Hydrocodone, Morphine, Methadone, Vicodin, etc.)</li>
                      <li>Medication must be UNOPENED. (We are not able to accept individual pills in unsealed amber medication bottles.)</li>
                      <li>Medications must be more than 3 months from the expiration date.</li>
                      <li>Medications must be properly stored.</li>
                      <li>Medication has not been recalled.</li>
                    </ol>
                  </Col>
                  <Col md={4}>
                    The following ARE acceptable:
                  <ul>
                      <li>Individual blister packs.</li>
                      <li>Amber containers with unbroken seals.</li>
                      <li>Unopened individually packaged medications (such as injectables, inhalers, drops, creams, etc.)</li>
                      <li>Medical supplies in sealed containers.</li>
                    </ul>

                  </Col>
                  <Col md={2}></Col>
                </Row>
              </Grid>

            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
