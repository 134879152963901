import React, { Component, Fragment } from "react";
import {
  Alert,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  ButtonToolbar,
  Button,
} from "react-bootstrap";
import PhoneInput from "react-phone-number-input";

import { baseUrl } from "../../globals";
import { JsonServiceClient } from "@servicestack/client";
import { CreateMedicationRequest } from "../../shared/dtos";
import ReactGA from "react-ga";

import "./MedicationRequestModal.css";

export default class MedicationRequestModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      apiClient: null,
      isLoading: false,
      isError: false,
      errorCd: "",
      errorMsg: "",
      page: 0,
      prescriptionLocation: "1",
      fillOption: "1",
      name: "",
      nameValidation: null,
      email: "",
      emailValidation: null,
      phone: "",
      phoneValidation: null,
      mailToName: "",
      mailToNameValidation: null,
      mailToAddress1: "",
      mailToAddress1Validation: null,
      mailToAddress2: "",
      mailToCity: "",
      mailToCityValidation: null,
      mailToState: "",
      mailToStateValidation: null,
      mailToZip: "",
      mailToZipValidation: null,
    };
    this.onContinue = this.onContinue.bind(this);
    this.onBack = this.onBack.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
  }

  componentDidMount() {
    const apiUrl = baseUrl;
    const jwt = localStorage.getItem("id_token");

    var ssClient = new JsonServiceClient(apiUrl);
    ssClient.bearerToken = jwt;
    this.setState({ apiClient: ssClient });
  }

  componentDidUpdate(prevProps) {
    if (this.props.show === false && prevProps.show === true) {
      this.setState({ page: 0, fillOption: "1" });
    }
  }

  componentWillUnmount() {}

  onContinue() {
    let passed = true;

    if (this.state.page > 0) {
      if (this.state.fillOption === "1") {
        // check name, email, phone
        if (this.state.name.length === 0) {
          passed = false;
          this.setState({ nameValidation: "error" });
        } else {
          this.setState({ nameValidation: "success" });
        }
        if (this.state.email.length === 0) {
          passed = false;
          this.setState({ emailValidation: "error" });
        } else {
          this.setState({ emailValidation: "success" });
        }
        if (this.state.phone.length === 0) {
          passed = false;
          this.setState({ stateValidation: "error" });
        } else {
          this.setState({ stateValidation: "success" });
        }
      } else if (this.state.fillOption === "2") {
        // check name, email, phone, mailName, mailAddr1, mailCity, mailState, mailZip
        if (this.state.name.length === 0) {
          passed = false;
          this.setState({ nameValidation: "error" });
        } else {
          this.setState({ nameValidation: "success" });
        }
        if (this.state.email.length === 0) {
          passed = false;
          this.setState({ emailValidation: "error" });
        } else {
          this.setState({ emailValidation: "success" });
        }
        if (this.state.phone.length === 0) {
          passed = false;
          this.setState({ phoneValidation: "error" });
        } else {
          this.setState({ phoneValidation: "success" });
        }
        if (this.state.mailToName.length === 0) {
          passed = false;
          this.setState({ mailToNameValidation: "error" });
        } else {
          this.setState({ mailToNameValidation: "success" });
        }
        if (this.state.mailToAddress1.length === 0) {
          passed = false;
          this.setState({ mailToAddress1Validation: "error" });
        } else {
          this.setState({ mailToAddress1Validation: "success" });
        }
        if (this.state.mailToCity.length === 0) {
          passed = false;
          this.setState({ mailToCityValidation: "error" });
        } else {
          this.setState({ mailToCityValidation: "success" });
        }
        if (this.state.mailToState.length === 0) {
          passed = false;
          this.setState({ mailToStateValidation: "error" });
        } else {
          this.setState({ mailToStateValidation: "success" });
        }
        if (this.state.mailToZip.length === 0) {
          passed = false;
          this.setState({ mailToZipValidation: "error" });
        } else {
          this.setState({ mailToZipValidation: "success" });
        }
      }
    }
    if (!passed) {
      this.setState({
        isError: true,
        errorCd: "MIA-501",
        errorMsg: "Required Data Missing.",
      });
    } else {
      this.setState({ isError: false, page: this.state.page + 1 });
    }
  }

  onBack() {
    // Modify the state to show the next state.
    this.setState({ page: this.state.page - 1 });
  }

  async sendRequest() {
    if (this.state.fillOption === "1") {
      this.setState({ page: this.state.page + 2 });
    } else {
      this.setState({ page: this.state.page + 1 });
      document.getElementById("donatePostage").submit();
    }
    try {
      let request = new CreateMedicationRequest();
      request.name = this.state.name;
      request.email = this.state.email;
      request.phone = this.state.phone;
      request.medicationInventoryId = this.props.medication.medicationInventoryId;
      request.prescriptionFillTypeId = this.state.fillOption;
      request.prescriptionLocationTypeId = this.state.prescriptionLocation;
      request.dateRequestedUtc = new Date().toISOString();
      request.mailToName = this.state.mailToName;
      request.mailToAddress1 = this.state.mailToAddress1;
      request.mailToAddress2 = this.state.mailToAddress2;
      request.mailToCity = this.state.mailToCity;
      request.mailToState = this.state.mailToState;
      request.mailToZip = this.state.mailToZip;

      await this.state.apiClient.post(request);
    } catch (err) {
      console.error("error creating medicaion request:" + JSON.stringify(err));
    }
  }

  handleOptionChange = (event) => {
    this.setState({
      prescriptionLocation: event.target.value,
    });
  };

  handleSelectChange = (event) => {
    this.setState({
      fillOption: event.target.value,
    });
  };

  renderModal() {
    switch (this.state.page) {
      case 0:
        return (
          <Modal.Body>
            <ul>
              <li>
                <strong>Medication</strong>
              </li>
              <li>{this.props.medication.drugName}</li>
            </ul>
            <ul>
              <li>
                <strong>Pharmacy</strong>
              </li>
              <li>{this.props.medication.pharmacyName}</li>
              <li>{this.props.medication.pharmacyZip}</li>
            </ul>
            <Form>
              <FormGroup controlId="formControlsSelect">
                <ControlLabel>Prescription Fill Options</ControlLabel>
                <FormControl
                  componentClass="select"
                  value={this.state.fillOption}
                  onChange={this.handleSelectChange}
                  placeholder="Prescription Fill Options"
                >
                  <option value="1">Pick Up</option>
                  <option value="2">Mail</option>
                </FormControl>
              </FormGroup>
              <FormGroup controlId="formPrescriptionLocation">
                <div className="radio--group">
                  <div className="radio--group--button">
                    <label>
                      <input
                        type="radio"
                        value="1"
                        checked={this.state.prescriptionLocation === "1"}
                        onChange={this.handleOptionChange}
                      />
                      My prescription has been sent.
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="2"
                        checked={this.state.prescriptionLocation === "2"}
                        onChange={this.handleOptionChange}
                      />
                      My doctor will be sending my prescription.
                    </label>
                  </div>
                </div>
              </FormGroup>
              <p className="small">
                Reminder: You must have a doctor's prescription in order to
                receive the medication requested. Tell your doctor to Note
                "Charitable Pharmacy" in the signature.
                <br />
                ALSO: If you choose to have your prescription sent to you by
                MAIL, <strong>you</strong> are responsible for the shipping
                costs, and will be connected shortly to our PayPal to complete
                the transaction and request.
              </p>
            </Form>
          </Modal.Body>
        );

      case 1:
        return (
          <Modal.Body>
            <Form>
              <FormGroup
                controlId="name"
                validationState={this.state.nameValidation}
              >
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  autoFocus
                  type="email"
                  value={this.state.name}
                  onChange={(event) =>
                    this.setState({ name: event.target.value })
                  }
                />
              </FormGroup>
              <FormGroup
                controlId="email"
                validationState={this.state.emailValidation}
              >
                <ControlLabel>Email</ControlLabel>
                <FormControl
                  type="email"
                  value={this.state.email}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                />
              </FormGroup>
              <FormGroup
                controlId="phone"
                validationState={this.state.phoneValidation}
              >
                <ControlLabel>Phone</ControlLabel>
                <PhoneInput
                  defaultCountry="US"
                  value={this.state.phone}
                  onChange={(value) => this.setState({ phone: value })}
                  placeholder="Your Phone"
                />
              </FormGroup>
              {this.state.fillOption === "2" ? (
                <Fragment>
                  <FormGroup validationState={this.state.mailToNameValidation}>
                    <u>Mailing Address</u>
                    <br />
                    <ControlLabel>Name:</ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.mailToName}
                      onChange={(event) =>
                        this.setState({ mailToName: event.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup validationState={this.state.mailToAddress1Validation}>
                    <ControlLabel>Address 1:</ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.mailToAddress1}
                      onChange={(event) =>
                        this.setState({ mailToAddress1: event.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Address 2:</ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.mailToAddress2}
                      onChange={(event) =>
                        this.setState({ mailToAddress2: event.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup validationState={this.state.mailToCityValidation}>
                    <ControlLabel>City:</ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.mailToCity}
                      onChange={(event) =>
                        this.setState({ mailToCity: event.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup validationState={this.state.mailToStateValidation}>
                    <ControlLabel>State:</ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.mailToState}
                      onChange={(event) =>
                        this.setState({ mailToState: event.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup validationState={this.state.mailToZipValidation}>
                    <ControlLabel>Zip:</ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.mailToZip}
                      onChange={(event) =>
                        this.setState({ mailToZip: event.target.value })
                      }
                    />
                  </FormGroup>
                </Fragment>
              ) : (
                ""
              )}
            </Form>
          </Modal.Body>
        );

      case 2:
        const MailAddress = (
          <li>
            <br />
            <table>
              <th>Mailing Address</th>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{this.state.mailToName}</td>
                </tr>
                <tr>
                  <td>Address1:</td>
                  <td>{this.state.mailToAddress1}</td>
                </tr>
                <tr>
                  <td>Address2:</td>
                  <td>{this.state.mailToAddress2}</td>
                </tr>
                <tr>
                  <td>City:</td>
                  <td>{this.state.mailToCity}</td>
                </tr>
                <tr>
                  <td>State:</td>
                  <td>{this.state.mailToState}</td>
                </tr>
                <tr>
                  <td>Zip:</td>
                  <td>{this.state.mailToZip}</td>
                </tr>
              </tbody>
            </table>
          </li>
        );

        return (
          <Modal.Body>
            <h4>
              <strong>Is this information correct?</strong>
            </h4>
            <ul>
              <li>
                <strong>Medication</strong>
              </li>
              <li>{this.props.medication.drugName}</li>
            </ul>
            <ul>
              <li>
                <strong>Pharmacy</strong>
              </li>
              <li>{this.props.medication.pharmacyName}</li>
              <li>{this.props.medication.pharmacyZip}</li>
            </ul>
            <ul>
              <li>
                <strong>Prescription Fill Options</strong>
              </li>
              <li>{this.state.fillOption === "1" ? "Pick Up" : "Mail"}</li>
            </ul>
            <ul>
              <li>
                <strong>Prescription Location</strong>
              </li>
              <li>
                {this.state.prescriptionLocation === "1"
                  ? "My prescription has been sent"
                  : "My Dr. will be sending my prescription"}
              </li>
            </ul>
            <ul>
              <li>
                <strong>Your Information</strong>
              </li>
              <li>
                Name:
                {this.state.name}
              </li>
              <li>
                Email:
                {this.state.email}
              </li>
              <li>
                Phone Number:
                {this.state.phone}
              </li>
              {this.state.fillOption === "2" ? MailAddress : ""}
            </ul>
            <p className="small">
              Reminder: You must have a doctor's prescription in order to
              recieve the medication requested. Tell your doctor to Note
              "Charitable Pharmacy" in the signature.
              <br />
              ALSO: If you choose to have your prescription sent to you by MAIL,{" "}
              <strong>you</strong> are responsible for the shipping costs, and
              will be connected shortly to our PayPal to complete the
              transaction and request.
            </p>
          </Modal.Body>
        );

      case 3:
        return (
          <Modal.Body>
            <ul>
              <li>
                You will be redirected shortly to cover $5.00 shipping costs via
                donation with Charitable Pharmacy's PayPal.
              </li>
              <form
                id="donatePostage"
                method="POST"
                action="https://www.paypal.com/cgi-bin/webscr"
                target="_blank"
              >
                <input type="hidden" name="cmd" value="_donations" />
                <input type="hidden" name="business" value="MFKZ7R2MYL54E" />
                <input type="hidden" name="amount" value={5.0} />
                <input type="hidden" name="currency_code" value="USD" />
                <input
                  type="hidden"
                  name="item_name"
                  value="Charitable Pharmacy Donation"
                />
                <input
                  type="hidden"
                  name="item_number"
                  value="Financial Donation"
                />
              </form>
            </ul>
          </Modal.Body>
        );

      case 4:
        return (
          <Modal.Body>
            <ul>
              <li>Thank you!</li>
              <li>Your prescription will be ready shortly.</li>
            </ul>
          </Modal.Body>
        );

      default:
        break;
    }
  }
  renderFooter() {
    let theButtons;

    switch (this.state.page) {
      case 0: {
        theButtons = (
          <ButtonToolbar>
            <Button onClick={this.props.onHide}>Cancel</Button>
            <Button onClick={this.onContinue} className="btn-success">
              Continue
            </Button>
          </ButtonToolbar>
        );
        break;
      }
      case 1: {
        theButtons = (
          <ButtonToolbar>
            <Button onClick={this.props.onHide}>Cancel</Button>
            <Button onClick={this.onBack}>Back</Button>
            <Button onClick={this.onContinue} className="btn-success">
              Continue
            </Button>
          </ButtonToolbar>
        );
        break;
      }
      case 2: {
        if (this.state.fillOption === "1") {
          theButtons = (
            <ButtonToolbar>
              <Button onClick={this.props.onHide}>Cancel</Button>
              <Button onClick={this.onBack}>Back</Button>
              <Button onClick={this.sendRequest} className="btn-success">
                Request
              </Button>
            </ButtonToolbar>
          );
        } else {
          theButtons = (
            <ButtonToolbar>
              <Button onClick={this.props.onHide}>Cancel</Button>
              <Button onClick={this.onBack}>Back</Button>
              <Button onClick={this.onContinue} className="btn-success">
                Continue
              </Button>
            </ButtonToolbar>
          );
        }
        break;
      }
      case 3: {
        theButtons = (
          <ButtonToolbar>
            <Button onClick={this.props.onHide}>Cancel</Button>
            <Button onClick={this.onBack}>Back</Button>
            <Button onClick={this.sendRequest} className="btn-success">
              Request
            </Button>
          </ButtonToolbar>
        );
        break;
      }
      case 4: {
        theButtons = (
          <ButtonToolbar>
            <Button onClick={this.props.onHide}>Done</Button>
          </ButtonToolbar>
        );
        break;
      }
      default:
        break;
    }
    return <Modal.Footer>{theButtons}</Modal.Footer>;
  }

  render() {
    ReactGA.pageview("Medication Request Modal");
    const modalBodyHML = this.renderModal();
    const modalFooterHTML = this.renderFooter();

    let alertBox;

    if (this.state.isError) {
      alertBox = (
        <Alert bsStyle="danger">
          <h4>Medication Request Error</h4>
          <p>There was an error in making your request.</p>
          <p>
            {this.state.errorCd}:{this.state.errorMsg}
          </p>
        </Alert>
      );
    } else {
      alertBox = "";
    }

    return (
      <Modal
        className={`MedicationRequestModal`}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
        {...this.props}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Medication Request
          </Modal.Title>
        </Modal.Header>
        {modalBodyHML}
        {modalFooterHTML}
        {alertBox}
      </Modal>
    );
  }
}
