import React, { Component } from "react";
import { Alert, Label, Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from "react-bootstrap";
import FindAPharmacyModal from "../../components/FindAPharmacyModal/FindAPharmacyModal";
import { USStates } from "../../globals";
import { invokeAPI } from "../../libs/apiLib";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";
import ReactGA from 'react-ga';

import "../DonationForm/DonationForm.css";
import "../DonationFormDonorInfo/DonationFormDonorInfo.css";

export default class DonationFormDonorInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      errorCd: '',
      errorMsg: '',
      showFindAPharmacyModal: false,
      pharmaciesList: [],
      selectedPharmId: props.getDS().pharmacyId,
      pharmacyAddress: props.getDS().pharmacyAddress,
      pharmacyState: props.getDS().pharmacyState,
      pharmacyCity: props.getDS().pharmacyCity,
      pharmacyZip: props.getDS().pharmacyZip,
      pharmacyPhone: props.getDS().pharmacyPhone,
      selectedPharmName: props.getDS().pharmacyName,
      pharmDisplayHTML: "",
      email: props.getDS().email,
      emailValidation: null,
      donationType: props.getDS().donationType,
      name: props.getDS().name,
      nameValidation: null,
      address: props.getDS().address,
      addressValidation: null,
      city: props.getDS().city,
      cityValidation: null,
      state: props.getDS().state,
      stateValidation: null,
      zip: props.getDS().zip,
      zipValidation: null,
      phone: props.getDS().phone,
      phoneValidation: null
    };

    this.closeFindAPharmacyModal = this.closeFindAPharmacyModal.bind(this);
    this.isValidated = this.isValidated.bind(this);
  }

  handleOptionChange = event => {
    this.setState({
      donationType: event.target.value
    });
  }


  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    try {
      const results = await this.getPharmList();
      this.setState({
        pharmaciesList: results.results,
      });
    } catch (e) {
      console.error(e);
    }
  }

  componentWillUnmount() {
    this.props.updateDS({
      name: this.state.name,
      email: this.state.email,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      phone: this.state.phone,
      donationType: this.state.donationType,
      pharmacyName: this.state.selectedPharmName,
      pharmacyId: this.state.selectedPharmId,
      pharmacyAddress: this.state.pharmacyAddress,
      pharmacyState: this.state.pharmacyState,
      pharmacyCity: this.state.pharmacyCity,
      pharmacyZip: this.state.pharmacyZip,
      pharmacyPhone: this.state.pharmacyPhone
    });
    this._mounted = false
  }

  isValidated() {
    let isDataValid = true;

    if (this.state.donationType.length === 0) {
      isDataValid = false;
      this.setState({ donationTypeValidation: "error" });
    } else {
      this.setState({ donationTypeValidation: "success" });
    }
    if (this.state.name.length === 0) {
      isDataValid = false;
      this.setState({ nameValidation: "error" });
    } else {
      this.setState({ nameValidation: "success" });
    }
    // Removed for Amanda - Card 123 PILOT: Make email address "Not Required" on Donation Form
    /*
    if (this.state.email.length === 0) {
      isDataValid = false;
      this.setState({ emailValidation: "error" });
    } else {
      this.setState({ emailValidation: "success" });
    }
    */
    if (this.state.address.length === 0) {
      isDataValid = false;
      this.setState({ addressValidation: "error" });
    } else {
      this.setState({ addressValidation: "success" });
    }
    if (this.state.city.length === 0) {
      isDataValid = false;
      this.setState({ cityValidation: "error" });
    } else {
      this.setState({ cityValidation: "success" });
    }
    if (this.state.selectedPharmId.length === 0) {
      isDataValid = false;
      this.setState({ selectedPharmIdValidation: "error" });
    } else {
      this.setState({ selectedPharmIdValidation: "success" });
    }
    if (this.state.phone.length === 0) {
      isDataValid = false;
      this.setState({ phoneValidation: "error" });
    } else {
      this.setState({ phoneValidation: "success" });
    }
    if (this.state.state.length === 0) {
      isDataValid = false;
      this.setState({ stateValidation: "error" });
    } else {
      this.setState({ stateValidation: "success" });
    }
    if (this.state.zip.length === 0) {
      isDataValid = false;
      this.setState({ zipValidation: "error" });
    } else {
      this.setState({ zipValidation: "success" });
    }
    if(!isDataValid) {
      this.setState({ isError: true, errorCd: "MIA-601", errorMsg: "Required Data Missing." });
    } else {
      this.setState({ isError: false });
    }

    return isDataValid;
  }

  handleCaptcha = (value)  => {
    window.sessionStorage.setItem('Captcha', value);
    this.props.doCaptcha();
  }

  getPharmList() {
    return invokeAPI(
      {
        path: `/pharmacies`,
        method: "GET",
      });
  }
  generatePharmlist() {
    if (this.state.pharmaciesList.length) {
      var meds = [];
      for (var i = 0; i < this.state.pharmaciesList.length; i++) {
        meds.push(<li className="pharmaciesList--item" key={this.state.pharmaciesList[i].pharmacyId}>
          <Row className="show-grid ">
            <Col md={4}>
              <Button className="btn-success" onClick={this.findAPharmacyContinue.bind(this, this.state.pharmaciesList[i])}>Select</Button>
            </Col>
            <Col md={4}>
              <ul>
                <li><strong>{this.state.pharmaciesList[i].pharmacyName}</strong></li>
                <li>Pharmacy </li>
                <li>Hours 8:30AM-6:00PM </li>
              </ul>
            </Col>
            <Col md={4}>
              <ul>
                <li>{this.state.pharmaciesList[i].address1} {this.state.pharmaciesList[i].address2}</li>
                <li>{this.state.pharmaciesList[i].city}, {this.state.pharmaciesList[i].state} , {this.state.pharmaciesList[i].zip}</li>
                <li>{this.state.pharmaciesList[i].phone}</li>
              </ul>
            </Col>
          </Row>
        </li>);
        // meds.push(<ListGroupItem> {this.state.pharmacies[i].name}</ListGroupItem>);


      }
      return <ul className="pharmaciesList"> {meds} </ul>;
      // return <ListGroup> {meds} </ListGroup>;
    }
    else {
      return "";
    }
  }

  openFindAPharmacyModal() {
    this.setState({ pharmDisplayHTML: this.generatePharmlist() });
    this.setState({ showFindAPharmacyModal: true });
  }

  findAPharmacyContinue(pharm) {
    this.setState({
      selectedPharmId: pharm.pharmacyId,
      selectedPharmName: pharm.pharmacyName,
      pharmacyAddress: (pharm.address1 + " " + pharm.address2),
      pharmacyState: pharm.state,
      pharmacyCity: pharm.city,
      pharmacyZip: pharm.zip,
      pharmacyPhone: pharm.phone,
      showFindAPharmacyModal: false
    });
  }

  closeFindAPharmacyModal() {
    this.setState({ showFindAPharmacyModal: false });
  }

  render() {
    let alertBox;

    if (this.state.isError) {
      alertBox = (
        <Alert bsStyle="danger">
          <h4>Medication Donation Error</h4>
          <p>There was an error creating a medication donation.</p>
          <p>
            {this.state.errorCd}:{this.state.errorMsg}
          </p>
        </Alert>
      );
    } else {
      alertBox = "";
    }

    const theCaptcha = 
      !this.props.showNav ? 
      <ReCAPTCHA
        sitekey="6LdSo-EUAAAAAB5TSRMTMLXp45yTs9L2l2qgnCOf"
        onChange={this.handleCaptcha}
      /> : ''

    return (
      <div className="step DonationFormDonorInfo">
        <Form>
          <FormGroup>
            <Grid>
              <Row className=" row--one">
                <Col md={8}>
                  <div className="radio--group">
                    <div className="radio--group--button">
                      <label>
                        <input type="radio" value="1" checked={this.state.donationType === '1'} onChange={this.handleOptionChange} />
                        I am a Donor
                      </label>
                    </div>
                    <div className="radio--group--button">
                      <label>
                        <input type="radio" value="2" checked={this.state.donationType === '2'} onChange={this.handleOptionChange} />
                        I am a Donor's Representative
                      </label>
                    </div>
                    <div className="radio--group--button">
                      <label>
                        <input type="radio" value="3" checked={this.state.donationType === '3'} onChange={this.handleOptionChange} />
                        I am a Facility
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  {theCaptcha}
                </Col>
                <Col md={6}>
                  <FormGroup validationState={this.state.nameValidation}>
                    <ControlLabel>Donor or Representative Name</ControlLabel>
                    <FormControl type="text" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} />
                  </FormGroup>
                  <ControlLabel>Your Email</ControlLabel>
                  <FormControl type="text" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} />
                  <FormControl.Static>This information is kept private.  Our site will use it to send a confirmation email regarding your donation.</FormControl.Static>
                  <FormGroup validationState={this.state.addressValidation}>
                    <ControlLabel>Your Address</ControlLabel>                  
                    <FormControl type="text" value={this.state.address} onChange={(event) => this.setState({ address: event.target.value })} />
                  </FormGroup>
                  <FormGroup validationState={this.state.cityValidation}>
                    <ControlLabel>City</ControlLabel>
                    <FormControl type="text" value={this.state.city} onChange={(event) => this.setState({ city: event.target.value })} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <div >
                    <Col md={12} className="form--inline-btn">
                      <Col md={12}>
                        <ControlLabel>Location I Want to Donate</ControlLabel>
                        <FormControl.Static>Find a location to take your donation by searching participating pharmacies.</FormControl.Static>
                      </Col>
                      <Col md={7}>
                        <Label>{this.state.selectedPharmName}</Label>
                      </Col>
                      <Col md={5}>
                        <Button bsStyle="primary" onClick={this.openFindAPharmacyModal.bind(this)}>Find Location</Button>
                      </Col>
                    </Col>
                  </div>
                  <FormGroup controlId="phone" validationState={this.state.phoneValidation}>
                  <ControlLabel>Your Phone</ControlLabel>
                  <PhoneInput
                    defaultCountry="US"
                    value={this.state.phone}
                    onChange={(value) => this.setState({ phone: value })}
                    placeholder="Your Phone"
                  />
                </FormGroup>
                  <FormControl.Static>This information is kept private.  The pharmacist will use if they need to contact you regarding your donation.</FormControl.Static>
                  <Col md={12} className="form--inline">
                    <Col md={6}>
                    <FormGroup controlId="state" validationState={this.state.stateValidation}>
                  <ControlLabel>State</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="State"
                    value={this.state.state}
                    onChange={(event) => this.setState({ state: event.target.value })}
                  >
                    { USStates.map((item, i) => {return <option key={i} value={item.value}>{item.name}</option>}) }
                  </FormControl>
                </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup validationState={this.state.zipValidation}>
                        <ControlLabel>Zip Code</ControlLabel>
                        <FormControl type="text" value={this.state.zip} onChange={(event) => this.setState({ zip: event.target.value })} />
                      </FormGroup>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Grid>
          </FormGroup>
        </Form>
        {alertBox}
        <FindAPharmacyModal
          pharmacyList={this.state.pharmDisplayHTML}
          // onContinue={this.findAPharmacyContinue}
          show={this.state.showFindAPharmacyModal}
          onHide={this.closeFindAPharmacyModal} />
      </div>
    )
  }
}
