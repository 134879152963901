import React, { Component } from "react";
import { Grid, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

import "./Environment.css";
export default class Environment extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="Environment">
        <div className="environment">
          <Grid>
            <Row className="show-grid row--one">
              <Col md={6}></Col>
              <Col md={4}>
                <h1 className="page-title">Save the Environment</h1>
                <p>
                  We are working to bridge the gap between the billions of dollars of unused and unexpired medications thrown away each year and the patients who need them.
                </p>
                <div className="button-contain">
                  <Link to='donatehow'><Button bsStyle="success" bsSize="large">How To Donate</Button></Link>
                </div>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Row className="show-grid row--two">
              <Col md={6} className="col-center">
                <div className="button-contain">
                  <Link to='findPharmacy'><Button bsStyle="success" bsSize="large">Participating Pharmacies</Button></Link>
                </div>
                </Col>
              <Col md={4}>
                <h1 className="page-title">Find a Pharmacy</h1>
                <p>
                  Your help has created a prescription drug program that improves
                  access to needed medications for those who can't afford them and
                  keeps them out of landfills and destroying our environment.
                </p>
                
              </Col>
              <Col md={2}></Col>
              
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
