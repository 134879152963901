import decode from 'jwt-decode';
import config from "../config";

//import axios from 'axios';

export default class AuthService {
  // Initializing important variables
  constructor(domain) {
    this.domain = domain || config.apiGateway.URL // API server domain
    this.fetch = this.fetch.bind(this) // React binding stuff
    this.login = this.login.bind(this)
    this.getProfile = this.getProfile.bind(this)
  }

  async login(un, pw) {
    // Remove the token before ew try to login.
    // localStorage.removeItem('id_token');
    this.removeLocalStorage();
    let data = new FormData();
    data.append('provider', 'credentials');
    data.append('UserName', un);
    data.append('Password', pw);
    try {
      await this.fetch(`${this.domain}/auth`, {
        method: 'POST',
        body: data
      }).then((respFetch) => {
        this.setStorage('id_token', respFetch.bearerToken); // Setting the token in localStorage
        this.setStorage('userId', respFetch.userId); // Setting the token in localStorage
        this.setStorage('sessionId', respFetch.sessionId); // Setting the token in localStorage
        return Promise.resolve();
      }).catch((respFetch)=> {
        console.error("Login Failed:", respFetch);
        this.setStorage('error', respFetch); // Setting error in localStorage
        return Promise.reject("Login Failed");
      });
    } catch(err) {
      console.error("Login Exception:", err);
      this.setStorage('error', err); // Setting error in localStorage
      return Promise.reject("Login Exception");
    }
  }

  async getUserType() {
    const res = await this.fetch(`${this.domain}/users/current`, {
      method: 'GET'
    });
    return Promise.resolve(res);
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken() // Getting token from localstorage
    var chkToken = !!token && !this.isTokenExpired(token) // handwaiving here
    return chkToken;
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
        return true;
      }
      else {
        return false;
      } 
    }
    catch (err) {
      console.error("Token Error:", err);
      return false;
    }
  }

  setStorage(key, data) {
    // Saves user token to localStorage
    localStorage.setItem(key, data);
    // localStorage.setItem('id_token', idToken)
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token')
  }

  removeLocalStorage() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('userId');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('error');
  }
  logout() {

    // this.fetch(`${this.domain}/auth/logout`, {
    //   method: 'GET'
    // });
    this.removeLocalStorage();

  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }

  async requestMedication(data) {
    const res = await this.fetch(`${this.domain}/requests`, {
      method: 'POST',
      body: data
    });
    return Promise.resolve(res);
  }

  async getMedications() {
    const res = await this.fetch(`${this.domain}/medications`, {
      method: 'GET'
    });
    return Promise.resolve(res);
  }

  fetch(url, options, ) {
    // performs api calls sending the required authentication headers
    let headers = {
      'Accept': 'application/json'
    }
    if (this.loggedIn()) {
      headers = { 'Accept': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('id_token') };
    }

    return fetch(url, {
      headers,
      ...options
    })
      .then(this._checkStatus)
      .then(response => response.json())
      .catch(function(err) {
        console.error("Fetch failed!", err);
        return Promise.reject(err);
      })
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
      return response
    } else {
      return Promise.reject(response.json());
      // var error = new Error(response.statusText)
      // error.response = response
      // throw error
    }
  }
}