import React from "react";
import ReactGA from 'react-ga';

import "./NotFound.css";

export default () =>
  <div className="NotFound">
   { ReactGA.pageview(window.location.pathname + window.location.search) }
    <h3>No Page Here, Go about your merry way!</h3>
  </div>;
