import React, { Component } from "react";
import {
  Tooltip,
  OverlayTrigger,
  Alert,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
  Button,
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import "./Signup.css";
//import { Link } from "react-router-dom";
import { AuthenticationDetails } from "amazon-cognito-identity-js";
import { UserContext } from "../../globals";
//import config from "../../config";
import { invokeAPI } from "../../libs/apiLib";
import ReactGA from 'react-ga';

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
    };
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleDismiss() {
    this.setState({ isError: false });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isError: false });
    this.setState({ isLoading: true });

    try {
      const newUser = await this.signup(this.state.email, this.state.password);
      this.setState({
        newUser: newUser,
      });
    } catch (e) {
      console.error(e);
      this.setState({ isError: true });
    }

    this.setState({ isLoading: false });
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.confirm(this.state.newUser, this.state.confirmationCode);
      await this.authenticate(
        this.state.newUser,
        this.state.email,
        this.state.password
      );

      this.props.userHasAuthenticated(true);
      this.props.setUserContext(UserContext.Member);
      this.props.history.push("/memberhome");
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  signup(email, password) {
    let data = new FormData();
    data.append("email", email);
    data.append("password", password);

    var apiData = { Email: email, Password: password };

    return invokeAPI({
      path: "/register/member",
      method: "POST",
      body: JSON.stringify(apiData),
    });
  }

  confirm(user, confirmationCode) {
    return new Promise((resolve, reject) =>
      user.confirmRegistration(confirmationCode, true, function (err, result) {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      })
    );
  }

  authenticate(user, email, password) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) =>
      user.authUser(authenticationDetails, {
        onSuccess: (result) => resolve(),
        onFailure: (err) => reject(err),
      })
    );
  }

  renderConfirmationForm() {
    return (
      <Col md={12} className="text-center">
        <h4>A verification link has been sent to your email account</h4>
        <p>
          Please click on the link that has been sent to your email account to
          verify your email.
        </p>
        <br />
        <br />
        <Button href="login" bsStyle="success">
          Login
        </Button>
      </Col>
    );
  }

  renderForm() {
    const UsernameTip = (
      <Tooltip id="tooltip">
        <strong>Username</strong> is your registered email.
      </Tooltip>
    );

    const PasswordTip = (
      <Tooltip id="tooltip">
        <strong>Passwords must meet the following criteria:</strong>
        <br />
        <br />
        <ul>
          <li>Must be at least 8 and a maximum of 64 characters</li>
          <br />
          <li>
            At least one Capital and Lower case letter
            <br />
            (a-zA-Z)
          </li>
          <br />
          <li>
            At least one non-alphanumeric / special character
            <br />
            (~!@#$%^&*_-+=`|(){}[]:;"'&lt;&gt;,.?/)
          </li>
          <br />
          <li>
            At least one number
            <br />
            (0-9)
          </li>
          <br />
        </ul>
      </Tooltip>
    );

    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <OverlayTrigger placement="top" overlay={UsernameTip}>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </OverlayTrigger>
        </FormGroup>

        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <OverlayTrigger placement="top" overlay={PasswordTip}>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </OverlayTrigger>
        </FormGroup>

        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Signup"
          loadingText="Signing up…"
        />
      </form>
    );
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let alertBox;

    if (this.state.isError) {
      alertBox = (
        <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
          <h4>SignUp Error</h4>
          <p>There was an error signing up.</p>
        </Alert>
      );
    } else {
      alertBox = "";
    }

    return (
      <div className="Signup">
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
        {alertBox}
      </div>
    );
  }
}