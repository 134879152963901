import React, { Component } from "react";
import { Image, Button, Grid, Row, Col } from "react-bootstrap";
import ReactGA from 'react-ga';

import "./DonationPdf.css";

export default class DonationPdf extends Component {

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="DonationForm">
        <div className="donationForm">
          <Grid>
            <Row className="show-grid">
              <Col md={1}></Col>
              <Col md={10}>
                <h1 className="page-title">Download Form</h1>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row className="show-grid">
              <Col md={1}></Col>
              <Col md={10}>
                <p>
                We prefer that you donate online so we can track the medications donated and report the success of our program.
                However, you are not required to use our online services.  You can print the form provided by the Department
                of Health and return your medication to any participating pharmacy.
                </p>
                <br />
                <div className="donationForm--button">
                  <Image src="Login_Row2_DownloadForm.png"></Image>
                  <br />
                  <br />
                  <br />
                  <Button href="donationForm.pdf" bsStyle="success">Download Form</Button>
                </div>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Grid>
        </div>
      </div>


    );
  }
}
