import React, { Component } from "react";
import { Grid, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

import "./DonateHow.css";

export default class DonateHow extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="DonateHow">
        <div className="donateHow">
          <Grid fluid={true}>
            <Row className="show-grid row--one">
              <Col md={2}>
              </Col>
              <Col md={4}>
                <h2>You can make a difference.  Donating is as easy as 1-2-3.</h2>
                <p>
                  Donating medications back to a participating pharmacy is an easy 3 step process.
                  You can scroll down to learn about each step required to donate.
                  Click on the online form and we will walk you through the process.
              </p>
                <Link to='donation'><Button bsStyle="success" bsSize="large">Online Form</Button></Link>
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row className="show-grid row--two">
              <Col md={2}>
              </Col>
              <Col md={4}>
                <br />
                <h2>Step 1: Complete the Online Donor Form</h2>
                <p>
                  Start by clicking on the "Online Form" button above. You will start by completing an online form,
                  required by the Washington State Department of Health. The information provided is for patient safety
                  and will not be used for any purpose other than contacting the donor if needed for questions. See our Privacy Policy for more details.
              </p>
              </Col>
              <Col md={6} className="row--two--coltwo">
                <Image src="DonateHow_Screenshot.png" rounded></Image>
              </Col>
            </Row>
            <Row className="show-grid row--three">
              {/* <Col md={1}>
              </Col> */}
              <Col md={6} className="row--three--colone">
                <Image src="DonateHow_Screenshot.png" rounded></Image>
              </Col>
              {/* <Col md={2}>
              
              </Col> */}
              <Col md={4}>
                <br />
                <h2>Step 2: Provide Medication Information</h2>
                <p>
                  Provide us with some specific information about the medication you are donating and select a participating pharmacy that you would like to return this medication to.
              </p>
                <Link to='findPharmacy'><Button bsStyle="success" bsSize="large">Participating Pharmacies</Button></Link>
                {/* <Button href="findPharmacy" bsStyle="success" bsSize="lg">Participating Pharmacies</Button> */}
              </Col>
              <Col md={2}>
              </Col>
            </Row>
            <Row className="show-grid row--four">
              <Col md={2}>
              </Col>
              <Col md={4}>
                <h2>Step 3: Return Your Medication</h2>
                <p>
                  After you submit the online donor form and provide the information about your donated medication, you will need
                  to return the medication into a participating pharmacy.  We will send you an email with confirmation of your donation
                  and what pharmacy you selected to return the medications to.  The pharmacist has been notified of your donation
                  and will be expecting you.
              </p>
              </Col>
              <Col md={6} >
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
