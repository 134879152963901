import React from "react";
import { Route, Switch } from "react-router-dom";

import NotFound from "./containers/NotFound/NotFound";
import Home from "./containers/Home/Home";
import Donate from "./containers/Donate/Donate";
import DonateHow from "./containers/DonateHow/DonateHow";
import FindPharmacy from "./containers/FindPharmacy/FindPharmacy";
import PharmacyHome from "./containers/PharmacyHome/PharmacyHome";
import MemberHome from "./containers/MemberHome/MemberHome";
import FindMedication from "./containers/FindMedication/FindMedication";
import FindMedicationResults from "./containers/FindMedicationResults/FindMedicationResults";
import Donation from "./containers/Donation/Donation";
import DonationThankYou from "./containers/DonationThankYou/DonationThankYou";
import Login from "./containers/Login/Login";
import LoginPharmacy from "./containers/LoginPharmacy/LoginPharmacy";
import AppliedRoute from "./components/AppliedRoute";
import Signup from "./containers/Signup/Signup";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import Environment from "./containers/Environment/Environment";
import DonateMoney from "./containers/DonateMoney/DonateMoney";
import TermsConditions from "./containers/TermsConditions/TermsConditions";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import DonationPdf from "./containers/DonationPdf/DonationPdf";
import AboutUs from "./containers/AboutUs/AboutUs";
import ContactUs from "./containers/ContactUs/ContactUs";
import NewMedication from "./containers/NewMedication/NewMedication";
import MedicationDetail from "./containers/MedicationDetail/MedicationDetail";
import DonationDetail from "./containers/DonationDetail/DonationDetail";
import RequestDetail from "./containers/RequestDetail/RequestDetail";
import DonationForm from "./containers/DonationForm/DonationForm";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ConfirmForgot from "./containers/ConfirmForgot/ConfirmForgot";
import Sandbox from "./components/Sandbox/Sandbox";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/index.html" exact component={Home} props={childProps} />
    <AppliedRoute path="/donate" exact component={Donate} props={childProps} />
    <AppliedRoute path="/donatehow" exact component={DonateHow} props={childProps} />
    <AppliedRoute path="/findpharmacy" exact component={FindPharmacy} props={childProps} />
    <AppliedRoute path="/findmedication" exact component={FindMedication} props={childProps} />
    <AppliedRoute path="/findmedicationresults" exact component={FindMedicationResults} props={childProps} />
    <AppliedRoute path="/donationthankyou" exact component={DonationThankYou} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/loginpharmacy" exact component={LoginPharmacy} props={childProps} />
    <AppliedRoute path="/donation" exact component={Donation} props={childProps} />
    <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
    <AppliedRoute path="/forgotpassword" exact component={ForgotPassword} props={childProps} />
    <AppliedRoute path="/confirmforgot" exact component={ConfirmForgot} props={childProps} />
    <AppliedRoute path="/environment" exact component={Environment} props={childProps} />
    <AppliedRoute path="/donatemoney" exact component={DonateMoney} props={childProps} />
    <AppliedRoute path="/termsconditions" exact component={TermsConditions} props={childProps} />
    <AppliedRoute path="/donationpdf" exact component={DonationPdf} props={childProps} />
    <AppliedRoute path="/privacypolicy" exact component={PrivacyPolicy} props={childProps} />
    <AppliedRoute path="/aboutus" exact component={AboutUs} props={childProps} />
    <AppliedRoute path="/contactus" exact component={ContactUs} props={childProps} />
    <AuthenticatedRoute path="/medication/new" exact component={NewMedication} props={childProps} />
    <AuthenticatedRoute path="/medication/:id" exact component={MedicationDetail} props={childProps} />
    <AuthenticatedRoute path="/donation/:id" exact component={DonationDetail} props={childProps} />
    <AuthenticatedRoute path="/request/:id" exact component={RequestDetail} props={childProps} />
    <AppliedRoute path="/donationform" exact component={DonationForm} props={childProps} />
    <AuthenticatedRoute path="/pharmacyhome" exact component={PharmacyHome} props={childProps} />
    <AuthenticatedRoute path="/memberhome" exact component={MemberHome} props={childProps} />
    <AppliedRoute path="/sandbox" exact component={Sandbox} props={childProps} />
    { /* Catch ALL */}
    <Route component={NotFound} />
  </Switch>;

// NOTE: Why do we have this... appliedRoute is essentially an UnauthenticatedRoute.
  // UnauthenticatedRoute <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
