import React from "react";
import { Panel, Grid, Row, Col } from "react-bootstrap";
import ReactGA from 'react-ga';

import "./PharmacySummary.css";

export default function PharmacySummary(props) {
    ReactGA.pageview('Pharmacy Summary');
    return (
      <div className="pharmacySummary">
        <Grid>
          <Row className="show-grid rowOne">
            <Col md={3}>
              <Panel bsStyle="info">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Current Available Medications</Panel.Title>
                </Panel.Heading>
                <Panel.Body>{props.availableMedications.length} Medications</Panel.Body>
              </Panel>
            </Col>
            <Col md={3}>
              <Panel bsStyle="info">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Current Medications Requested</Panel.Title>
                </Panel.Heading>
                <Panel.Body>{props.medicationRequests.length} Medications</Panel.Body>
              </Panel>
            </Col>
            <Col md={3}>
              <Panel bsStyle="info">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">All Medications</Panel.Title>
                </Panel.Heading>
                <Panel.Body>{props.allMedications.length} Medications</Panel.Body>
              </Panel>
            </Col>
          </Row>
          {/*
          <Row className="show-grid rowTwo">
            <Col md={4}>
              <Panel bsStyle="info">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Previous Donations</Panel.Title>
                </Panel.Heading>
                <Panel.Body>{props.previousDonations.length} Donations (This is an archive of all previous donations.)</Panel.Body>
              </Panel>
            </Col>
            <Col md={6}>
              <Panel bsStyle="danger">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Basic Pharmacy Analytics</Panel.Title>
                </Panel.Heading>
                <Panel.Body>We could provide the basic pharmacy analytics such as most donation times, most requested medication, and most donated medications, etc.</Panel.Body>
              </Panel>
            </Col>
          </Row>
          */}
        </Grid>
      </div>
    );
  }