import React, { Component } from "react";
import { Alert, FormGroup, FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import "./ForgotPassword.css";
import { invokeAPI } from "../../libs/apiLib";
import ReactGA from 'react-ga';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
      email: ""
    };
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    this.setState({ isError: false });
  }

  validateForm() {
    return this.state.email.length > 0;
  }

  requestReset(email) {
    let data = new FormData();
    data.append('PasswordChangeType', 'ForgotPassword');
    return invokeAPI({
      path: '/users/' + email + '/password',
      method: "POST",
      body: data,
    });
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }


  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isError: false });
    this.setState({ isLoading: true });

    try {
      await this.requestReset(this.state.email);
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
      this.setState({ isError: true });
    }
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let alertBox;

    if(this.state.isError) {
      alertBox = <Alert bsStyle="danger" onDismiss={ this.handleDismiss }>
        <h4>SignUp Error</h4>
        <p>
          There was an error signing up.
        </p>
      </Alert>;
    } else {
      alertBox = '';
    }

    return (
      <div className="Login">
        <Grid>
          <Row className="show-grid rowOne">
            <Col md={4}>
            </Col>
            <Col md={4}>
              <div className="text-center">
                <h4>Forgot Password</h4>
              </div>
              <br />
              <br />
              <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="email">
                  <ControlLabel>Email</ControlLabel>
                  <FormControl autoFocus type="email" value={this.state.email} onChange={this.handleChange} />
                </FormGroup>
                <FormGroup className="text-center">
                  <LoaderButton bsStyle="success" disabled={!this.validateForm()} type="submit" isLoading={this.state.isLoading} text="Request Change" loadingText="Requesting..." />
                </FormGroup>
              </form>
              { alertBox }
            </Col>
            <Col md={4}>
            </Col>
          </Row>
        </Grid>
      </div >
    );
  }
}
