import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import ReactGA from 'react-ga';

import "./DonationThankYou.css";

export default class DonationThankYou extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="DonationThankYou">
        <div className="donationThankYou">
          <Grid>
            <Row className="show-grid rowOne">
              <Col md={1}></Col>
              <Col md={10}>
                <h1>Thank You!</h1>
                <br />
                <p>
                  Your donation means the world to patients who need this medication and are not able to afford it otherwise.
                  Thank you for your time and effort to help others in your community.  You will receive an email with a confirmation of your donation and where you are to return the medication.
                  Please complete your donation by delivering your medication to the pharmacy you selected.
              </p>
                <br />
                <div>
                  <h4>Pharmacy Information</h4>
                  <span>
                    =Pharmacy Name=
                </span>
                  <br />
                  <span>
                    =Pharmacy Street 1=<br />
                    =Pharmacy Street 2=
                </span>
                  <br />
                  <br />
                  <span>
                    =Pharmacy Hours=
                </span>
                  <br />
                  <br />
                  <span>
                    =Pharmacy Phone=
                </span>
                </div>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
