// export var baseUrl = "http://localhost:5000";
export var baseUrl = "https://api.donatedrugs.net";
export var UserContext = Object.freeze({ "Public": "Public", "Member": "Member", "Pharmacy": "Pharmacist", "Guest": "Guest" });
// KLS - Testing Feedback List, Found Better List Below
// export var MedicationType = ["Tablet","Capsule","Milliliter","Each","Inhaler","Cream","Lotion","Ointment"];
export var MedicationType = [
                    {"value":"","name":""},
                    {"value":"Unknown","name":"Unknown"},
                    {"value":"PillTablet","name":"Pill (Tablet)"},
                    {"value":"PillCapsule","name":"Pill (Capsule)"},
                    {"value":"Patch","name":"Patch"},
                    {"value":"Inhaler","name":"Inhaler"},
                    {"value":"SolutionNebulizer","name":"Solution (Nebulizer)"},
                    {"value":"SolutionIvOral","name":"Solution (IV or Oral)"},
                    {"value":"SuppositoryVagRec","name":"Suppository (Vaginal or Rectal)"},
                    {"value":"Syringe","name":"Syringe"},
                    {"value":"DropsEyeEar","name":"Drops (Eye or Ear)"},
                    {"value":"SprayNasal","name":"Spray (Nasal)"},
                    {"value":"TopicalOintmentCream","name":"Topical (Ointment or Cream)"},
];
export var MedicationSource = [
                    {"value":"","name":""},
                    {"value":"Unknown","name":"Unknown"},
                    {"value":"Donor","name":"Donor"},
                    {"value":"DonorRep","name":"Donor Representative"},
                    {"value":"Hospital","name":"Hospital"},
                    {"value":"DocOffice","name":"Doctor's Office"},
                    {"value":"Clinic","name":"Clinic"},
                    {"value":"MinorEmergencyClinic","name":"Minor Emergency Clinic"},
                    {"value":"DrugManufacturer","name":"Drug Manufacturer"}
];
export var USStates = [
                    {"value":"","name":"N/A"},
                    {"value":"AK","name":"Alaska"},
                    {"value":"AL","name":"Alabama"},
                    {"value":"AR","name":"Arkansas"},
                    {"value":"AZ","name":"Arizona"},
                    {"value":"CA","name":"California"},
                    {"value":"CO","name":"Colorado"},
                    {"value":"CT","name":"Connecticut"},
                    {"value":"DC","name":"District of Columbia"},
                    {"value":"DE","name":"Delaware"},
                    {"value":"FL","name":"Florida"},
                    {"value":"GA","name":"Georgia"},
                    {"value":"HI","name":"Hawaii"},
                    {"value":"IA","name":"Iowa"},
                    {"value":"ID","name":"Idaho"},
                    {"value":"IL","name":"Illinois"},
                    {"value":"IN","name":"Indiana"},
                    {"value":"KS","name":"Kansas"},
                    {"value":"KY","name":"Kentucky"},
                    {"value":"LA","name":"Louisiana"},
                    {"value":"MA","name":"Massachusetts"},
                    {"value":"MD","name":"Maryland"},
                    {"value":"ME","name":"Maine"},
                    {"value":"MI","name":"Michigan"},
                    {"value":"MN","name":"Minnesota"},
                    {"value":"MO","name":"Missouri"},
                    {"value":"MS","name":"Mississippi"},
                    {"value":"MT","name":"Montana"},
                    {"value":"NC","name":"North Carolina"},
                    {"value":"ND","name":"North Dakota"},
                    {"value":"NE","name":"Nebraska"},
                    {"value":"NH","name":"New Hampshire"},
                    {"value":"NJ","name":"New Jersey"},
                    {"value":"NM","name":"New Mexico"},
                    {"value":"NV","name":"Nevada"},
                    {"value":"NY","name":"New York"},
                    {"value":"OH","name":"Ohio"},
                    {"value":"OK","name":"Oklahoma"},
                    {"value":"OR","name":"Oregon"},
                    {"value":"PA","name":"Pennsylvania"},
                    {"value":"PR","name":"Puerto Rico"},
                    {"value":"RI","name":"Rhode Island"},
                    {"value":"SC","name":"South Carolina"},
                    {"value":"SD","name":"South Dakota"},
                    {"value":"TN","name":"Tennessee"},
                    {"value":"TX","name":"Texas"},
                    {"value":"UT","name":"Utah"},
                    {"value":"VA","name":"Virginia"},
                    {"value":"VT","name":"Vermont"},
                    {"value":"WA","name":"Washington"},
                    {"value":"WI","name":"Wisconsin"},
                    {"value":"WV","name":"West Virginia"},
                    {"value":"WY","name":"Wyoming"}
];