import React, { useState, useEffect } from "react";
import {
  Alert,
  Modal,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar,
} from "react-bootstrap";
import { DateTime } from "react-datetime-bootstrap";
import { baseUrl, MedicationType, MedicationSource } from "../../globals";
import { JsonServiceClient } from "@servicestack/client";
import { UpdateMedicationInventory } from "../../shared/dtos";
import ReactGA from "react-ga";

import "./MedicationInventoryEdit.css";

const MedicationInventoryEdit = (props) => {
  const med = props.medication;
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCd, setErrorCd] = useState("");
  const [apiClient, setApiClient] = useState();
  const [drugName, setDrugName] = useState("");
  const [drugNameValidation, setDrugNameValidation] = useState(null);
  const [pharmacyId, setPharmacyId] = useState("");
  const [pharmacyIdValidation, setPharmacyIdValidation] = useState(null);
  const [expirationDate, setExpirationDate] = useState("");
  const [expirationDateValidation, setExpirationDateValidation] = useState(
    null
  );
  const [strengthDosage, setStrengthDosage] = useState("");
  const [strengthDosageValidation, setStrengthDosageValidation] = useState(
    null
  );
  const [lotNumber, setLotNumber] = useState("");
  const [lotNumberValidation, setLotNumberValidation] = useState(null);
  const [ndcNumber, setNdcNumber] = useState("");
  const [ndcNumberValidation, setNdcNumberValidation] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [quantityValidation, setQuantityValidation] = useState(null);
  const [quantityType, setQuantityType] = useState("");
  const [quantityTypeValidation, setQuantityTypeValidation] = useState(null);
  const [source, setSource] = useState("");
  const [sourceValidation, setSourceValidation] = useState(null);
  const [medicationInventoryStatus, setMedicationInventoryStatus] = useState();
  const [
    medicationInventoryStatusValidation,
    setMedicationInventoryStatusValidation,
  ] = useState(null);
  const [zip, setZip] = useState();
  const [pharmacyName, setPharmacyName] = useState();

  useEffect(() => {
    const apiUrl = baseUrl;
    const jwt = localStorage.getItem("id_token");

    var ssClient = new JsonServiceClient(apiUrl);
    ssClient.bearerToken = jwt;
    setApiClient((apiClient) => ssClient);
  }, []);

  useEffect(() => {
    setDrugName(med.drugName);
    setPharmacyId(med.pharmacyId);
    setExpirationDate(med.expirationDate);
    setStrengthDosage(med.strengthDosage);
    setLotNumber(med.lotNumber);
    setNdcNumber(med.ndcNumber);
    setQuantity(med.quantity);
    setQuantityType(med.quantityType);
    setSource(med.source);
    setMedicationInventoryStatus(med.medicationInventoryStatus);
    setZip(med.zip);
    setPharmacyName(med.pharmacyName);
  }, [
    med.drugName,
    med.pharmacyId,
    med.expirationDate,
    med.strengthDosage,
    med.lotNumber,
    med.ndcNumber,
    med.quantity,
    med.quantityType,
    med.source,
    med.medicationInventoryStatus,
    med.zip,
    med.pharmacyName,
  ]);

  useEffect(() => {
    props.doEdit(isError);
  }, [isError]);

  const checkRequired = () => {
    let rtnVal = true;

    if (drugName.length === 0) {
      rtnVal = false;
      setDrugNameValidation("error");
    } else {
      setDrugNameValidation("success");
    }
    if (pharmacyId.length === 0) {
      rtnVal = false;
      setPharmacyIdValidation("error");
    } else {
      setPharmacyIdValidation("success");
    }
    if (expirationDate.length === 0) {
      rtnVal = false;
      setExpirationDateValidation("error");
    } else {
      setExpirationDateValidation("success");
    }
    if (strengthDosage.length === 0) {
      rtnVal = false;
      setStrengthDosageValidation("error");
    } else {
      setStrengthDosageValidation("success");
    }
    if (lotNumber.length === 0) {
      rtnVal = false;
      setLotNumberValidation("error");
    } else {
      setLotNumberValidation("success");
    }
    if (ndcNumber.length === 0) {
      rtnVal = false;
      setNdcNumberValidation("error");
    } else {
      setNdcNumberValidation("success");
    }
    if (quantity.length === 0) {
      rtnVal = false;
      setQuantityValidation("error");
    } else {
      setQuantityValidation("success");
    }
    if (quantityType.length === 0) {
      rtnVal = false;
      setQuantityTypeValidation("error");
    } else {
      setQuantityTypeValidation("success");
    }
    if (source.length === 0) {
      rtnVal = false;
      setSourceValidation("error");
    } else {
      setSourceValidation("success");
    }
    if (medicationInventoryStatus.length === 0) {
      rtnVal = false;
      setMedicationInventoryStatusValidation("error");
    } else {
      setMedicationInventoryStatusValidation("success");
    }
    if (!rtnVal) {
      setIsError(true);
    }

    return rtnVal;
  };

  const editInventory = async (e) => {
    // check for required data
    if (!checkRequired()) return;
    // create client and Edit new med.
    try {
      let request = new UpdateMedicationInventory();
      request.medicationInventoryId = med.medicationInventoryId;
      request.pharmacyId = pharmacyId;
      request.pharmacyName = pharmacyName;
      request.drugName = drugName;
      request.expirationDate = expirationDate;
      request.lotNumber = lotNumber;
      request.source = source;
      request.quantity = quantity;
      request.quantityType = quantityType;
      request.ndcNumber = ndcNumber;
      request.strengthDosage = strengthDosage;
      request.zip = zip;
      request.medicationInventoryStatus = medicationInventoryStatus;

      await apiClient.put(request);
      setIsError(false);
      props.doEdit(false);
    } catch (err) {
      setIsError(true);
      setErrorCd("MIA-200");
      setErrorMsg(JSON.stringify(err));
      console.error("error editing medication:" + JSON.stringify(err));
    }
  };

  const handleDateChange = (dateVal, priorDateVal) => {
    setExpirationDate(dateVal);
  };

  const handleChange = (e) => {
    var theId = e.target.id;
    var theVal = e.target.value;

    switch (theId) {
      case "drugName": {
        setDrugName((drugName) => theVal);
        break;
      }
      case "pharmacyId": {
        setPharmacyId((pharmacyId) => theVal);
        break;
      }
      case "expirationDate": {
        setExpirationDate((expirationDate) => theVal);
        break;
      }
      case "strengthDosage": {
        setStrengthDosage((strengthDosage) => theVal);
        break;
      }
      case "lotNumber": {
        setLotNumber((lotNumber) => theVal);
        break;
      }
      case "ndcNumber": {
        setNdcNumber((ndcNumber) => theVal);
        break;
      }
      case "quantity": {
        setQuantity((quantity) => theVal);
        break;
      }
      case "quantityType": {
        setQuantityType((quantityType) => theVal);
        break;
      }
      case "source": {
        setSource((source) => theVal);
        break;
      }
      case "medicationInventoryStatus": {
        setMedicationInventoryStatus((medicationInventoryStatus) => theVal);
        break;
      }
      default:
        break;
    }
  };

  const closeEdit = () => {
    setIsError(false);
    props.onHide();
  };

  let alertBox;

  if (isError) {
    alertBox = (
      <Alert bsStyle="danger">
        <h4>Edit Medication Error</h4>
        <p>There was an error editing the medication.</p>
        <p>
          {errorCd}:{errorMsg}
        </p>
      </Alert>
    );
  } else {
    alertBox = "";
  }
  ReactGA.pageview("Medication Inventory Edit");
  return (
    <Modal
      className="MedicationInventoryEdit"
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
      {...props}
      onHide={closeEdit}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">
          Medication Inventory - Edit Medication ({med.medicationInventoryId})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup validationState={drugNameValidation}>
                <ControlLabel>Drug Name</ControlLabel>
                <FormControl
                  id="drugName"
                  type="text"
                  placeholder="Medication Name"
                  value={drugName}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={pharmacyIdValidation}>
                <ControlLabel>Pharmacy ID</ControlLabel>
                <FormControl
                  id="pharmacyId"
                  type="number"
                  placeholder="Pharmacy ID"
                  value={pharmacyId}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup validationState={expirationDateValidation}>
                <ControlLabel>Expiration Date</ControlLabel>
                <DateTime
                  className="dtPicker"
                  id="expirationDate"
                  value={expirationDate}
                  onChange={handleDateChange}
                  pickerOptions={{ format: "L", allowInputToggle: false }}
                  placeholder="Expiration Date"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={strengthDosageValidation}>
                <ControlLabel>Strength Dosage</ControlLabel>
                <FormControl
                  id="strengthDosage"
                  type="text"
                  placeholder="Strength Dosage"
                  value={strengthDosage}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup validationState={lotNumberValidation}>
                <ControlLabel>Lot Number</ControlLabel>
                <FormControl
                  id="lotNumber"
                  type="text"
                  placeholder="Lot Number"
                  value={lotNumber}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={ndcNumberValidation}>
                <ControlLabel>NDC Number</ControlLabel>
                <FormControl
                  id="ndcNumber"
                  type="text"
                  placeholder="NDC Number"
                  value={ndcNumber}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup validationState={quantityValidation}>
                <ControlLabel>Quantity</ControlLabel>
                <FormControl
                  id="quantity"
                  type="text"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                controlId="quantityType"
                validationState={quantityTypeValidation}
              >
                <ControlLabel>Quantity Type</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="Quantity Type"
                  value={quantityType}
                  onChange={handleChange}
                >
                  {MedicationType.map((item, i) => {
                    return (
                      <option key={i} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup controlId="source" validationState={sourceValidation}>
                <ControlLabel>Source</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="Source"
                  value={source}
                  onChange={handleChange}
                >
                  {MedicationSource.map((item, i) => {
                    return (
                      <option key={i} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </FormControl>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                controlId="medicationInventoryStatus"
                validationState={medicationInventoryStatusValidation}
              >
                <ControlLabel>Inventory Status</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="Inventory Status"
                  value={medicationInventoryStatus}
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Available">Available</option>
                  <option value="OnHold">On Hold</option>
                  <option value="Dispensed">Dispensed</option>
                  <option value="Expired">Expired</option>
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button
            onClick={() => {
              props.doCancel();
              setIsError(false);
            }}
            className="btn-success"
          >
            Cancel
          </Button>
          <Button
            onClick={async (e) => {
              await editInventory(e, props);
            }}
            className="btn-success pull-right"
          >
            Update
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
      {alertBox}
    </Modal>
  );
};
export default MedicationInventoryEdit;
