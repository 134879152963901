"use strict";
/* Options:
Date: 2020-04-08 14:57:18
Version: 5.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://api.donatedrugs.net
//BaseUrl: http://localhost:5000
//GlobalNamespace:
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion:
//AddDescriptionAsComments: True
//IncludeTypes:
//ExcludeTypes:
//DefaultImports:
*/
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var DonatedMedicationStatus;
(function (DonatedMedicationStatus) {
    DonatedMedicationStatus["Accepted"] = "Accepted";
    DonatedMedicationStatus["Rejected"] = "Rejected";
    DonatedMedicationStatus["PendingProcessing"] = "PendingProcessing";
})(DonatedMedicationStatus = exports.DonatedMedicationStatus || (exports.DonatedMedicationStatus = {}));
var SmartEnum_2 = /** @class */ (function () {
    function SmartEnum_2(init) {
        Object.assign(this, init);
    }
    return SmartEnum_2;
}());
exports.SmartEnum_2 = SmartEnum_2;
var SmartEnum_1 = /** @class */ (function (_super) {
    __extends(SmartEnum_1, _super);
    function SmartEnum_1(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return SmartEnum_1;
}(SmartEnum_2));
exports.SmartEnum_1 = SmartEnum_1;
var BaseAudit = /** @class */ (function () {
    function BaseAudit(init) {
        Object.assign(this, init);
    }
    return BaseAudit;
}());
exports.BaseAudit = BaseAudit;
var DonatedMedication = /** @class */ (function (_super) {
    __extends(DonatedMedication, _super);
    function DonatedMedication(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return DonatedMedication;
}(BaseAudit));
exports.DonatedMedication = DonatedMedication;
// @DataContract
var ResponseError = /** @class */ (function () {
    function ResponseError(init) {
        Object.assign(this, init);
    }
    return ResponseError;
}());
exports.ResponseError = ResponseError;
// @DataContract
var ResponseStatus = /** @class */ (function () {
    function ResponseStatus(init) {
        Object.assign(this, init);
    }
    return ResponseStatus;
}());
exports.ResponseStatus = ResponseStatus;
var DonationType;
(function (DonationType) {
    DonationType["Donor"] = "Donor";
    DonationType["Representative"] = "Representative";
    DonationType["Facility"] = "Facility";
})(DonationType = exports.DonationType || (exports.DonationType = {}));
var DonationDetail = /** @class */ (function () {
    function DonationDetail(init) {
        Object.assign(this, init);
    }
    return DonationDetail;
}());
exports.DonationDetail = DonationDetail;
var DonorType;
(function (DonorType) {
    DonorType["Member"] = "Member";
    DonorType["Guest"] = "Guest";
})(DonorType = exports.DonorType || (exports.DonorType = {}));
var Donation = /** @class */ (function (_super) {
    __extends(Donation, _super);
    function Donation(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return Donation;
}(BaseAudit));
exports.Donation = Donation;
var PasswordChangeType;
(function (PasswordChangeType) {
    PasswordChangeType["ForgotPassword"] = "ForgotPassword";
    PasswordChangeType["ForgotPasswordConfirmation"] = "ForgotPasswordConfirmation";
    PasswordChangeType["ChangePassword"] = "ChangePassword";
    PasswordChangeType["ForcedPasswordChange"] = "ForcedPasswordChange";
})(PasswordChangeType = exports.PasswordChangeType || (exports.PasswordChangeType = {}));
var UserPasswordChange = /** @class */ (function () {
    function UserPasswordChange(init) {
        Object.assign(this, init);
    }
    return UserPasswordChange;
}());
exports.UserPasswordChange = UserPasswordChange;
var GetUserResponseModel = /** @class */ (function () {
    function GetUserResponseModel(init) {
        Object.assign(this, init);
    }
    return GetUserResponseModel;
}());
exports.GetUserResponseModel = GetUserResponseModel;
var BaseResponse = /** @class */ (function () {
    function BaseResponse(init) {
        Object.assign(this, init);
    }
    return BaseResponse;
}());
exports.BaseResponse = BaseResponse;
var PrescriptionFillType;
(function (PrescriptionFillType) {
    PrescriptionFillType["None"] = "None";
    PrescriptionFillType["StorePickup"] = "StorePickup";
    PrescriptionFillType["Mailed"] = "Mailed";
})(PrescriptionFillType = exports.PrescriptionFillType || (exports.PrescriptionFillType = {}));
var PrescriptionLocationType;
(function (PrescriptionLocationType) {
    PrescriptionLocationType["None"] = "None";
    PrescriptionLocationType["AtPharmacy"] = "AtPharmacy";
    PrescriptionLocationType["PhysicianWillSend"] = "PhysicianWillSend";
})(PrescriptionLocationType = exports.PrescriptionLocationType || (exports.PrescriptionLocationType = {}));
var MedicationInventoryStatus;
(function (MedicationInventoryStatus) {
    MedicationInventoryStatus["Available"] = "Available";
    MedicationInventoryStatus["OnHold"] = "OnHold";
    MedicationInventoryStatus["Dispensed"] = "Dispensed";
    MedicationInventoryStatus["Expired"] = "Expired";
})(MedicationInventoryStatus = exports.MedicationInventoryStatus || (exports.MedicationInventoryStatus = {}));
var MedicationInventory = /** @class */ (function (_super) {
    __extends(MedicationInventory, _super);
    function MedicationInventory(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return MedicationInventory;
}(BaseAudit));
exports.MedicationInventory = MedicationInventory;
var MedicationRequestStatus = /** @class */ (function (_super) {
    __extends(MedicationRequestStatus, _super);
    function MedicationRequestStatus(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return MedicationRequestStatus;
}(SmartEnum_1));
exports.MedicationRequestStatus = MedicationRequestStatus;
var MedicationRequestModel = /** @class */ (function () {
    function MedicationRequestModel(init) {
        Object.assign(this, init);
    }
    return MedicationRequestModel;
}());
exports.MedicationRequestModel = MedicationRequestModel;
var RequestStatus;
(function (RequestStatus) {
    RequestStatus["Dispensed"] = "Dispensed";
    RequestStatus["Denied"] = "Denied";
})(RequestStatus = exports.RequestStatus || (exports.RequestStatus = {}));
var CreateMedicationInventoryModel = /** @class */ (function (_super) {
    __extends(CreateMedicationInventoryModel, _super);
    function CreateMedicationInventoryModel(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return CreateMedicationInventoryModel;
}(MedicationInventory));
exports.CreateMedicationInventoryModel = CreateMedicationInventoryModel;
var UpdateMedicationInventoryModel = /** @class */ (function (_super) {
    __extends(UpdateMedicationInventoryModel, _super);
    function UpdateMedicationInventoryModel(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return UpdateMedicationInventoryModel;
}(MedicationInventory));
exports.UpdateMedicationInventoryModel = UpdateMedicationInventoryModel;
var BaseResponseList = /** @class */ (function () {
    function BaseResponseList(init) {
        Object.assign(this, init);
    }
    return BaseResponseList;
}());
exports.BaseResponseList = BaseResponseList;
// @DataContract
var QueryBase = /** @class */ (function () {
    function QueryBase(init) {
        Object.assign(this, init);
    }
    return QueryBase;
}());
exports.QueryBase = QueryBase;
var QueryDb_1 = /** @class */ (function (_super) {
    __extends(QueryDb_1, _super);
    function QueryDb_1(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return QueryDb_1;
}(QueryBase));
exports.QueryDb_1 = QueryDb_1;
var Pharmacy = /** @class */ (function (_super) {
    __extends(Pharmacy, _super);
    function Pharmacy(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return Pharmacy;
}(BaseAudit));
exports.Pharmacy = Pharmacy;
var QueryDb_2 = /** @class */ (function (_super) {
    __extends(QueryDb_2, _super);
    function QueryDb_2(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return QueryDb_2;
}(QueryBase));
exports.QueryDb_2 = QueryDb_2;
var MedicationSearch = /** @class */ (function () {
    function MedicationSearch(init) {
        Object.assign(this, init);
    }
    return MedicationSearch;
}());
exports.MedicationSearch = MedicationSearch;
var MedicationRequest = /** @class */ (function (_super) {
    __extends(MedicationRequest, _super);
    function MedicationRequest(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return MedicationRequest;
}(BaseAudit));
exports.MedicationRequest = MedicationRequest;
var ListMedicationResult = /** @class */ (function () {
    function ListMedicationResult(init) {
        Object.assign(this, init);
    }
    return ListMedicationResult;
}());
exports.ListMedicationResult = ListMedicationResult;
var DonatedMedicationWithDonor = /** @class */ (function (_super) {
    __extends(DonatedMedicationWithDonor, _super);
    function DonatedMedicationWithDonor(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return DonatedMedicationWithDonor;
}(DonatedMedication));
exports.DonatedMedicationWithDonor = DonatedMedicationWithDonor;
var ProcessDonatedMedicationResponse = /** @class */ (function () {
    function ProcessDonatedMedicationResponse(init) {
        Object.assign(this, init);
    }
    return ProcessDonatedMedicationResponse;
}());
exports.ProcessDonatedMedicationResponse = ProcessDonatedMedicationResponse;
var CreateDonationResponse = /** @class */ (function () {
    function CreateDonationResponse(init) {
        Object.assign(this, init);
    }
    return CreateDonationResponse;
}());
exports.CreateDonationResponse = CreateDonationResponse;
var GetDonationResponse = /** @class */ (function () {
    function GetDonationResponse(init) {
        Object.assign(this, init);
    }
    return GetDonationResponse;
}());
exports.GetDonationResponse = GetDonationResponse;
var HelloResponse = /** @class */ (function () {
    function HelloResponse(init) {
        Object.assign(this, init);
    }
    return HelloResponse;
}());
exports.HelloResponse = HelloResponse;
var RegisterMemberResponse = /** @class */ (function () {
    function RegisterMemberResponse(init) {
        Object.assign(this, init);
    }
    return RegisterMemberResponse;
}());
exports.RegisterMemberResponse = RegisterMemberResponse;
var UpdateUserPasswordResponse = /** @class */ (function () {
    function UpdateUserPasswordResponse(init) {
        Object.assign(this, init);
    }
    return UpdateUserPasswordResponse;
}());
exports.UpdateUserPasswordResponse = UpdateUserPasswordResponse;
var GetUserResponse = /** @class */ (function (_super) {
    __extends(GetUserResponse, _super);
    function GetUserResponse(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return GetUserResponse;
}(BaseResponse));
exports.GetUserResponse = GetUserResponse;
var CreateMedicationRequestResponse = /** @class */ (function (_super) {
    __extends(CreateMedicationRequestResponse, _super);
    function CreateMedicationRequestResponse(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return CreateMedicationRequestResponse;
}(BaseResponse));
exports.CreateMedicationRequestResponse = CreateMedicationRequestResponse;
var GetMedicationRequestResponse = /** @class */ (function (_super) {
    __extends(GetMedicationRequestResponse, _super);
    function GetMedicationRequestResponse(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return GetMedicationRequestResponse;
}(BaseResponse));
exports.GetMedicationRequestResponse = GetMedicationRequestResponse;
var ProcessMedicationRequestResponse = /** @class */ (function (_super) {
    __extends(ProcessMedicationRequestResponse, _super);
    function ProcessMedicationRequestResponse(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return ProcessMedicationRequestResponse;
}(BaseResponse));
exports.ProcessMedicationRequestResponse = ProcessMedicationRequestResponse;
var CreateMedicationInventoryResponse = /** @class */ (function (_super) {
    __extends(CreateMedicationInventoryResponse, _super);
    function CreateMedicationInventoryResponse(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return CreateMedicationInventoryResponse;
}(BaseResponse));
exports.CreateMedicationInventoryResponse = CreateMedicationInventoryResponse;
var UpdateMedicationInventoryResponse = /** @class */ (function (_super) {
    __extends(UpdateMedicationInventoryResponse, _super);
    function UpdateMedicationInventoryResponse(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return UpdateMedicationInventoryResponse;
}(BaseResponse));
exports.UpdateMedicationInventoryResponse = UpdateMedicationInventoryResponse;
var ListDrugNamesResponse = /** @class */ (function (_super) {
    __extends(ListDrugNamesResponse, _super);
    function ListDrugNamesResponse(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return ListDrugNamesResponse;
}(BaseResponseList));
exports.ListDrugNamesResponse = ListDrugNamesResponse;
// @DataContract
var AuthenticateResponse = /** @class */ (function () {
    function AuthenticateResponse(init) {
        Object.assign(this, init);
    }
    return AuthenticateResponse;
}());
exports.AuthenticateResponse = AuthenticateResponse;
// @DataContract
var ConvertSessionToTokenResponse = /** @class */ (function () {
    function ConvertSessionToTokenResponse(init) {
        Object.assign(this, init);
    }
    return ConvertSessionToTokenResponse;
}());
exports.ConvertSessionToTokenResponse = ConvertSessionToTokenResponse;
// @DataContract
var GetAccessTokenResponse = /** @class */ (function () {
    function GetAccessTokenResponse(init) {
        Object.assign(this, init);
    }
    return GetAccessTokenResponse;
}());
exports.GetAccessTokenResponse = GetAccessTokenResponse;
// @DataContract
var QueryResponse = /** @class */ (function () {
    function QueryResponse(init) {
        Object.assign(this, init);
    }
    return QueryResponse;
}());
exports.QueryResponse = QueryResponse;
/**
* ProcessDonatedMedication
*/
// @Route("/donations/medications/{donatedMedicationId}", "POST")
// @Api(Description="ProcessDonatedMedication ")
var ProcessDonatedMedication = /** @class */ (function () {
    function ProcessDonatedMedication(init) {
        Object.assign(this, init);
    }
    ProcessDonatedMedication.prototype.createResponse = function () { return new ProcessDonatedMedicationResponse(); };
    ProcessDonatedMedication.prototype.getTypeName = function () { return 'ProcessDonatedMedication'; };
    return ProcessDonatedMedication;
}());
exports.ProcessDonatedMedication = ProcessDonatedMedication;
/**
* CreateDonation
*/
// @Route("/donations", "POST")
// @Api(Description="CreateDonation")
var CreateDonation = /** @class */ (function () {
    function CreateDonation(init) {
        Object.assign(this, init);
    }
    CreateDonation.prototype.createResponse = function () { return new CreateDonationResponse(); };
    CreateDonation.prototype.getTypeName = function () { return 'CreateDonation'; };
    return CreateDonation;
}());
exports.CreateDonation = CreateDonation;
/**
* GetDonation
*/
// @Route("/donations/{donationId}", "GET")
// @Api(Description="GetDonation")
var GetDonation = /** @class */ (function () {
    function GetDonation(init) {
        Object.assign(this, init);
    }
    GetDonation.prototype.createResponse = function () { return new GetDonationResponse(); };
    GetDonation.prototype.getTypeName = function () { return 'GetDonation'; };
    return GetDonation;
}());
exports.GetDonation = GetDonation;
// @Route("/hello", "GET")
var Hello = /** @class */ (function () {
    function Hello(init) {
        Object.assign(this, init);
    }
    Hello.prototype.createResponse = function () { return new HelloResponse(); };
    Hello.prototype.getTypeName = function () { return 'Hello'; };
    return Hello;
}());
exports.Hello = Hello;
// @Route("/hello/member", "GET")
var HelloMember = /** @class */ (function () {
    function HelloMember(init) {
        Object.assign(this, init);
    }
    HelloMember.prototype.createResponse = function () { return new HelloResponse(); };
    HelloMember.prototype.getTypeName = function () { return 'HelloMember'; };
    return HelloMember;
}());
exports.HelloMember = HelloMember;
// @Route("/hello/pharmacist", "GET")
var HelloPharmacist = /** @class */ (function () {
    function HelloPharmacist(init) {
        Object.assign(this, init);
    }
    HelloPharmacist.prototype.createResponse = function () { return new HelloResponse(); };
    HelloPharmacist.prototype.getTypeName = function () { return 'HelloPharmacist'; };
    return HelloPharmacist;
}());
exports.HelloPharmacist = HelloPharmacist;
// @Route("/hello/authenticated", "GET")
var HelloAuthenticated = /** @class */ (function () {
    function HelloAuthenticated(init) {
        Object.assign(this, init);
    }
    HelloAuthenticated.prototype.createResponse = function () { return new HelloResponse(); };
    HelloAuthenticated.prototype.getTypeName = function () { return 'HelloAuthenticated'; };
    return HelloAuthenticated;
}());
exports.HelloAuthenticated = HelloAuthenticated;
// @Route("/register/member", "POST")
var RegisterMember = /** @class */ (function () {
    function RegisterMember(init) {
        Object.assign(this, init);
    }
    RegisterMember.prototype.createResponse = function () { return new RegisterMemberResponse(); };
    RegisterMember.prototype.getTypeName = function () { return 'RegisterMember'; };
    return RegisterMember;
}());
exports.RegisterMember = RegisterMember;
/**
* UpdateUserPassword
*/
// @Route("/users/{email}/password", "POST")
// @Api(Description="UpdateUserPassword")
var UpdateUserPassword = /** @class */ (function () {
    function UpdateUserPassword(init) {
        Object.assign(this, init);
    }
    UpdateUserPassword.prototype.createResponse = function () { return new UpdateUserPasswordResponse(); };
    UpdateUserPassword.prototype.getTypeName = function () { return 'UpdateUserPassword'; };
    return UpdateUserPassword;
}());
exports.UpdateUserPassword = UpdateUserPassword;
/**
* GetUser
*/
// @Route("/users/current", "GET")
// @Api(Description="GetUser")
var GetUser = /** @class */ (function () {
    function GetUser(init) {
        Object.assign(this, init);
    }
    GetUser.prototype.createResponse = function () { return new GetUserResponse(); };
    GetUser.prototype.getTypeName = function () { return 'GetUser'; };
    return GetUser;
}());
exports.GetUser = GetUser;
/**
* CreateMedicationRequest
*/
// @Route("/requests/", "POST")
// @Api(Description="CreateMedicationRequest")
var CreateMedicationRequest = /** @class */ (function () {
    function CreateMedicationRequest(init) {
        Object.assign(this, init);
    }
    CreateMedicationRequest.prototype.createResponse = function () { return new CreateMedicationRequestResponse(); };
    CreateMedicationRequest.prototype.getTypeName = function () { return 'CreateMedicationRequest'; };
    return CreateMedicationRequest;
}());
exports.CreateMedicationRequest = CreateMedicationRequest;
/**
* GetMedicationRequest
*/
// @Route("/requests/{medicationRequestId}", "GET")
// @Api(Description="GetMedicationRequest")
var GetMedicationRequest = /** @class */ (function () {
    function GetMedicationRequest(init) {
        Object.assign(this, init);
    }
    GetMedicationRequest.prototype.createResponse = function () { return new GetMedicationRequestResponse(); };
    GetMedicationRequest.prototype.getTypeName = function () { return 'GetMedicationRequest'; };
    return GetMedicationRequest;
}());
exports.GetMedicationRequest = GetMedicationRequest;
/**
* ProcessMedicationRequest
*/
// @Route("/requests/{medicationRequestId}", "PUT")
// @Api(Description="ProcessMedicationRequest")
var ProcessMedicationRequest = /** @class */ (function () {
    function ProcessMedicationRequest(init) {
        Object.assign(this, init);
    }
    ProcessMedicationRequest.prototype.createResponse = function () { return new ProcessMedicationRequestResponse(); };
    ProcessMedicationRequest.prototype.getTypeName = function () { return 'ProcessMedicationRequest'; };
    return ProcessMedicationRequest;
}());
exports.ProcessMedicationRequest = ProcessMedicationRequest;
/**
* CreateMedicationInventory
*/
// @Route("/medications/inventory", "POST")
// @Api(Description="CreateMedicationInventory")
var CreateMedicationInventory = /** @class */ (function () {
    function CreateMedicationInventory(init) {
        Object.assign(this, init);
    }
    CreateMedicationInventory.prototype.createResponse = function () { return new CreateMedicationInventoryResponse(); };
    CreateMedicationInventory.prototype.getTypeName = function () { return 'CreateMedicationInventory'; };
    return CreateMedicationInventory;
}());
exports.CreateMedicationInventory = CreateMedicationInventory;
/**
* UpdateMedicationInventory
*/
// @Route("/medications/inventory/{medicationInventoryId}", "PUT")
// @Api(Description="UpdateMedicationInventory")
var UpdateMedicationInventory = /** @class */ (function () {
    function UpdateMedicationInventory(init) {
        Object.assign(this, init);
    }
    UpdateMedicationInventory.prototype.createResponse = function () { return new UpdateMedicationInventoryResponse(); };
    UpdateMedicationInventory.prototype.getTypeName = function () { return 'UpdateMedicationInventory'; };
    return UpdateMedicationInventory;
}());
exports.UpdateMedicationInventory = UpdateMedicationInventory;
/**
* GetMedicationInventory
*/
// @Route("/medications/inventory/{medicationInventoryId}", "GET")
// @Api(Description="GetMedicationInventory")
var GetMedicationInventory = /** @class */ (function () {
    function GetMedicationInventory(init) {
        Object.assign(this, init);
    }
    GetMedicationInventory.prototype.createResponse = function () { return new CreateMedicationInventoryResponse(); };
    GetMedicationInventory.prototype.getTypeName = function () { return 'GetMedicationInventory'; };
    return GetMedicationInventory;
}());
exports.GetMedicationInventory = GetMedicationInventory;
/**
*  ListDrugNames
*/
// @Route("/medications/names", "GET")
// @Api(Description=" ListDrugNames")
var ListDrugNames = /** @class */ (function () {
    function ListDrugNames(init) {
        Object.assign(this, init);
    }
    ListDrugNames.prototype.createResponse = function () { return new ListDrugNamesResponse(); };
    ListDrugNames.prototype.getTypeName = function () { return 'ListDrugNames'; };
    return ListDrugNames;
}());
exports.ListDrugNames = ListDrugNames;
// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
var Authenticate = /** @class */ (function () {
    function Authenticate(init) {
        Object.assign(this, init);
    }
    Authenticate.prototype.createResponse = function () { return new AuthenticateResponse(); };
    Authenticate.prototype.getTypeName = function () { return 'Authenticate'; };
    return Authenticate;
}());
exports.Authenticate = Authenticate;
// @Route("/session-to-token")
// @DataContract
var ConvertSessionToToken = /** @class */ (function () {
    function ConvertSessionToToken(init) {
        Object.assign(this, init);
    }
    ConvertSessionToToken.prototype.createResponse = function () { return new ConvertSessionToTokenResponse(); };
    ConvertSessionToToken.prototype.getTypeName = function () { return 'ConvertSessionToToken'; };
    return ConvertSessionToToken;
}());
exports.ConvertSessionToToken = ConvertSessionToToken;
// @Route("/access-token")
// @DataContract
var GetAccessToken = /** @class */ (function () {
    function GetAccessToken(init) {
        Object.assign(this, init);
    }
    GetAccessToken.prototype.createResponse = function () { return new GetAccessTokenResponse(); };
    GetAccessToken.prototype.getTypeName = function () { return 'GetAccessToken'; };
    return GetAccessToken;
}());
exports.GetAccessToken = GetAccessToken;
/**
* ListPharmacies
*/
// @Route("/pharmacies", "GET")
// @Api(Description="ListPharmacies")
var ListPharmacies = /** @class */ (function (_super) {
    __extends(ListPharmacies, _super);
    function ListPharmacies(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    ListPharmacies.prototype.createResponse = function () { return new QueryResponse(); };
    ListPharmacies.prototype.getTypeName = function () { return 'ListPharmacies'; };
    return ListPharmacies;
}(QueryDb_1));
exports.ListPharmacies = ListPharmacies;
/**
* ListMedications
*/
// @Route("/medications/inventory", "GET")
// @Api(Description="ListMedications")
var ListMedications = /** @class */ (function (_super) {
    __extends(ListMedications, _super);
    function ListMedications(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    ListMedications.prototype.createResponse = function () { return new QueryResponse(); };
    ListMedications.prototype.getTypeName = function () { return 'ListMedications'; };
    return ListMedications;
}(QueryDb_2));
exports.ListMedications = ListMedications;
/**
* ListMedicationRequests
*/
// @Route("/requests", "GET")
// @Api(Description="ListMedicationRequests")
var ListMedicationRequests = /** @class */ (function (_super) {
    __extends(ListMedicationRequests, _super);
    function ListMedicationRequests(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    ListMedicationRequests.prototype.createResponse = function () { return new QueryResponse(); };
    ListMedicationRequests.prototype.getTypeName = function () { return 'ListMedicationRequests'; };
    return ListMedicationRequests;
}(QueryDb_2));
exports.ListMedicationRequests = ListMedicationRequests;
/**
* ListDonatedMedications
*/
// @Route("/donations/medications", "GET")
// @Api(Description="ListDonatedMedications")
var ListDonatedMedications = /** @class */ (function (_super) {
    __extends(ListDonatedMedications, _super);
    function ListDonatedMedications(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    ListDonatedMedications.prototype.createResponse = function () { return new QueryResponse(); };
    ListDonatedMedications.prototype.getTypeName = function () { return 'ListDonatedMedications'; };
    return ListDonatedMedications;
}(QueryDb_2));
exports.ListDonatedMedications = ListDonatedMedications;
/**
* ListDonations
*/
// @Route("/donations", "GET")
// @Api(Description="ListDonations")
var ListDonations = /** @class */ (function (_super) {
    __extends(ListDonations, _super);
    function ListDonations(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    ListDonations.prototype.createResponse = function () { return new QueryResponse(); };
    ListDonations.prototype.getTypeName = function () { return 'ListDonations'; };
    return ListDonations;
}(QueryDb_1));
exports.ListDonations = ListDonations;
